<div class="manage-content-container" *ngIf="page.isPreview()">
  <ng-container [brManageContentButton]="document"></ng-container>
</div>
<p *ngIf="!data">The Content Tiles Component will render after a content document is selected in the authoring modal.</p>
<div *ngIf="data" class="content-tiles-section">
    <div class="content-tiles-section__wrapper">
      <h2>{{tileHeading}}</h2>
        <div class="grid-container grid-x grid-margin-x">
            <div class="cell large-4 small-12" *ngFor="let contentTile of contentTiles">
                <img src="{{contentTile.image}}">
                <div class='content-tiles-section__content-wrapper'>
                  <h3>{{contentTile.heading}}</h3>
                  <p [innerHTML]=contentTile.content></p>
                </div>
            </div>
        </div>
    </div>
  </div>
