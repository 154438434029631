import { Component, OnInit, Input } from '@angular/core';
// import { PageModelService, ImageUrlService } from '../../upgrade/bloomreach';
// import { SingleContentComponent } from 'src/app/upgrade/SingleContentComponent';

// Upgrade
import { Component as BrComponent, Document, ImageSet, Page } from '@bloomreach/spa-sdk';


@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {
  // @Input() configuration: any;
  @Input() component!: BrComponent;
  @Input() page!: Page;

  imageHeroCTA: string;

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get data() {
    let data = this.document?.getData();
    this.imageHeroCTA = data.link?.text;
    return data;
  }

  ngOnInit() {
    // super.ngOnInit();
  }

}
