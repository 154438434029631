import { Component, OnInit, Input } from '@angular/core';
// import { BaseComponent, ImageUrlService, PageModelService, getNestedObject, RequestContextService } from '../../upgrade/bloomreach';
// import { SingleContentComponent } from 'src/app/upgrade/SingleContentComponent';

// Upgrade
import { Component as BrComponent, Document, ImageSet, Page } from '@bloomreach/spa-sdk';

/**
 * This should be included on every page.  Sets the appropriate content based on parameters set in the CMS for SEO meta tags.
 */
@Component({
  selector: 'app-internal-search',
  templateUrl: './internal-search.component.html',
  styleUrls: ['./internal-search.component.scss']
})
export class InternalSearchComponent implements OnInit {
  // @Input() configuration: any;
  @Input() component!: BrComponent;
  @Input() page!: Page;

  imageHeroCTA: string;
  internalSearch: {
    description: string,
    keywords: string,
    title: string,
    omitPageFromIndex?: boolean,
    pagePriority?: number,
    suggestTerms?: string
  };

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get data() {
    let data = this.document?.getData();
    this.imageHeroCTA = data.link?.text;
    return data;
  }

  get isPreview(): boolean {
    return this.page.isPreview();
  }

  ngOnInit() {
    // super.ngOnInit();
    // this.isPreview = this.data.requestContextService.isPreviewRequest();
    // if (this.configuration) {
    //   this.internalSearch = this.configuration._meta.params;
    // }
  }

}
