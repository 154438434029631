<div *ngIf="!isLoaded" class="loading">
    <div class="loading__wrap">
        <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
        Loading
    </div>
</div>
<div id='magazine' [style.height.px]="magazineHeight" [class.display]="isLoaded">
    <div class="pages" #el>
        <div *ngFor="let image of images; let i = index" class="page" [class.turned]="image.turned"
            [class.index]="i == pageIndex" [style.zIndex]="image.zindex" (click)="turnPage($event, i + 1)">
            <img src="{{ image.url }}" (load)="imagesLoaded()">
        </div>
    </div>
</div>
