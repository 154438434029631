<div class="manage-content-container" *ngIf="page.isPreview()">
  <ng-container [brManageContentButton]="document"></ng-container>
</div>
<p *ngIf="!data">The Content Well Component will render after a content document is selected in the authoring modal.</p>
<div *ngIf="data" class="content-well-component">
  <div class="content-well--component__wrapper">
      <div class="grid-container grid-x">
        <div class="cell large-12 text-center">
          <h2 class="content-well--component__title">{{contentWellTitle}}</h2>
        </div>
        <div class="cell large-12 small-12">
          <span [innerHTML]="contentWellContent"></span>
        </div>
      </div>
  </div>
</div>
