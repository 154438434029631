import { Component, OnInit, ViewChild, ElementRef, NgZone, Renderer2, Input } from '@angular/core';
// import { RequestContextService, getNestedObject, PageModelService, ImageUrlService } from '../../upgrade/bloomreach';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SeoService } from '../../services/seo.service';
// import { BasePageComponent } from '../base-page/base-page.component';

// Upgrade
import { Component as BrComponent, Document, ImageSet, Page } from '@bloomreach/spa-sdk';

@Component({
  selector: 'app-flipbook-page',
  templateUrl: './flipbook-page.component.html',
  styleUrls: ['./flipbook-page.component.scss']
})
export class FlipbookPageComponent implements OnInit {
  @Input() configuration: any;
  @Input() component!: BrComponent;
  @Input() page!: Page;

  configContent: any;
  isLoaded: boolean = false;
  loadedImagesIndex: number = 0;
  images: any = [];
  imageHeroCTA: string;
  pageIndex: number = 0;
  magazineHeight: number = 0;
  @ViewChild('el', { read: ElementRef }) el: ElementRef;
  @ViewChild('blank', { read: ElementRef }) blank: ElementRef;

  constructor(
    // requestContextService: RequestContextService,
    titleService: Title,
    route: ActivatedRoute,
    router: Router,
    seoService: SeoService,
    // private _pageModelService: PageModelService,
    // private imageUrlService: ImageUrlService,
    private renderer: Renderer2,
    private ngZone: NgZone,
  ) {}

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get data() {
    let data = this.document?.getData();
    this.imageHeroCTA = data.link?.text;
    return data;
  }

  ngOnInit() {
    // super.ngOnInit();

    // Load up the configuration and get the document data from it
    const configDocRef = this.data.getNestedObject(this.configuration, ['models', 'document', '$ref']);
    this.configContent = this.page.getContent(configDocRef);

    // Get the images from the document
    for (let image of this.configContent.images) {
      this.images.push({
        turned: false,
        url: this.page.getContent<ImageSet>(image.largeImage).getOriginal().getUrl()
      });
    }

    // Set the z index for all even elements in reverse order, so when they become stacked they have the correct order
    for(let i = 0; i < this.images.length; i++) {
      if (i % 2 === 0) {
        this.images[i]['zindex'] = (this.images.length - i);
      }
    }
  }

  turnPage($event, pageNum) {
    // For any of the even elements, add the .turned class to the current and the next element
    if (pageNum % 2 === 0) {
      $event.target.classList.remove('turned');
      $event.target.previousElementSibling.classList.remove('turned');
    } else {
      // The opposite when going the other way
      $event.target.classList.add('turned');
      $event.target.nextElementSibling.classList.add('turned');
    }
  }

  imagesLoaded() {
    this.loadedImagesIndex++;
    let heightArr = [];

    if(this.loadedImagesIndex == this.images.length) {
      this.isLoaded = true;
      // Push the heights of the images into the heightArr
      for (let page of this.el.nativeElement.children) {
        heightArr.push(page.offsetHeight)
      }
    }
    // Set the min height to the magazine Height from the heightArr
    this.magazineHeight = Math.min.apply(Math, heightArr);
  }
}
