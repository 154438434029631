<div class="manage-content-container" *ngIf="page.isPreview()">
    <ng-container [brManageContentButton]="document"></ng-container>
</div>

<p *ngIf="!data">The Subsidiary Heading Component will render after a content document is selected in the authoring
    modal.</p>
<div *ngIf="data" class="subsidiary-heading">
    <div class="subsidiary-heading__wrapper">
        <div class="grid-container grid-x">
            <div class="cell large-8 small-12">
                <h1 [innerHTML]='subsidiaryHeading'></h1>
            </div>
            <div class="cell large-2 small-6">
                <div *ngIf="susidiaryHeadingWebText && susidiaryHeadingWebLink">
                    <a *ngIf="susidiaryHeadingWebIsExternal && !newTab; else openNewTab;"
                        href="{{susidiaryHeadingWebLink | protocol}}" class="button secondary"><span
                            class="icon-web-icon"><span class="path1"></span><span
                                class="path2"></span></span>{{susidiaryHeadingWebText}}</a>
                    <ng-template #openNewTab>
                        <a *ngIf="susidiaryHeadingWebIsExternal && newTab; else internalNew;"
                            href="{{susidiaryHeadingWebLink | protocol}}" target="_blank" class="button secondary"><span
                                class="icon-web-icon"><span class="path1"></span><span
                                    class="path2"></span></span>{{susidiaryHeadingWebText}}</a>
                    </ng-template>
                    <ng-template #internalNew>
                        <a *ngIf="!susidiaryHeadingWebIsExternal && !assetLink && newTab; else internal;"
                            routerLink="{{susidiaryHeadingWebLink | parseUrl: 'pathname'}}" target="_blank" class="button secondary"><span
                                class="icon-web-icon"><span class="path1"></span><span
                                    class="path2"></span></span>{{susidiaryHeadingWebText}}</a>
                    </ng-template>
                    <ng-template #internal>
                        <a *ngIf="!susidiaryHeadingWebIsExternal && !assetLink && !newTab; else assetNew;"
                            routerLink="{{susidiaryHeadingWebLink | parseUrl: 'pathname'}}" class="button secondary"><span
                                class="icon-web-icon"><span class="path1"></span><span
                                    class="path2"></span></span>{{susidiaryHeadingWebText}}</a>
                    </ng-template>
                    <ng-template #assetNew>
                        <a *ngIf="!susidiaryHeadingWebIsExternal && assetLink && newTab; else asset;"
                            href="{{susidiaryHeadingWebLink}}" target="_blank"
                            class="button secondary">{{susidiaryHeadingWebText}}</a>
                    </ng-template>
                    <ng-template #asset>
                        <a href="{{susidiaryHeadingWebLink}}" class="button secondary">{{susidiaryHeadingWebText}}</a>
                    </ng-template>
                </div>
            </div>
            <div class="cell large-2 small-6">
                <a (click)="scrollToLocations()"><span class="icon-directions-icon"><span class="path1"></span><span
                            class="path2"></span></span> {{subsidiaryHeadingLocationText}}</a>
            </div>
        </div>
    </div>
</div>
