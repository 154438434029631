import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ServiceUrls } from '../enums/service-urls.enum';
import { SearchResults } from '../global-classes/search-result'

@Injectable()
export class SearchService {
  // tslint:disable-next-line:max-line-length
  readonly endpoint = `${environment.hippoRootProto}://${environment.hippoRootUrl}:${environment.hippoRootPort}${environment.hippoSitePrefix ? `/${environment.hippoSitePrefix}` : ''}${ServiceUrls.hippoRestApi}${ServiceUrls.search}`;
  readonly elasticEndpoint = `${environment.hippoRootProto}://${environment.hippoRootUrl}:${environment.hippoRootPort}${environment.hippoSitePrefix ? `/${environment.hippoSitePrefix}` : ''}${ServiceUrls.hippoRestApi}${ServiceUrls.ESsearch}`;

  constructor(private http: HttpClient) { }

  public getSearchResults(v): Observable<any> {
    const searchTerm = v;
    return this.http.get(this.endpoint + '?query=' +  searchTerm)
    .pipe(
      map((data: SearchResults) => {
        return data;
      })
    );
  }

  public getElasticSearchResults(searchTerm): Observable<any> {
    return this.http.post<any>(this.elasticEndpoint + '?text=' + searchTerm + "&filter=", {})
    .pipe(
      map((data: SearchResults) => {
        return data;
      }),
      catchError((err) => {
        console.error(err);
        throw err;
      })
    );
  }

  public getElasticSearchCategories(): Observable<any> {
    let query = `${this.elasticEndpoint}/categories`;

    return this.http.post<any>(query, {})
    .pipe(
      map(results => {
        return results;
      }),
      catchError((err) => {
        console.error(err);
        return err;
      })
    );
  }

  public getElasticCompletionResults(searchTerm): Observable<any> {
    let queryTerm = encodeURI(searchTerm);
    let query = `${this.elasticEndpoint}/completionSuggester?text=${queryTerm}`;

    return this.http.post<any>(query, {})
    .pipe(
      map(results => {
        return results;
      }),
      catchError((err) => {
        console.error(err);
        return err;
      })
    );
  }

  public getElasticTermsResults(searchTerm): Observable<any> {
    let queryTerm = encodeURI(searchTerm);
    let query = `${this.elasticEndpoint}/termSuggester?text=${queryTerm}`;

    return this.http.post<any>(query, {})
    .pipe(
      map(results => {
        return results;
      }),
      catchError((err) => {
        console.error(err);
        return err;
      })
    );
  }
}
