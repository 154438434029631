<div class="manage-content-container" *ngIf="page.isPreview()">
  <ng-container [brManageContentButton]="document"></ng-container>
</div>
<p *ngIf="!data">The Image with Pull Quote Component will render after a content document is selected in the authoring modal.</p>
<div *ngIf="data" class="image-pull-quote-section">
  <div class="image-pull-quote-section__wrapper">
    <div class="grid-container  grid-x grid-margin-x {{orientation}}">
        <div class="cell large-5 small-12 quote">
          <div class="quote__wrapper">
            <blockquote>
              <p [innerHTML]=quote></p>
            </blockquote>
            <span class="image-pull-quote-section-author">{{author}}</span>
          </div>
        </div>
        <div class="cell large-7 small-12 image" [ngStyle]="{'background-image': 'url('+image+')'}">
        </div>
    </div>
  </div>
</div>
