<div class="manage-content-container" *ngIf="page.isPreview()">
  <ng-container [brManageContentButton]="document"></ng-container>
</div>
<p *ngIf="!data">The Content Section with Video Component will render after a content document is selected in the authoring modal.</p>
<div *ngIf="data" class="content-section-video">
    <div class="content-section-video__wrapper">
        <div class="grid-container grid-x {{contentOrientation}}">
          <div class="cell large-6 small-12 content">
            <h2>{{contentHeading}}</h2>
            <p [innerHTML]="contentContent"></p>
          </div>
          <div class="cell large-6 small-12 video">
            <div>
              <div id="video_player"></div>
            </div>
          </div>
        </div>
    </div>
</div>
