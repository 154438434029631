<div class="manage-content-container" *ngIf="page.isPreview()">
  <ng-container [brManageContentButton]="document"></ng-container>
</div>
<p *ngIf="!data">The Hero Video Component will render after a content document is selected in the authoring modal.</p>
<section *ngIf="data" class="video-hero-block" [ngClass]="{'medium': dimension === 'medium'}" [ngStyle]="{'background-image': 'url('+videoHeroImageDisplay+')'}" id="videoHeroContainerBackground">
    <div id="hero_video_player"></div>
    <div class="video-hero-block__wrapper" id="videoHeroContainer">
      <h1 [innerHTML]='videoHeroHeading'></h1>
      <!-- <h1 [innerHTML]='videoHeroHeading' *ngIf="videoHeroIsPageTitle == 'true'; else heading2"></h1> 
      <ng-template #heading2>
        <h2 [innerHTML]='videoHeroHeading'></h2>
      </ng-template>
      -->
      <p [innerHTML]='videoHeroContent'></p>
      <a (click)="clicky($event, videoHeroCTAUrl)" class="button primary"><span class="icon-play-icon"></span>{{videoHeroCTA}}</a>
      <!--      <div *ngIf="videoHeroCTAUrl && videoHeroCTA">
          <a *ngIf="videoHeroCTAIsExternal; else internal;" href="{{videoHeroCTAUrl | protocol}}" class="button primary"><span class="icon-play-icon"></span>{{videoHeroCTA}}</a>
          <ng-template #internal>
            <a routerLink="{{videoHeroCTAUrl}}" class="button primary"><span class="icon-play-icon"></span>{{videoHeroCTA}}</a>
          </ng-template>
      </div>
      -->

      <!--
      <div *ngIf="videoHeroCTAUrl && videoHeroCTA">
        <a href="{{videoHeroCTAUrl | protocol}}" class="button primary">{{videoHeroCTA}}</a>
      </div> -->
    </div>
</section>
