<div class="manage-content-container" *ngIf="page.isPreview()">
    <ng-container [brManageContentButton]="document"></ng-container>
</div>
<p *ngIf="!data">The Content Teaser Component will render after a content document is selected in the authoring modal.</p>
<div *ngIf="data" class="content-teaser-block">
    <div class="content-teaser-block__wrapper">
        <div class="grid-container grid-x {{contentTeaserImageAlignment}}">
            <div class="cell large-6 small-12 image">
                <img src='{{contentTeaserImage}}' [ngClass]="{'ani-active': isVisible}">
            </div>
            <div class="cell large-6 small-12 content">
                <h2>{{contentTeaserTitle}}</h2>
                <h3 [innerHTML]='contentTeaserHeading' [ngClass]="{'ani-active': isVisible}" #uStatic></h3>
                <p [innerHTML]="contentTeaserContent"></p>
                <div *ngIf="contentTeaserLinkText && contentTeaserLink">
                    <a *ngIf="contentTeaserIsExternal && !newTab; else openNewTab;" href="{{contentTeaserLink}}" class="button secondary">{{contentTeaserLinkText}}</a>
                    <ng-template #openNewTab>
                        <a *ngIf="contentTeaserIsExternal && newTab; else internalNew;" href="{{contentTeaserLink}}" target="_blank" class="button secondary">{{contentTeaserLinkText}}</a>
                    </ng-template>
                    <ng-template #internalNew>
                      <a *ngIf="!contentTeaserIsExternal && !assetLink && newTab; else internal;" routerLink="{{contentTeaserLink | parseUrl: 'pathname'}}" target="_blank" class="button secondary">{{contentTeaserLinkText}}</a>
                    </ng-template>
                    <ng-template #internal>
                        <a *ngIf="!contentTeaserIsExternal && !assetLink && !newTab; else assetNew;" routerLink="{{contentTeaserLink | parseUrl: 'pathname'}}" class="button secondary">{{contentTeaserLinkText}}</a>
                    </ng-template>
                    <ng-template #assetNew>
                      <a *ngIf="!contentTeaserIsExternal && assetLink && newTab; else asset;" href="{{contentTeaserLink}}" target="_blank" class="button secondary">{{contentTeaserLinkText}}</a>
                    </ng-template>
                    <ng-template #asset>
                        <a href="{{contentTeaserLink}}" class="button secondary">{{contentTeaserLinkText}}</a>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
