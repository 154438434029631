import { Component, OnInit, Input } from '@angular/core';
// import { BaseComponent, ImageUrlService, PageModelService } from '../../upgrade/bloomreach';
// import { SingleContentComponent } from 'src/app/upgrade/SingleContentComponent';

import {
  Component as BrComponent,
  Document,
  ImageSet,
  Page,
} from "@bloomreach/spa-sdk";

@Component({
  selector: 'app-featured-content',
  templateUrl: './featured-content.component.html',
  styleUrls: ['./featured-content.component.scss']
})
export class FeaturedContentComponent implements OnInit {
featuredHeading: string;
featuredContentCol1: string;
featuredContentCol2: string;
featuredImage: string;
featuredImageTitle: string;

@Input() component!: BrComponent;
@Input() page!: Page;


// constructor(imageUrlService: ImageUrlService, pageModelService: PageModelService) {
//   super(imageUrlService, pageModelService );
// }

// Populate the document variable, will return the model data from the Bloomreach API
get document() {
  const { document } = this.component.getModels();
  return document && this.page.getContent<Document>(document);
}

// Populate the data variable, will return the data from document
get data() {
  let data = this.document?.getData();
  return data;
}

  ngOnInit() {
    // super.ngOnInit();
    if(this.data) {
      this.featuredHeading = this.data.headline;
      this.featuredContentCol1 = this.data.col1.value;
      this.featuredContentCol2 = this.data.col2.value;
      if (this.data.image) {
        this.featuredImage =  this.page.getContent<ImageSet>(this.data.image).getOriginal().getUrl();
      }
      this.featuredImageTitle = this.data.label;
    }
  }

}
