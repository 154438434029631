import { Component, OnInit, Input, OnChanges } from "@angular/core";
// import { BaseComponent, getNestedObject } from '../../upgrade/bloomreach';

// Upgrade
import {
  Component as BrComponent,
  Document,
  ImageSet,
  Page,
} from "@bloomreach/spa-sdk";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit, OnChanges {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  @Input() configuration: any;
  @Input() isProductPage?: boolean;
  @Input() isProductBrowsePage?: boolean;
  @Input() productName?: string;
  @Input() productBreadcrumb?: string[];
  @Input() extraBreadcrumb?: {
    title: string;
    facetId: string;
  };

  breadcrumbObject: {
    [key: string]: string;
  } = {};

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get data() {
    let data = this.document?.getData();
    return data;
  }

  ngOnInit() {
    /**
     * Build out breadcrumb from provided configuration object.
     */
    if (this.data) {
      this.renderBreadcrumbs();
    }
  }

  renderBreadcrumbs() {
    // Get the breadcrumbs from the component
    const tempBreadcrumbObject = this.page
      .getComponent("breadcrumb")
      .getModels();

    if (tempBreadcrumbObject) {
      const breadcrumbKeys = Object.keys(tempBreadcrumbObject.breadcrumb);
      // Remove breadcrumbs that are from wildcard routes.
      breadcrumbKeys.forEach((curKey) => {
        if (!curKey.includes("_any_")) {
          this.breadcrumbObject[curKey] =
            tempBreadcrumbObject.breadcrumb[curKey];
        }
      });
    }
  }

  /**
   * Update the breadcrumb on any change to guarentee that it will render correctly.
   */
  ngOnChanges() {
    this.breadcrumbObject = {};
    // Same as above, but happens when a change happens on the page
    this.renderBreadcrumbs();
  }
}
