<app-breadcrumb [configuration]="breadcrumbConfig"></app-breadcrumb>
<div class="subsidiary-page">
  <div class="grid-container">
    <div class="grid-x grid-margin-x">
      <div class="cell large-12">
        <bre-render-cms-component [path]="'main'"></bre-render-cms-component>
        <!-- <app-subsidiary-heading></app-subsidiary-heading>
        <app-image-carousel></app-image-carousel>
        <app-content-section-component></app-content-section-component>
        <app-page-break></app-page-break>
        <app-bulleted-content-section></app-bulleted-content-section>
        <app-page-break></app-page-break>
        <app-subsidiary-locations></app-subsidiary-locations> -->
      </div>
    </div>
  </div>
</div>
