import { Component, OnInit } from '@angular/core';
import { LocationsService } from '../../services/locations.service';
import { Observable } from 'rxjs';
import { Location } from '../../global-classes/location';
import { ConfigComponent } from '../../find-a-yard-components/config/config.component';

@Component({
  selector: 'app-find-a-yard-map',
  templateUrl: './find-a-yard-map.component.html',
  styleUrls: ['./find-a-yard-map.component.scss']
})
export class FindAYardMapComponent implements OnInit {
  ConfigComponent = ConfigComponent;
  resultsList$: Observable<Location[]>;
  activeLocation$: Observable<Location>;
  status: string;
  prevStatus: string;

  windowTop: number;
  mapTop: number;

  constructor(private _locationsService: LocationsService) { }

  ngOnInit() {
    this.resultsList$ = this._locationsService.locations$;
    this.activeLocation$ = this._locationsService.activeLocation$;
    this._locationsService.currentStatus.subscribe(status => this.status = status);
    this._locationsService.previousStatus.subscribe(prevStatus => this.prevStatus = prevStatus);
    this.windowTop = window.screenTop;
    this.mapTop = document.getElementsByClassName("find-a-yard-page__search-container").item(0).getBoundingClientRect().bottom;
  }

  setActiveLocation(activeLocation: Location) {
    this._locationsService.setActiveLocation(activeLocation);
  }

  clearActiveLocation() {
    this._locationsService.clearActiveLocation();
  }
}
