<div class="manage-content-container" *ngIf="page.isPreview()">
  <ng-container [brManageContentButton]="document"></ng-container>
</div>
<p *ngIf="!data">The Image Carousel Component will render after a content document is selected in the authoring modal.</p>
<div class="image-carousel" *ngIf="slides && slides.length">
  <ngu-carousel *ngIf="slides.length > 1" #carousel [dataSource]="slides" [inputs]="carouselTileConfig">
    <ngu-tile *nguCarouselDef="let item; let i = index; let ani = animate" class="carouselTile item">
        <section class="image-carousel__content">
          <ng-template [ngIf]="item.smallImage !== null" [ngIfElse]="fullSingleImage">
            <div class="image-carousel__images-container">
              <img class="image-carousel__mobile-image" src="{{item.smallImage}}">
              <img class="image-carousel__foreground-image" src="{{item.largeImage}}">
            </div>
          </ng-template>
          <ng-template #fullSingleImage>
            <div class="image-carousel__images-container">
              <img class="image-carousel__image" src="{{item.largeImage}}">
            </div>
          </ng-template>
          <div class="image-carousel__link" *ngIf="item.linkOptions.url">
            <a *ngIf="item.linkOptions.isExternal && !item.linkOptions.newTab; else openNewTab;"
              href="{{item.linkOptions.url | protocol}}"></a>
            <ng-template #openNewTab>
              <a *ngIf="item.linkOptions.isExternal && item.linkOptions.newTab; else internalNew;"
                href="{{item.linkOptions.url | protocol}}" target="_blank"></a>
            </ng-template>
            <ng-template #internalNew>
              <a *ngIf="!item.linkOptions.isExternal && !item.linkOptions.isAsset && item.linkOptions.newTab; else internal;"
                routerLink="{{item.linkOptions.url | parseUrl: 'pathname'}}" target="_blank"></a>
            </ng-template>
            <ng-template #internal>
              <a *ngIf="!item.linkOptions.isExternal && !item.linkOptions.isAsset && !item.linkOptions.newTab; else assetNew;"
                routerLink="{{item.linkOptions.url | parseUrl: 'pathname'}}"></a>
            </ng-template>
            <ng-template #assetNew>
              <a *ngIf="!item.linkOptions.isExternal && item.linkOptions.isAsset && item.linkOptions.newTab; else asset;"
                href="{{item.linkOptions.url}}" target="_blank"></a>
            </ng-template>
            <ng-template #asset>
              <a href="{{item.linkOptions.url}}"></a>
            </ng-template>
          </div>
          <div class="image-carousel__content-container grid-container">
          </div>
        </section>
    </ngu-tile>

    <button *ngIf="!carousel.isFirst && slides && slides.length > 1" NguCarouselPrev
      class="leftRs image-carousel__arrow image-carousel__arrow--prev">
      <span class="icon-arrow-left-icon"></span>
    </button>
    <button *ngIf="!carousel.isLast && slides && slides.length > 1" NguCarouselNext
      class="rightRs image-carousel__arrow image-carousel__arrow--next">
      <span class="icon-arrow-icon"></span>
    </button>
    <ul *ngIf="slides && slides.length > 1" class="image-carousel__indicators" NguCarouselPoint>
      <li *ngFor="let slide of slides; let i = index" class="image-carousel__point"
        [ngClass]="{'is-active': i === carousel.activePoint}" (click)="carousel.moveTo(i)"></li>
    </ul>
  </ngu-carousel>

  <ngu-carousel *ngIf="slides.length == 1" #carousel [dataSource]="slides" [inputs]="carouselTileConfigOneItem">
    <div *nguCarouselDef="let item; let i = index; let ani = animate" class="carouselTile item">
      <div class="tile">
        <section class="image-carousel__content">
          <div class="image-carousel__images-container">
            <img class="image-carousel__mobile-image" src="{{item.smallImage}}">
            <img class="image-carousel__foreground-image" src="{{item.largeImage}}">
          </div>
          <div class="image-carousel__link" *ngIf="item.linkOptions.url">
            <a *ngIf="item.linkOptions.isExternal && !item.linkOptions.newTab; else openNewTab;"
              href="{{item.linkOptions.url | protocol}}"></a>
            <ng-template #openNewTab>
              <a *ngIf="item.linkOptions.isExternal && item.linkOptions.newTab; else internalNew;"
                href="{{item.linkOptions.url | protocol}}" target="_blank"></a>
            </ng-template>
            <ng-template #internalNew>
              <a *ngIf="!item.linkOptions.isExternal && !item.linkOptions.isAsset && item.linkOptions.newTab; else internal;"
                routerLink="{{item.linkOptions.url | parseUrl: 'pathname'}}" target="_blank"></a>
            </ng-template>
            <ng-template #internal>
              <a *ngIf="!item.linkOptions.isExternal && !item.linkOptions.isAsset && !item.linkOptions.newTab; else assetNew;"
                routerLink="{{item.linkOptions.url | parseUrl: 'pathname'}}"></a>
            </ng-template>
            <ng-template #assetNew>
              <a *ngIf="!item.linkOptions.isExternal && item.linkOptions.isAsset && item.linkOptions.newTab; else asset;"
                href="{{item.linkOptions.url}}" target="_blank"></a>
            </ng-template>
            <ng-template #asset>
              <a href="{{item.linkOptions.url}}"></a>
            </ng-template>
          </div>
          <div class="image-carousel__content-container grid-container">
          </div>
        </section>
      </div>
    </div>

    <button *ngIf="!carousel.isFirst && slides && slides.length > 1" NguCarouselPrev
      class="leftRs image-carousel__arrow image-carousel__arrow--prev">
      <span class="icon-arrow-left-icon"></span>
    </button>
    <button *ngIf="!carousel.isLast && slides && slides.length > 1" NguCarouselNext
      class="rightRs image-carousel__arrow image-carousel__arrow--next">
      <span class="icon-arrow-icon"></span>
    </button>
    <ul *ngIf="slides && slides.length > 1" class="image-carousel__indicators" NguCarouselPoint>
      <li *ngFor="let slide of slides; let i = index" class="image-carousel__point"
        [ngClass]="{'is-active': i === carousel.activePoint}" (click)="carousel.moveTo(i)"></li>
    </ul>
  </ngu-carousel>



</div>
