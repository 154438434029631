import { Component, OnInit, Inject, PLATFORM_ID, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { Location } from "../../global-classes/location";
import { LocationsService } from "../../services/locations.service";
import { isPlatformBrowser } from "@angular/common";
import { BasePageComponent } from "../base-page/base-page.component";
import { Title } from "@angular/platform-browser";
import { SeoService } from "../../services/seo.service";

// Upgrade
import { ENDPOINT } from '../../index/index.component';
// import { REQUEST } from "@nguniversal/express-engine/tokens";
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';

@Component({
  selector: "app-find-a-yard",
  templateUrl: "./find-a-yard.component.html",
  styleUrls: ["./find-a-yard.component.scss"],
  providers: [LocationsService],
})
export class FindAYardComponent extends BasePageComponent implements OnInit {
  isBrowser: boolean;
  mapActive = true;
  // configContent: any;
  locations$: Observable<Location[]>;
  searchLocation$: Observable<string>;
  loading$: Observable<boolean>;
  status: string;

  @Input() page!: Page;
  @Input() component!: BrComponent;

  constructor(
    route: ActivatedRoute,
    titleService: Title,
    seoService: SeoService,
    router: Router,
    private _locationsService: LocationsService,
    @Inject(PLATFORM_ID) platformId: string,
    @Inject(ENDPOINT) endpoint: string,
    // @Inject(REQUEST) @Optional() request?: Request
  ) {
    super(titleService, route, router, seoService, endpoint);
    this.isBrowser = isPlatformBrowser(platformId);
  }

  // get configContent() {
  //   const configRef = this.page.getComponent('configuration-values');

  //   console.log(configRef);

  //   return configRef;
  // }

  ngOnInit() {
    super.ngOnInit();

    this._locationsService.currentStatus.subscribe(
      (status) => (this.status = status)
    );

    if (this.router.url.includes("?")) {
      this.mapActive = true;
    }
    this.router.events.subscribe((event) => {
      if (this.router.url.includes("?")) {
        this.mapActive = true;
      }
    });

    // const componentContainers = getNestedObject(this.page, [
    //   "page",
    //   "components",
    // ]);
    // const configContainer = componentContainers.find(
    //   (curContainer) => curContainer.name === "config"
    // );
    // const innerConfigContainer = configContainer.components.find(
    //   (curContainer) => curContainer.label === "Find a Yard Config"
    // );
    // const configComponent = innerConfigContainer.components.find(
    //   (curComponent) => curComponent.name === "configuration-values"
    // );
    // const configDocRef = getNestedObject(configComponent, [
    //   "models",
    //   "document",
    //   "$ref",
    // ]);

    // this.configContent = this._pageModelService.getContentViaReference(configDocRef);

    this.locations$ = this._locationsService.locations$;
    this.searchLocation$ = this._locationsService.searchLocation$;
    this.loading$ = this._locationsService.loading$;
  }

  toggleMapActive(isActive: boolean) {
    this.mapActive = isActive;
  }
}
