<main>
  <div *ngIf="isLoading;" class="search-results__loading">
    <div class="search-results__loading__wrap">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
      Retrieving Search Results
    </div>
  </div>
  <div class="search-results">
    <div class="search-results__topbar">
      <div class="search-results__topbar__background" (window:resize)="resetClientWidth($event)" [style.width.px]="getClientWidth()"></div>
      <div class="search-results__box">
        <app-global-search [displayJustForm]=true></app-global-search>
      </div>
      <div class="search-results__refine-by" (click)="toggleMobileFilterMenu()" [ngClass]="{'rotated-arrow': showMobileFilterMenu==true}">
        <div class="refine-by">
          Refine by:
          <i class="icon-dropdown-arrow-icon"></i>
        </div>
      </div>
      <div class="search-results__tabs" [ngClass]="{'hidden': showMobileFilterMenu==false}">
        <div *ngFor="let filterItem of filterCategories" class="search-results__tab"
          (click)="updateFilter(filterItem)">
          <button>
            {{filterItem.label}} <span>({{filterItem.count}})</span>
          </button>
        </div>
      </div>
    </div>

    <div class="search-results__count">
      {{searchResultsCount}} {{searchResultsFoundLabel}}
    </div>

    <div *ngIf="!serverError;">
      <div *ngIf="searchResultsCount !== 0; else noResults;" class="search-results__info">
      </div>
    </div>
    <ng-template #noResults>
      <div class="search-results__info">
        <h3 [innerHTML]='noSearchResults'></h3>
        <div>
          <p *ngIf="searchTerms.length > 0">We found some terms that are similar to what you searched for:</p>
          <ul>
            <li *ngFor="let term of searchTerms">
              <a (click)="submitSearchForm(term)">{{ term }}</a>
            </li>
          </ul>
        </div>
        <div class="default-message">
          <p>If you still can’t find what you’re looking for, try checking out one of these popular pages: </p>
          <ul>
            <li><a href="/our-company">Our Company</a></li>
            <li><a href="/our-services">Our Services</a></li>
            <li><a href="/about-us">About Us</a></li>
          </ul>
        </div>
      </div>
    </ng-template>

    <div *ngIf="serverError;" class="search-results__info">
      {{ serverErrorLabel }}
    </div>

    <div class="search-results__list">
      <div *ngFor="let searchResultsItem of displayedSearchResults"
        class="logo-item large-4 small-6 cell {{!searchResultsItem.loaded ? 'hide' : null}}">
        <h3>
          <a href="{{searchResultsItem._source.url}}">{{searchResultsItem._source.title}}</a>
        </h3>
        <div *ngIf="searchResultsItem._source.description; then showDescription; else noDescription;">
        </div>
        <ng-template #showDescription>
          <p class="logo-item__desc">{{searchResultsItem._source.description}}</p>
        </ng-template>
        <ng-template #noDescription>
          <p class="logo-item__desc">{{noPageDescriptionLabel}}</p>
        </ng-template>
      </div>
      <div *ngIf="moreResultsToLoad;" class="search-results__list__load-more">
        <button (click)="loadMoreResults()" class="primary button">Load More</button>
      </div>
    </div>
  </div>
</main>
