import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment'

@Pipe({
  name: 'asset'
})
export class AssetPipe implements PipeTransform {

  newValue: string;

  // Currently only used in logo-grid component
  transform(value: string): string {
    if (value) {
      if (environment.hippoSitePrefix) {
        this.newValue = environment.hippoRootProto + "://" + environment.hippoRootUrl+ ":" + environment.hippoRootPort + "/" + environment.hippoSitePrefix + value;
      } else {
        this.newValue = environment.hippoRootProto + "://" + environment.hippoRootUrl + ":" + environment.hippoRootPort  + value;

      }
    }
    return this.newValue;
  }

}
