<div class="find-a-yard-map-section" *ngIf="(resultsList$ | async) as resultsList">
    <ng-container *ngIf="resultsList.length && status == 'default'">
        <agm-map class="map"
          *ngIf="resultsList.length"
          [zoom]="4"
          [maxZoom]="17"
          [mapDraggable]="true"
          [draggableCursor]="'default'"
          [zoomControl]="true"
          [streetViewControl]="false"
          [latitude]= "39.809734"
          [longitude]= "-98.555620"
          [scrollwheel]="false"
        >
          <ng-container *ngIf="!(activeLocation$ | async); else activeMarker">
            <agm-marker *ngFor="let result of resultsList; index as i;"
              [latitude]="result.lat" [longitude]="result.long"
              [iconUrl]="'../../../assets/images/map-icon-small.png'"
              [label]="{
                text: i + 1 | number,
                color: '#fff',
                fontWeight: '700',
                fontFamily: 'Source Sans Pro',
                fontSize: '0px',
                lineHeight: '45px'
              }"
              [title]="i + 1 | number"
              [agmFitBounds]="true"
              (markerClick)="setActiveLocation(result)"
            >
            </agm-marker>
          </ng-container>
          <ng-template #activeMarker>
            <ng-container *ngIf="(activeLocation$ | async) as activeLocation">
              <agm-marker 
                [latitude]="activeLocation.lat" [longitude]="activeLocation.long"
                [iconUrl]="'../../../assets/images/map-icon-small.png'"
                [label]="{
                  text: activeLocation.index + 1 | number,
                  color: '#fff',
                  fontWeight: '700',
                  fontFamily: 'Source Sans Pro',
                  fontSize: '0px',
                  lineHeight: '45px'
                }"
                [title]="activeLocation.index + 1 | number"
                [agmFitBounds]="true"
              >
              </agm-marker>
            </ng-container>
          </ng-template>
        </agm-map>
      </ng-container>

  <ng-container *ngIf="resultsList.length && status == 'active' && prevStatus == 'active'">
    <agm-map class="map"
      *ngIf="resultsList.length"
      [mapDraggable]="true"
      [draggableCursor]="'default'"
      [zoomControl]="true"
      [streetViewControl]="false"
      [fitBounds]="resultsList && resultsList.length ? true : false"
      [maxZoom]="17"
      [scrollwheel]="false"
    >
      <ng-container *ngIf="!(activeLocation$ | async); else activeMarker">
        <agm-marker *ngFor="let result of resultsList; index as i;"
          [latitude]="result.lat" [longitude]="result.long"
          [iconUrl]="'../../../assets/images/map-icon.png'"
          [label]="{
            text: i + 1 | number,
            color: '#fff',
            fontWeight: '700',
            fontFamily: 'Source Sans Pro',
            fontSize: '18px',
            lineHeight: '45px'
          }"
          [title]="i + 1 | number"
          [agmFitBounds]="true"
          (markerClick)="setActiveLocation(result)"
        >
        </agm-marker>
      </ng-container>
      <ng-template #activeMarker>
        <ng-container *ngIf="(activeLocation$ | async) as activeLocation">
          <agm-marker 
            [latitude]="activeLocation.lat" [longitude]="activeLocation.long"
            [iconUrl]="'../../../assets/images/map-icon.png'"
            [label]="{
              text: activeLocation.index + 1 | number,
              color: '#fff',
              fontWeight: '700',
              fontFamily: 'Source Sans Pro',
              fontSize: '18px',
              lineHeight: '45px'
            }"
            [title]="activeLocation.index + 1 | number"
            [agmFitBounds]="true"
          >
          </agm-marker>
        </ng-container>
      </ng-template>
    </agm-map>
  </ng-container>


  <ng-container *ngIf="resultsList.length && status == 'active' && prevStatus == 'default'">
      <agm-map class="map"
        *ngIf="resultsList.length"
        [mapDraggable]="true"
        [draggableCursor]="'default'"
        [zoomControl]="true"
        [streetViewControl]="false"
        [fitBounds]="resultsList && resultsList.length ? true : false"
        [maxZoom]="17"
        [scrollwheel]="false"
      >
        <ng-container *ngIf="!(activeLocation$ | async); else activeMarker">
          <agm-marker *ngFor="let result of resultsList; index as i;"
            [latitude]="result.lat" [longitude]="result.long"
            [iconUrl]="'../../../assets/images/map-icon.png'"
            [label]="{
              text: i + 1 | number,
              color: '#fff',
              fontWeight: '700',
              fontFamily: 'Source Sans Pro',
              fontSize: '0px',
              lineHeight: '45px'
            }"
            [title]="i + 1 | number"
            [agmFitBounds]="true"
            (markerClick)="setActiveLocation(result)"
          >
          </agm-marker>
        </ng-container>
        <ng-template #activeMarker>
          <ng-container *ngIf="(activeLocation$ | async) as activeLocation">
            <agm-marker 
              [latitude]="activeLocation.lat" [longitude]="activeLocation.long"
              [iconUrl]="'../../../assets/images/map-icon.png'"
              [label]="{
                text: activeLocation.index + 1 | number,
                color: '#fff',
                fontWeight: '700',
                fontFamily: 'Source Sans Pro',
                fontSize: '0px',
                lineHeight: '45px'
              }"
              [title]="activeLocation.index + 1 | number"
              [agmFitBounds]="true"
            >
            </agm-marker>
          </ng-container>
        </ng-template>
      </agm-map>
    </ng-container>


</div>
