import { Component, OnInit, OnDestroy, Input, Pipe, PipeTransform } from '@angular/core';
import { LocationsService } from '../../services/locations.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Breakpoints } from '../../enums/breakpoints.enum';
import { Location } from '../../global-classes/location';
import { FormGroup, FormControl } from '@angular/forms';
import { FullStateNamePipe } from '../../directives-and-pipes/fullNameState.pipe';

import {
  Component as BrComponent,
  Document,
  Page,
} from "@bloomreach/spa-sdk";
@Component({
  selector: "app-find-a-yard-search",
  templateUrl: "./find-a-yard-search.component.html",
  styleUrls: ["./find-a-yard-search.component.scss"]
})
export class FindAYardSearchComponent implements OnInit, OnDestroy {
  @Input() configComp: any;
  config: any;
  findHeading: string;
  findButtonText: string;
  findPlaceholderText: string;
  findSelectPlaceholderText: string;
  stateSelectPlaceholderText: string;
  orLabel: string;
  findCompanies: string[];
  stateShortNames: string[];
  isLargeScreen: boolean;
  activeLocation: Location;
  alternativeLocations: String[];
  displayAlternativeLocations: Boolean;
  alternativeResultFocus: number;

  paramsSub: Subscription;
  layoutChangesLargeSub: Subscription;
  activeLocationSub: Subscription;
  allLocationsSubj: Subscription;
  alternativeLocationsSub: Subscription;

  status: string;
  prevStatus: string;

  findAYardForm = new FormGroup({
    searchQuery: new FormControl(""),
    companyName: new FormControl(""),
    stateName: new FormControl("")
  });

  @Input() component!: BrComponent;
  @Input() page!: Page;

  constructor(
    private _locationsService: LocationsService,
    private router: Router,
    private route: ActivatedRoute,
    private _breakpointObserver: BreakpointObserver,
    private FullStateNamePipe: FullStateNamePipe
  ) {
  }

  ngOnInit() {
    // There are two ways of getting the document from the page instance by specifying the names or from the docContent
    // Works similar to getNestedObject from the previous Bloomreach SDK version
    let { document } = this.page.getComponent('config', 'container', 'configuration-values').getModels();
    // let { document } = this.configComp.getComponent('config', 'container', 'configuration-values').getModels();

    let docContent = this.page.getContent<Document>(document);

    this.config = docContent?.getData();

    this._locationsService.currentStatus.subscribe(
      status => (this.status = status)
    );
    this._locationsService.previousStatus.subscribe(
      prevStatus => (this.prevStatus = prevStatus)
    );
    this.findHeading = this.config.findHeading;
    this.findButtonText = this.config.findMeButtonText;
    this.findPlaceholderText = this.config.findPlaceholderText;
    this.orLabel = this.config.orLable;
    this.findSelectPlaceholderText = this.config.findSelectPlaceholder;
    this.stateSelectPlaceholderText = this.config.stateSelectPlaceholder ? this.config.stateSelectPlaceholder : 'Select a State or Province';
    this.displayAlternativeLocations = false;
    this.alternativeResultFocus = -1;

    this._locationsService.allLocations$.subscribe(locationsList => {
      if (locationsList && locationsList.length) {
        let stateShortCodes = ["AB", "AK", "AL", "AR", "AZ", "BC", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "IA", "ID", "IL", "IN", "KS", "KY", "LA", "MA", "MB", "MD", "ME", "MI", "MN", "MO", "MS", "MT", "NB", "NC", "ND", "NE", "NF", "NH", "NJ", "NM", "NS", "NT", "NU", "NV", "NY", "OH", "OK", "ON", "OR", "PA", "PE", "QC", "RI", "SC", "SD", "SK", "TN", "TX", "UT", "VA", "VT", "DC", "WA", "WI", "WV", "WY", "YT"];

        let _self = this;

        this.stateShortNames = stateShortCodes.sort(function(a:string, b:string) {
          let firstState = _self.FullStateNamePipe.transform(a);
          let secondState = _self.FullStateNamePipe.transform(b);
          if(firstState < secondState) { return -1; }
          if(firstState > secondState) { return 1; }
          return 0;
        });
      } else {
        this.stateShortNames = [];
      }
    });

    this.allLocationsSubj = this._locationsService.allLocations$.subscribe(
      locationsList => {
        if (locationsList && locationsList.length) {
          this.findCompanies = Array.from(
            new Set(locationsList.map(curLocation => curLocation.company).filter(curLocation => curLocation.indexOf('/') === -1))
          );

          this.findCompanies = this.findCompanies.sort();
          const searchQuery = this.route.snapshot.paramMap.get("searchQuery");
          const search = this.route.snapshot.paramMap.get("searchQuery");
          if (searchQuery) {
            this._locationsService.getLocations(searchQuery);
          } else {
            this._locationsService.getLocations(search);
          }
          if (this.prevStatus === "default") {
            // do nothing
          } else {
            this._locationsService.setPrevStatus("active");
          }
        } else {
          this.findCompanies = [];
        }
      }
    );

    this.paramsSub = this.route.queryParams.subscribe(params => {
      const searchQuery = params.searchQuery;
      const search = params.search;
      if (searchQuery) {
        this.findAYardForm.get("searchQuery").setValue(searchQuery);
        this._locationsService.getLocations(searchQuery);
      } else if (search) {
        this.findAYardForm.get("searchQuery").setValue(search);
        this._locationsService.getLocations(search);
      }
    });

    this.activeLocationSub = this._locationsService.activeLocation$.subscribe(
      newActiveLocation => {
        this.activeLocation = newActiveLocation;
      }
    );

    const layoutChangesLarge$ = this._breakpointObserver.observe([
      Breakpoints.large
    ]);

    this.layoutChangesLargeSub = layoutChangesLarge$.subscribe(result => {
      if (result.matches) {
        this.isLargeScreen = true;
      } else {
        this.isLargeScreen = false;
      }
    });

    this.alternativeLocationsSub = this._locationsService.alternativeLocations$.subscribe(locationsList => {
      this.alternativeLocations = locationsList;
    });
  }

  ngOnDestroy() {
    this.paramsSub.unsubscribe();
    this.layoutChangesLargeSub.unsubscribe();
    this.activeLocationSub.unsubscribe();
    this.allLocationsSubj.unsubscribe();
  }

  getAlternativeLocations(newSearchQuery: string) {
    this._locationsService.getAlternativeLocations(newSearchQuery);
  }

  hideAlternativeLocations() {
    this.displayAlternativeLocations = false;
  }

  showAlternativeLocations(newSearchQuery: string) {
    if(this.alternativeLocations.length === 0) {
      this._locationsService.getAlternativeLocations(newSearchQuery);
    }
    this.displayAlternativeLocations = true;
  }

  clickedLocation() {

  }

  selectResult(event) {
    switch (event.key) {
      case "ArrowUp":
        // Select the previous result from the array
        if (this.alternativeResultFocus > 0) {
          this.alternativeResultFocus--;
        }

        return false;

      case "ArrowDown":
        // Select next result from the array
        if (this.alternativeResultFocus < this.alternativeLocations.length - 1) {
          this.alternativeResultFocus++;
        } else if (this.alternativeResultFocus > this.alternativeLocations.length - 1) {
          this.alternativeResultFocus = 0;
        }

        return false;

      case "Escape":
        // Close the menu
        this.alternativeLocations = [];
        return false;

      case "Enter":
        // Get the value from the form in case of user input
        let query = this.findAYardForm.value.searchQuery;

        if(this.alternativeLocations.length !== 0) {
          // If there are results and focus is on one of them
          let index = this.alternativeResultFocus >= 0 ? this.alternativeResultFocus : 0;
          // Get the query from the selected result
          query = this.alternativeLocations[index]['display_name'];
        }

        this.router.navigate(['find-a-yard'], { queryParams: { searchQuery: query } });

        // Clear the autocomplete results
        this.alternativeLocations = [];
        return false;

      default:
        return event;
    }
  }


  changeFocusToSelf(index) {
    // Set the focus to the received index
    this.alternativeResultFocus = index;
  }

  getLocationsFromSearch(newSearchQuery: string) {
    this._locationsService.setPrevStatus('active');
    this._locationsService.changeStatus('active');
    this.findAYardForm.get('companyName').setValue('');
    this.router.navigate(['find-a-yard'], { queryParams: { searchQuery: newSearchQuery } });
    this._locationsService.setActiveLocation(null);
  }

  getLocationsByCompany(newCompany: string) {
    this._locationsService.setPrevStatus("active");
    this._locationsService.changeStatus("active");
    this.findAYardForm.get("searchQuery").setValue("");
    this.findAYardForm.get("stateName").setValue("");
    this._locationsService.getLocationsByCompany(newCompany);
    this.router.navigate(["find-a-yard"], {
      queryParams: { company: newCompany }
    });
    this._locationsService.setActiveLocation(null);
  }

  getStatesFromCompanies(newState: string) {
    this._locationsService.setPrevStatus("active");
    this._locationsService.changeStatus("active");
    this.findAYardForm.get("companyName").setValue("");
    this.findAYardForm.get("searchQuery").setValue("");
    this._locationsService.setStateShortName(newState);
    this.router.navigate(["find-a-yard"], {
      queryParams: { state: newState }
    });
    this._locationsService.setActiveLocation(null);
  }

  initializeGeoLocation() {
    this._locationsService.setPrevStatus("active");
    this._locationsService.changeStatus("active");
    if (
      typeof window !== "undefined" &&
      window.navigator &&
      window.navigator.geolocation
    ) {
      this.router.navigate(["find-a-yard"], {
        queryParams: { location: "geolocation" }
      });
      window.navigator.geolocation.getCurrentPosition(
        position => {
          this._locationsService.getLocations(
            "",
            position.coords.latitude,
            position.coords.longitude
          );
        },
        error => {
          switch (error.code) {
            case 1:
              console.log("Permission Denied");
              break;
            case 2:
              console.log("Position Unavailable");
              break;
            case 3:
              console.log("Timeout");
              break;
          }
        }
      );
    }
  }
}
