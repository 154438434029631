<div class="page-break">
  <div class="page-break__wrapper">
    <div class="grid-container grid-x">
      <div class="cell large-12 small-12">
        <hr>
      </div>
    </div>
  </div>
</div>

