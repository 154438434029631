import { Component, OnInit, Input } from "@angular/core";
import { NguCarouselConfig } from "@ngu/carousel";
import { LinkOptions } from '../../global-classes/link-options';

// Upgrade
import { Component as BrComponent, Document, ImageSet, Page, Reference } from '@bloomreach/spa-sdk';

@Component({
  selector: "app-image-carousel",
  templateUrl: "./image-carousel.component.html",
  styleUrls: ["./image-carousel.component.scss"],
})
export class ImageCarouselComponent implements OnInit {
  // @Input() configuration: any;
  @Input() component!: BrComponent;
  @Input() page!: Page;

  imageHeroCTA: string;
  slides: {
    linkOptions: LinkOptions;
    largeImage: string;
    smallImage: string;
  }[];

  public carouselTileConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, all: 0 },
    speed: 500,
    point: {
      visible: true,
      hideOnSingleSlide: true,
    },
    touch: true,
    loop: true,
    velocity: 0.5,
    animation: "lazy",
    easing: "cubic-bezier(0.35, 0, 0.25, 1)",
    RTL: false,
  };

  public carouselTileConfigOneItem: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, all: 0 },
    speed: 500,
    point: {
      visible: true,
      hideOnSingleSlide: true,
    },
    touch: false,
    loop: true,
    velocity: 0.5,
    animation: "lazy",
    easing: "cubic-bezier(0.35, 0, 0.25, 1)",
    RTL: false,
  };

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get data() {
    let data = this.document?.getData();
    return data;
  }

  ngOnInit() {
    // super.ngOnInit();

    if (this.data) {
      this.slides = this.data.images.map(
        (
          curImg: {
            linkOptions: LinkOptions,
            largeImage: Reference;
            smallImage: Reference;
          },
          index: number
        ) => {
          let linkOptions: LinkOptions = {
            url: "",
            text: "",
            isExternal: false,
            newTab: false,
            isAsset: false,
          };
          if (this.data.link[index]) {
            linkOptions.isExternal = this.data.link[index].isExternal;
            linkOptions.newTab = this.data.link[index].newTab;
            linkOptions.text = this.data.link[index].text;
            let linkDocument = this.page.getContent<any>(this.data.link[index].primaryDocument);
            if(this.data.link[index].primaryDocument) {
              if(typeof linkDocument.type !== 'undefined' && linkDocument.type === 'asset') {
                linkOptions.url = linkDocument.data.asset.links.site.href;
              } else {
                linkOptions.url = linkDocument.getUrl();
              }
            }
            if (linkOptions.url.includes("assets")) {
              linkOptions.isAsset = true;
            } else {
              linkOptions.isAsset = false;
            }
          }
          return {
            linkOptions: linkOptions,
            largeImage: curImg.largeImage
              ? this.page.getContent<ImageSet>(curImg.largeImage.$ref).getOriginal().getUrl()
              : null,
            smallImage: curImg.smallImage
              ? this.page.getContent<ImageSet>(curImg.smallImage.$ref).getOriginal().getUrl()
              : null,
          };
        }
      );
    }
  }
}
