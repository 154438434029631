import { Component, OnInit, Inject, PLATFORM_ID, Optional, InjectionToken } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../environments/environment';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { SeoService } from './services/seo.service';
import { GlobalHeaderComponent } from './global-components/global-header/global-header.component';
import { GlobalFooterComponent } from './global-components/global-footer/global-footer.component';
import { CookieInfoComponent } from './global-components/cookie-info/cookie-info.component';

/*** Upgrade ****/
import { BrPageComponent } from '@bloomreach/ng-sdk';
import { TYPE_CONTAINER_ITEM_UNDEFINED } from '@bloomreach/spa-sdk';
import { FallbackComponent } from './fallback-component/fallback.component';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

// export const ENDPOINT = new InjectionToken<string>('brXM API endpoint');
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  // Make available for template
  GlobalHeaderComponent = GlobalHeaderComponent;
  GlobalFooterComponent = GlobalFooterComponent;
  CookieInfoComponent = CookieInfoComponent;

  isHomePage = false;
  initComplete: boolean;
  title = 'gms-app';
  isBrowser: boolean;
  overlayActive: boolean;
  routerSub: Subscription;
  isLoading = true;
  isLoadingPageComponent = true;
  isPageContentNotFound: boolean;
  isPageProductNotFound: boolean;

  /**
   * Mapping of hippo component to angular component.  Format is "hipp component": angularComponent.
   * DEVELOPER NOTE: Anything mapped to a hippo component will need to be included in the entryComponents
   * array in ngModule of app.module.ts.
   * DEVELOPER UPDATE: Components are now mapped in the index.component.ts after the upgrade to bloomreach-ng-sdk version 1.0
   */
  componentMappings = {
    [TYPE_CONTAINER_ITEM_UNDEFINED]: FallbackComponent,

  };

  mapping = {
    [TYPE_CONTAINER_ITEM_UNDEFINED]: FallbackComponent,
  };

  configuration: BrPageComponent['configuration'];

  // Url overrides for the hippo angular sdk.  Live url is published content, preview is unpublished.

  apiUrls = {};

  private navigationEnd: Observable<NavigationEnd>;

  constructor(
    private router: Router,
    private seoService: SeoService,
    location: Location,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

      const config = {
        endpoint: environment.endpoint,
        request: { path: location.path() },
        endpointQueryParameter: 'endpoint',
        path: router.url,
        origin: environment.cms
    } as BrPageComponent['configuration'];
    this.configuration = config;

    this.navigationEnd = router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
    ) as Observable<NavigationEnd>;

  }

  ngOnInit() {
    // If this is not the prod environment, add robots header to prevent indexing.
    if (!environment.prodServer) {
      this.seoService.createMetaRobots();
    }
  }
}
