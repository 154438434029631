<form *ngIf="(isLargeScreen || !activeLocation)" class="find-a-yard-search" [formGroup]="findAYardForm" autocomplete="off">
  <h1>{{findHeading}}</h1>
  <a class="button secondary" (click)="initializeGeoLocation()"><span class="icon-locate-icon"></span><span class="search-text">{{findButtonText}}</span></a>
  <label class="find-a-yard-search__text-input">
    <span class="show-for-sr">{{findPlaceholderText}}</span>
    <input matInput
        (change)="getLocationsFromSearch(searchQuery.value)"
        (input)="getAlternativeLocations(searchQuery.value)"
        (keydown)="selectResult($event)"
        (focusout)="hideAlternativeLocations()"
        (focusin)="showAlternativeLocations(searchQuery.value)"
        type="text" placeholder="{{findPlaceholderText}}"
        #searchQuery formControlName="searchQuery">
    <a (click)="getLocationsFromSearch(searchQuery.value)" class="icon-search-icon" aria-hidden="true"></a>
    <div class="alt-locations" [ngClass]="displayAlternativeLocations ? '' : 'hide'">
      <div *ngFor="let location of alternativeLocations; let i = index"
          class="alt-location"
          [attr.data-index]="i"
          [ngClass]="{'focused': i === alternativeResultFocus}"
          (mousedown)="getLocationsFromSearch(location.display_name)"
          (onmouseover)="changeFocusToSelf(i)">
        {{ location.display_name }}
      </div>
    </div>
  </label>

  <span class="desktop">{{ orLabel }}</span>

  <mat-form-field class="mat-remove-spacing">
    <mat-label class="show-for-sr">{{findSelectPlaceholderText}}</mat-label>
    <mat-select class="vendorDropDown" placeholder="{{findSelectPlaceholderText}}"
      (valueChange)="getLocationsByCompany($event)" formControlName="companyName">
      <mat-label>
        Name
      </mat-label>
      <mat-option *ngFor="let company of findCompanies" value="{{company}}">{{company}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label class="show-for-sr">{{ stateSelectPlaceholderText }}</mat-label>
    <mat-select class="vendorDropDown" placeholder="{{ stateSelectPlaceholderText }}"
      (valueChange)="getStatesFromCompanies($event)" formControlName="stateName">
      <mat-label>
        State or Province
      </mat-label>
      <mat-option *ngFor="let state of stateShortNames" value="{{state}}">{{ state | FullStateNamePipe }}</mat-option>
    </mat-select>
  </mat-form-field>
</form>
