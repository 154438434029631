import { Component, OnInit, Input } from '@angular/core';

// Upgrade
import { Component as BrComponent, Document, ImageSet, Page } from '@bloomreach/spa-sdk';

@Component({
  selector: 'app-product-tiles',
  templateUrl: './product-tiles.component.html',
  styleUrls: ['./product-tiles.component.scss']
})
export class ProductTilesComponent implements OnInit {
  productTiles: {
    title: string,
    desc: string,
    image: string,
    link: string,
    newTab: boolean,
    assetLink: boolean
  }[];

  portraitMode: boolean;

  @Input() component!: BrComponent;
  @Input() page!: Page;

  voidLink = (event, linkElem) => {
    if(linkElem.getAttribute("href") === '#') {
      event.preventDefault();
    }
  }

  // Populate the document variable, will return the model data from the Bloomreach API
  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  // Populate the data variable, will return the data from document
  get data() {
    let data = this.document?.getData();
    return data;
  }

  ngOnInit() {
    if (this.data) {
      this.portraitMode = this.data.portrait;
      this.productTiles = this.data.productTiles.map(
      (curTile: {
        productName: string,
        productDescription: string,
        productImage: string,
        productLink: string,
        newTab: string
      }
      ) => {
        let productData = this.page.getContent<any>(curTile.productLink);
        let productLink = '';
        if(productData && curTile.productLink) {
          if(typeof productData.type !== 'undefined' && productData.type === 'asset') {
            productLink = productData.data.asset.links.site.href;
          } else {
            productLink = this.page.getContent<Document>(curTile.productLink).getUrl()!;
          }
        } else {
          productLink = '/404';
        }
        return {
          title: curTile.productName,
          desc: curTile.productDescription,
          image: curTile.productImage ? this.page.getContent<ImageSet>(curTile.productImage ).getOriginal().getUrl() : null,
          link: productLink,
          newTab: curTile.newTab,
          assetLink: productLink.includes('assets') ? true : false,
        };
      });
    }
  }
}
