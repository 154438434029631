import { Injectable, PLATFORM_ID, Inject, Optional } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';

import { catchError, tap } from 'rxjs/operators';

import { Observable, throwError } from 'rxjs';
import { environment } from '../environments/environment';
import { RESPONSE, REQUEST } from '@nguniversal/express-engine/tokens';
import { isPlatformBrowser } from '@angular/common';
// import { Request } from 'hapi';
import { getNestedObject } from './misc-utils/utility-methods';


/**
 * Intercepts all outgoing http requests and incoming responses.  Allows us to globally handle errors and redirect when needed.
 * Also useful if we need to include an auth token on requests.
 */
@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) platformId: Object,
    // @Optional() @Inject(REQUEST) private hapiRequest: Request,
    @Optional() @Inject(RESPONSE) private response: any
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  /**
   * Method called on all http requests/responses.
   * @param request the outgoing request object.
   * @param next HttpHandler that returns the observable from the http response.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes('https://maps.googleapis.com/maps')) {
      request = request.clone({
        // Workaround for sdk requests doubling up on the cmsinternal and site vars.
        url: request.url.replace('/site/_cmsinternal/resourceapi/site/_cmsinternal', '/site/_cmsinternal/resourceapi')
      });
    }

    if(request.url.includes('/fr')) {
      // If there is a site prefix to the resourceapi endpoint add it from environment and access the resource api that way
      let frenchPrefix = environment.hippoSitePrefix !== '' ? '/' + environment.hippoSitePrefix : '';
      request = request.clone({
        // Workaround for french translations
        url: request.url.replace(`${frenchPrefix}/resourceapi/fr/`, `${frenchPrefix}/fr/resourceapi/`)
      });
    }

    return next.handle(request)
      .pipe(
        tap((curResponse: HttpResponse<any>) => {
          if (request.url.includes(environment.hippoRootUrl) && request.url.includes('resourceapi') && curResponse) {
            const body = curResponse.body;
            if (body) {
              const metaId = getNestedObject(body, ['page', '_meta', 'definitionId']);

              if (metaId === 'hst:pages/pagenotfound') {
                this._throw404();
              }
            }
          }

          return curResponse;
        })
      )
      .pipe(catchError(error => {
        if (
          (
            request.url.includes(environment.hippoRootUrl)
          ) &&
          error.status === 404
        ) {
          this._throw404();
          return throwError(error);
        } else {
          // If we get here, this is not a specific error we've accounted for.  Just log the error object.
          return throwError(error);
        }
      })
    );
  }

  private _throw404() {
    if (!this.isBrowser) {
      this.response.statusCode = 404;
      // this.hapiRequest.raw.res = this.response;
    }
  }

}
