import { Component, OnInit, OnDestroy, Input, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormControl } from "@angular/forms";
import { SearchService } from "../../services/search.service";
import { Subscription, from } from "rxjs";
import { DOCUMENT } from "@angular/common";

// Upgrade
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';

@Component({
  selector: 'app-search-results-list',
  templateUrl: './search-results-list.component.html',
  styleUrls: ['./search-results-list.component.scss']
})
export class SearchResultsListComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;

  config: any;

  selectedFilter: string = "";
  searchResultsLabel: string;
  searchResultsFoundLabel: string;
  noSearchResults: string;
  noPageDescriptionLabel: string;
  searchResultsCount: number;
  searchTerm: Subscription;
  serverError: boolean = false;
  serverErrorLabel: string;
  showMobileFilterMenu: boolean = false;
  // Used for the loading animation
  isLoading: boolean = false;
  // Used for displaying the load more button
  moreResultsToLoad: boolean = false;
  elasticSearchForm = new FormGroup({
    searchQuery: new FormControl("")
  });
  searchResultsList: {
    heading: string;
    headingLink: string;
    description: string;
  }[];

  // Filter categories
  filterCategories: any = [
    {
      section: "",
      label: "All Results"
    },
    {
      section: "about-gms",
      label: "About GMS"
    },
    {
      section: "careers",
      label: "Careers"
    },
    {
      section: "products",
      label: "Products"
    },
    {
      section: "safety",
      label: "Safety"
    },
    {
      section: "servicies",
      label: "Services"
    },
    {
      section: "subsidiaries",
      label: "Subsidiaries"
    },
    {
      section: "vendors",
      label: "Vendors"
    }
  ];
  searchTerms: any = [];
  searchResults: any = [];
  displayedSearchResults: any = [];

  constructor(
    // imageUrlService: ImageUrlService,
    // pageModelService: PageModelService,
    private searchService: SearchService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(DOCUMENT) public document: any
  ) {}

  ngOnInit() {
    // super.ngOnInit();

    // Get the configuration values component
    const configContentRef = this.page
    .getComponent("search-config", "container", "configuration-values")
    .getModels();

    // Get the contnet based on the ref provided by the configuration values component
    const configContent = this.page.getContent(configContentRef.document.$ref);

    // Get the data and provide it in the config variable
    this.config = configContent.getData();

    this.searchResultsCount = 0;
    this.serverError = false;
    this.serverErrorLabel = this.config.serverErrorLabel;
    this.searchResultsFoundLabel = this.config.searchResultsFoundLabel;
    this.noPageDescriptionLabel = this.config.noPageDescriptionLabel;

    this.route.queryParams.subscribe(params => {
      const searchQuery = params.query;
      if (searchQuery) {
        this.searchTerm = searchQuery;
        this.noSearchResults = this.config.noSearchResultsLabel + " “" + this.searchTerm + "”";
        this.getSearchResults(this.searchTerm);
      }
    });

    this.resetFilterCount();
  }

  getClientWidth() {
    return this.document.documentElement.clientWidth;
  }

  resetClientWidth($event) {
    return this.document.documentElement.clientWidth;
  }

  updateFilter(filterItem) {
    this.toggleMobileFilterMenu();

    this.selectedFilter = filterItem.section;

    if (typeof filterItem.section !== "undefined" && filterItem.section !== '') {
      this.displayedSearchResults = this.searchResults.filter(result => {
        return result._source.section === filterItem.section;
      });
    } else {
      this.displayedSearchResults = this.searchResults;
    }

    this.searchResultsCount = this.displayedSearchResults.length;
    this.updateLoadMore();

    if(this.searchResultsCount === 0) {
      this.getTermsResults();
    }
  }

  getTermsResults() {
    this.searchService
    .getElasticTermsResults(this.searchTerm)
    .subscribe(data => {
      this.searchTerms = data;
    });
  }

  submitSearchForm(newSearchQuery: string) {
    if (newSearchQuery !== "") {
      this.getSearchResults(newSearchQuery);
      this.router.navigate(["/search-results/"], {
        queryParams: { query: newSearchQuery }
      });
      // this.searchTerm.next(newSearchQuery);
    }
  }

  getSearchResults(newSearchQuery) {
    this.searchResults = [];
    // Set the loading flag
    this.isLoading = true;
    // Add the necessary class to body
    this.document.body.classList.add("is-loading");

    // Use the search service to fetch the results
    this.searchService
      .getElasticSearchResults(newSearchQuery)
      .subscribe(data => {
        // Update the results and the count with data from the request
        this.searchResults = data.hits.hits;
        this.displayedSearchResults = this.searchResults;
        this.searchResultsCount = data.hits.total.value;

        // Set the loading flag to false once the request has finished
        this.isLoading = false;
        // Remove the loading class from the body tag
        this.document.body.classList.remove("is-loading");

        this.resetFilterCount();

        // If the search results don't return anything
        if(this.searchResultsCount === 0) {
          // Get the terms and render them
          this.getTermsResults();
        }

        // Handle the results count for each tab
        this.searchResults.forEach(element => {
          // Find element in array of filters and get the index
          let sectionIndex = this.filterCategories.find(item => {
            return item.section === element._source.section;
          });

          // If higher than -1, which means its present in the array
          if (typeof sectionIndex !== "undefined") {
            // Update the count
            sectionIndex.count++;
          }
        });

        // Set the main category of filters count equal to the search results count
        let allResultsSection = this.filterCategories.find(item => {
          return item.section === '';
        })

        allResultsSection.count = this.searchResultsCount;

        this.updateLoadMore();
      }, error => {
        // Set the loading flag to false once the request has finished
        this.isLoading = false;
        // Remove the loading class from the body tag
        this.document.body.classList.remove("is-loading");
        // Display the server error template
        this.serverError = true;
      });
  }

  // Init each filter categorys count with 0's
  resetFilterCount() {
    this.filterCategories.forEach(element => {
      element.count = 0;
    });
  }

  toggleMobileFilterMenu() {
    this.showMobileFilterMenu = !this.showMobileFilterMenu;
    this.updateLoadMore();
  }

  updateLoadMore() {
    for (let i = 0; i < this.displayedSearchResults.length; i++) {
      this.displayedSearchResults[i].loaded = false;
    }
    // If there are no results don't display the load more button
    if (
      this.searchResultsCount === 0 ||
      this.searchResultsCount < 10
    ) {
      this.moreResultsToLoad = false;
      for (let i = 0; i < this.displayedSearchResults.length; i++) {
        this.displayedSearchResults[i].loaded = true;
      }
    } else {
      // Update the search results with a display flag
      // True for the first 10 elements to display the first 10
      for (let i = 0; i < 10 && i < this.displayedSearchResults.length; i++) {
        this.displayedSearchResults[i].loaded = true;
      }
      if (this.searchResultsCount > 10) {
        this.moreResultsToLoad = true;
      }
    }
  }

  loadMoreResults() {
    // Filter the elements that are already displayed
    let moreResults = this.displayedSearchResults.filter(result => {
      return result.loaded !== true;
    });

    // Update the next 10 results with the flag
    for (let i = 0; i < 10 && i < moreResults.length; i++) {
      moreResults[i].loaded = true;
    }

    // If there are no more elements to load, hide the button
    if (moreResults.length <= 10) {
      this.moreResultsToLoad = false;
    }
  }
}
