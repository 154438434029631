import {
  Component,
  OnInit,
  Input,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import {
  Component as BrComponent,
  Document,
  ImageSet,
  Page,
} from "@bloomreach/spa-sdk";

@Component({
  selector: "app-video-hero",
  templateUrl: "./video-hero.component.html",
  styleUrls: ["./video-hero.component.scss"],
})
export class VideoHeroComponent implements OnInit {
  videoHeroHeading: string;
  videoHeroImageLarge: string;
  videoHeroImageSmall: string;
  videoHeroImageDisplay: string;
  videoHeroCTA: string;
  videoHeroCTAUrl: string;
  videoHeroContent: string;
  videoHeroCTAIsExternal: boolean;
  videoHeroTimeVar: string;
  @ViewChild("videoHeroContainer") videoHeroContainer: ElementRef;

  iframe_html: any;
  YT: any;
  video: any;
  player: any;
  reframed: Boolean = false;
  public innerWidth: any;

  dimension: string = '';

  // @Input() configuration: any;
  @Input() component!: BrComponent;
  @Input() page!: Page;

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get data() {
    let data = this.document?.getData();
    return data;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 640) {
      if (this.data.smallImage) {
        this.videoHeroImageDisplay = this.videoHeroImageSmall;
      }
    } else {
      this.videoHeroImageDisplay = this.videoHeroImageLarge;
    }
  }

  init() {
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  ngOnInit() {
    this.init();

    // Change the styling based on the component label can be Hero Video, which is default and Medium Video which is a smaller version
    if( this.component.getName() === 'medium-video') {
      this.dimension = 'medium';
    }

    if (this.data) {
      this.videoHeroHeading = this.data.primaryText;
      if (this.data.largeImage) {
        this.videoHeroImageLarge = this.page
          .getContent<ImageSet>(this.data.largeImage)
          .getOriginal()
          .getUrl();
      }
      if (this.data.smallImage) {
        this.videoHeroImageSmall = this.page
          .getContent<ImageSet>(this.data.smallImage)
          .getOriginal()
          .getUrl();
      }
      if (this.data.link) {
        this.videoHeroCTA = this.data.link.text;
        this.videoHeroCTAUrl = this.data.link.url;
        let linkDocument = this.page.getContent<any>(
          this.data.link.primaryDocument
        );
        if (this.data.link.primaryDocument) {
          if (
            typeof linkDocument.type !== "undefined" &&
            linkDocument.type === "asset"
          ) {
            this.videoHeroCTAUrl = linkDocument.data.asset.links.site.href;
          } else {
            this.videoHeroCTAUrl = linkDocument.getUrl();
          }
        }
        this.videoHeroCTAIsExternal = this.data.link.isExternal;
      }

      let linkVar = this.videoHeroCTAUrl;
      linkVar = linkVar.substring(linkVar.indexOf("=") + 1); // get link
      let timeVar = linkVar.substring(linkVar.indexOf("=") + 1); // get time with s
      timeVar = timeVar.replace("s", ""); // remove s from time

      if (linkVar.includes("&")) {
        // check if link has time stamp
        linkVar = linkVar.substring(0, linkVar.indexOf("&")); // final link without ampersand
      }

      // console.log(linkVar, timeVar, finalLink);
      this.videoHeroTimeVar = timeVar;

      // tslint:disable-next-line:max-line-length
      this.videoHeroContent = this.data.secondaryText.value;

      this.videoHeroCTAUrl = linkVar;

      if (this.innerWidth < 640) {
        // check for which image to use for viewport size
        if (this.data.smallImage) {
          this.videoHeroImageDisplay = this.videoHeroImageSmall;
        }
      } else {
        this.videoHeroImageDisplay = this.videoHeroImageLarge;
      }
    }
  }

  onPlayerStateChange(event) {
    const videoElement = document.getElementById("videoHeroContainer");
    const videoBackground = document.getElementById(
      "videoHeroContainerBackground"
    );

    switch (event.data) {
      case window["YT"].PlayerState.PLAYING:
        if (this.cleanTime() === 0) {
          // console.log('started ' + this.cleanTime());
        } else {
          // console.log('playing ' + this.cleanTime() );
        }
        break;
      case window["YT"].PlayerState.PAUSED:
        if (this.player.getDuration() - this.player.getCurrentTime() !== 0) {
          // console.log('paused' + ' @ ' + this.cleanTime());
        }
        break;
      case window["YT"].PlayerState.ENDED:
        event.target.destroy();
        videoElement.classList.remove("hide");
        videoBackground.classList.remove("dim");
        break;
    }
  }

  // utility
  cleanTime() {
    return Math.round(this.player.getCurrentTime());
  }
  onPlayerError(event) {
    switch (event.data) {
      case 2:
        // console.log('' + this.video);
        break;
      case 100:
        break;
      case 101 || 150:
        break;
    }
  }

  clicky(event, val) {
    const videoElement = document.getElementById("videoHeroContainer");
    const videoBackground = document.getElementById(
      "videoHeroContainerBackground"
    );
    const viewWidth = window.innerWidth;
    const displayWidth = viewWidth - 5;

    videoElement.classList.add("hide");
    videoBackground.classList.add("dim");

    this.YT = window["YT"];
    this.reframed = false;

    if (viewWidth > 640) {
      this.player = new window["YT"].Player("hero_video_player", {
        height: "475",
        width: "1380",
        position: "absolute",
        videoId: val,
        playerVars: {
          autoplay: 1,
          modestbranding: 1,
          rel: 0,
          start: this.videoHeroTimeVar,
        },
        events: {
          onStateChange: this.onPlayerStateChange.bind(this),
          onError: this.onPlayerError.bind(this),
        },
      });
    } else {
      this.player = new window["YT"].Player("hero_video_player", {
        height: "200",
        width: displayWidth,
        // position: 'absolute',
        videoId: val,
        playerVars: {
          autoplay: 1,
          modestbranding: 1,
          rel: 0,
          start: this.videoHeroTimeVar,
        },
        events: {
          onStateChange: this.onPlayerStateChange.bind(this),
          onError: this.onPlayerError.bind(this),
        },
      });
    }
  }
}
