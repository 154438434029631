import { Component, OnInit, ViewChild, ElementRef, NgZone, OnDestroy, Input, 
  ChangeDetectionStrategy,
  ChangeDetectorRef, } from "@angular/core";
// import { BaseComponent, ImageUrlService, PageModelService, getNestedObject } from '../../upgrade/bloomreach';
// import { SingleContentComponent } from 'src/app/upgrade/SingleContentComponent';
import { ScrollDispatcher } from "@angular/cdk/scrolling";
import { Subscription, Observable } from "rxjs";
import { ContentAlignment } from "../../enums/content-alignment.enum";
import { GoogleLocationService } from "../../services/google-location.service";
import { LocationsService } from "../../services/locations.service";
import { Location } from "../../global-classes/location";
// import { map } from 'rxjs/operators';
// import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import {
  Component as BrComponent,
  Document,
  ImageSet,
  Page,
} from "@bloomreach/spa-sdk";

@Component({
  selector: "app-logo-grid",
  templateUrl: "./logo-grid.component.html",
  styleUrls: ["./logo-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class LogoGridComponent implements OnInit, OnDestroy {
  logoGridTitle: string;
  logoGridHeading: string;
  logoGridText: string;
  logoGridCTAText: string;
  logoGridCTAUrl: Document;
  assetLink: boolean;
  newTab: boolean;
  linkIsExternal: boolean;
  logoGridOrientation: string;
  logoGridImages: {
    url: string;
    image: string;
  }[];

  locatedLogoGridImages: any[];

  locationLat: number;
  locationLong: number;
  resultsList$: Observable<Location[]>;
  isLocated: boolean;

  @ViewChild("uStatic") uStatic: ElementRef;
  scrollDispSub: Subscription;
  isVisible = false;

  @Input() component!: BrComponent;
  @Input() page!: Page;

  // A mapping of options from hippo to ContentAlignment
  private readonly hippoContentAlignmentMap = {
    Left: ContentAlignment.Left,
    'left': ContentAlignment.Left,
    Center: ContentAlignment.Center,
    'center': ContentAlignment.Center,
    Right: ContentAlignment.Right,
    'right': ContentAlignment.Right,
  };

  constructor(
    private scrollDispatcher: ScrollDispatcher,
    private ngZone: NgZone,
    private googleLocationService: GoogleLocationService,
    private _locationsService: LocationsService,
    private cd: ChangeDetectorRef
  ) {}

  // Populate the document variable, will return the model data from the Bloomreach API
  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get config() {
    const { cparam } = this.component.getModels();
    return cparam;
  }

  // Populate the data variable, will return the data from document
  get data() {
    let data = this.document?.getData();
    return data;
  }

  ngOnInit() {
    // this.resultsList$ = this._locationsService.locations$;
    this.isLocated = false;
    this.getGoogleLocation();

    if (this.data) {
      // Assign the variables to their respective data entries from the data object
      this.logoGridTitle = this.data.header;
      this.logoGridHeading = this.data.primaryText.value;
      this.logoGridText = this.data.secondaryText;
      if (this.data.link) {
        this.logoGridCTAText = this.data.link.text;
        this.linkIsExternal = this.data.link.isExternal;
        this.newTab = this.data.link.newTab;
        // Get the URL for the CTA Link
        if(this.linkIsExternal) {
          this.logoGridCTAUrl = this.data.link.url;
        } else {
          // Extra step needed to get the URL
          this.logoGridCTAUrl = this.page.getContent<Document>(this.data.link.primaryDocument);
        }
        // if (this.logoGridCTAUrl.includes("assets")) {
        //   this.assetLink = true;
        // } else {
        //   this.assetLink = false;
        // }
      }
    }

    this.logoGridImages = this.data.logoitems.map(
      (curLogo: { logoLink: string; logoImage: string }) => {
        return {
          url: this.page.getContent<Document>(curLogo.logoLink)?.getUrl(),
          image: this.page.getContent<ImageSet>(curLogo.logoImage)?.getOriginal()?.getUrl(),
        };
      }
    );

    if(this.config) {
      this.logoGridOrientation = this.hippoContentAlignmentMap[this.config.alignment]
    }

    setTimeout(() => {
      if (this.uStatic && this.uStatic.nativeElement) {
        this.scrollDispSub = this.scrollDispatcher
          .ancestorScrolled(this.uStatic)
          .subscribe(() => {
            const elementTop =
              this.uStatic.nativeElement.getBoundingClientRect().top;
            const elementHeight = this.uStatic.nativeElement.offsetHeight;
            const winHeight = window.innerHeight;
            const checkValue = winHeight - elementHeight - 100;

            if (elementTop < checkValue && !this.isVisible) {
              this.ngZone.run(() => {
                this.isVisible = true;
                setTimeout(() => {
                  this.cd.markForCheck();
                });
              });
            }
          });
      }
    });
  }

  ngOnDestroy() {
    if (this.scrollDispSub) {
      this.scrollDispSub.unsubscribe();
    }
  }

  getGoogleLocation() {
    this.googleLocationService.getLocation().subscribe((data) => {
      this.locationLat = data.location.lat;
      this.locationLong = data.location.lng;
      this.getLocations(this.locationLat, this.locationLong);
    });
  }

  getLocations(lat, lng) {
    this._locationsService._setGoogleLocation(lat, lng);
    this.resultsList$ = this._locationsService.locations$;
    this.resultsList$.subscribe((resultsList$) => {
      if (resultsList$.length > 0) {
        // reduce results by removing duplicates
        const uniqCompany_map = (prop) => (arr) =>
          Array.from(
            arr
              .reduce(
                (acc, item) => (
                  item && item[prop] && acc.set(item[prop], item), acc
                ),
                new Map()
              )
              .values()
          );
        const uniqueByCompany = uniqCompany_map("company");
        const unifiedArray = uniqueByCompany(resultsList$);

        this.isLocated = true;
        this.locatedLogoGridImages = unifiedArray;
      }
    });
  }
}
