import {
  Component,
  OnInit,
  Input,
  HostListener,
  Inject,
  PLATFORM_ID,
} from "@angular/core";
import { TrueFalse } from "../../enums/true-false.enum";
import { ContentAlignment } from "../../enums/content-alignment.enum";
import { HeroHeight } from "../../enums/hero-height.enum";

// Upgrade
import {
  Component as BrComponent,
  Document,
  ImageSet,
  Page,
} from "@bloomreach/spa-sdk";
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: "app-global-hero",
  templateUrl: "./global-hero.component.html",
  styleUrls: ["./global-hero.component.scss"],
})
export class GlobalHeroComponent implements OnInit {
  // @Input() configuration: any;
  @Input() globalHeroIsPageTitle: TrueFalse;
  @Input() globalHeroContentAlign: ContentAlignment;
  @Input() globalHeroBanner: TrueFalse;

  @Input() component!: BrComponent;
  @Input() page!: Page;

  globalHeroHeading: string;
  globalHeroOrientation: string;
  globalHeroImageLarge: string;
  globalHeroImageSmall: string;
  globalHeroImageDisplay: string;
  globalHeroImageAlt: string;
  globalHeroCTA: string;
  globalHeroCTAUrl: string;
  globalHeroCTAIsExternal: boolean;
  globalHeroContentWidth: string;
  globalHeroContent: string;
  globalHeroType: string;

  globalHeroBannerColor: string;
  globalHeroBannerTextColor: string;
  globalHeroBannerImage: string;
  globalHeroBannerText: string;
  globalHeroBannerLink: string;
  globalHeroBannerLinkText: string;
  globalHeroBannerLinkIsExternal: boolean;
  globalHeroBannerLinkNewTab: boolean;
  globalHeroBannerLinkAssetLink: boolean;

  newTab: boolean;
  globalHeroHeader: string;
  ieVar: string;
  assetLink: boolean;
  isBrowser: boolean;

  public innerWidth: any;

  // Expose to template
  TrueFalse = TrueFalse;
  ContentAlignment = ContentAlignment;
  HeroHeight = HeroHeight;

  // A mapping of options from hippo to TrueFalse
  private readonly hippoTrueFalseMap = {
    True: TrueFalse.True,
    False: TrueFalse.False,
  };

  // A mapping of options from hippo to ContentAlignment
  private readonly hippoContentAlignmentMap = {
    Left: ContentAlignment.Left,
    Center: ContentAlignment.Center,
    Right: ContentAlignment.Right,
  };

  // A mapping of options from hippo to HeroHeight
  private readonly hippoHeroHeighteMap = {
    Small: HeroHeight.Small,
    Large: HeroHeight.Large,
  };

  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  // Populate the document variable, will return the model data from the Bloomreach API
  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  // Populate the data variable, will return the data from document
  get data() {
    let data = this.document?.getData();
    return data;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (this.isBrowser) {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 640) {
        this.globalHeroImageDisplay = this.globalHeroImageSmall;
      } else {
        this.globalHeroImageDisplay = this.globalHeroImageLarge;
      }
    }
  }

  ngOnInit() {
    // super.ngOnInit();
    if (this.component) {
      // Get the params from the component
      const params = this.component.getParameters();
      this.globalHeroIsPageTitle = this.hippoTrueFalseMap[params.isPageTitle];
      this.globalHeroContentWidth = params.contentWidth;
      this.globalHeroBanner = this.hippoTrueFalseMap[params.heroBanner];
      this.globalHeroBannerColor = params.heroBannerColor;
      this.globalHeroBannerTextColor = params.heroBannerTextColor;
      this.globalHeroOrientation =
        this.hippoContentAlignmentMap[params.contentAlignment];
      this.globalHeroType = this.hippoHeroHeighteMap[params.heroHeight];

      let ieVarB = this.globalHeroContentWidth;
      ieVarB = ieVarB.substring(0, ieVarB.indexOf("%"));
      this.ieVar = ieVarB;
    }

    if (this.data) {
      // Get component data
      this.globalHeroHeading = this.data.primaryText;
      if (this.data.largeImage) {
        this.globalHeroImageLarge = this.page
          .getContent<ImageSet>(this.data.largeImage)
          .getOriginal()
          .getUrl();
      }
      if (this.data.smallImage) {
        this.globalHeroImageSmall = this.page
          .getContent<ImageSet>(this.data.smallImage)
          .getOriginal()
          .getUrl();
      }

      if (this.isBrowser) {
        this.innerWidth = window.innerWidth; // set inner width on init

        if (this.innerWidth < 640) {
          // check for which image to use for viewport size
          this.globalHeroImageDisplay = this.globalHeroImageSmall;
        } else {
          this.globalHeroImageDisplay = this.globalHeroImageLarge;
        }
      }

      this.globalHeroHeader = this.data.header;
      this.globalHeroImageAlt = this.data.primaryText;
      this.globalHeroContent = this.data.secondaryText.value;
      if (this.data.link) {
        this.globalHeroCTAIsExternal = this.data.link.isExternal;
        this.globalHeroCTA = this.data.link.text;
        this.newTab = this.data.link.newTab;
        this.globalHeroCTAUrl = this.data.link.url;
        if (this.data.link.primaryDocument) {
          this.globalHeroCTAUrl = this.page
            .getContent<Document>(this.data.link.primaryDocument)
            .getUrl()!;
        }
        if (this.globalHeroCTAUrl.includes("assets")) {
          this.assetLink = true;
        } else {
          this.assetLink = false;
        }
      }
      if (this.globalHeroBanner == "true") {
        if (typeof this.data.bannerImage !== "undefined") {
          this.globalHeroBannerImage = this.page
            .getContent<ImageSet>(this.data.bannerImage)
            .getOriginal()
            .getUrl();
        }
        if (typeof this.data.rteText !== "undefined") {
          this.globalHeroBannerText = this.data.rteText;
        }
        if (typeof this.data.pdfLink !== "undefined") {
          this.globalHeroBannerLinkIsExternal = this.data.pdfLink.isExternal;
          this.globalHeroBannerLinkText = this.data.pdfLink.text;
          this.globalHeroBannerLinkNewTab = this.data.pdfLink.newTab;
          if (this.data.link.primaryDocument) {
            this.globalHeroBannerLink = this.page
              .getContent<Document>(this.data.link.primaryDocument)
              .getUrl()!;
          }
          if (this.globalHeroBannerLink.includes("assets")) {
            this.globalHeroBannerLinkAssetLink = true;
          } else {
            this.globalHeroBannerLinkAssetLink = false;
          }
        }
      }
    }
  }
}
