import { Component, OnInit, Input } from '@angular/core';
// import { BaseComponent, ImageUrlService, PageModelService } from '../../upgrade/bloomreach';
// import { SingleContentComponent } from 'src/app/upgrade/SingleContentComponent';

// Upgrade
import { Component as BrComponent, Document, ImageSet, Page } from '@bloomreach/spa-sdk';

@Component({
  selector: 'app-bulleted-content-section',
  templateUrl: './bulleted-content-section.component.html',
  styleUrls: ['./bulleted-content-section.component.scss']
})
export class BulletedContentSectionComponent implements OnInit {
  // @Input() configuration: any;
  @Input() component!: BrComponent;
  @Input() page!: Page;

  imageHeroCTA: string;
  bulletHeading: string;
  bulletPoints: string;

  // constructor(imageUrlService: ImageUrlService, pageModelService: PageModelService) {
  //   super(imageUrlService, pageModelService );
  // }

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get data() {
    let data = this.document?.getData();
    this.imageHeroCTA = data.link?.text;
    return data;
  }

  ngOnInit() {
    // super.ngOnInit();
    if(this.data) {
      this.bulletHeading = this.data.contentHeadline;
      this.bulletPoints = this.data.detailedContent.value;
    }
  }
}
