<div class="header-scrollfix" #scrollFixElement></div>

<header class="global-header" [ngClass]="navStatus ? '' : 'stuck'" #headerElement>
  <div class="manage-content-container" *ngIf="page.isPreview()">
    <ng-container [brManageMenuButton]="menu"></ng-container>
  </div>
  <div class="top-hat">
    <div class="grid-container">
      <ul>
        <li *ngFor="let topHatItem of topHatLinks">
          <a *ngIf="!topHatItem.isExternal; else isExternal" routerLink="{{topHatItem.ur | parseUrl: 'pathname'}}">{{topHatItem.text}}</a>
          <ng-template #isExternal>
            <a href="{{topHatItem.url}}">{{topHatItem.text}}</a>
          </ng-template>
        </li>
        <li>
          <app-global-search></app-global-search> 
        </li>
      </ul>
    </div>
  </div>
  <div class="global-header__nav grid-container">
    <div class="global-header__nav--mobile-nav" (click)="toggleNav();">
      <div class="icon-menu-icon"></div>
      <!-- <img src="../assets/images/icon-menu-nav.png">-->
    </div>
    <div class="global-header__nav--logo">
      <a href="{{logoUrl}}">
        <img src="../assets/images/gms-logo.png" alt="{{logoAltText}}">
      </a>
    </div>
    <div class="global-header__nav--container">
      <ul>
        <li *ngFor="let navItems of navLinks">
            <a *ngIf="!navItems.isExternal && navItems.url; else isExternal" routerLink="{{navItems.url | parseUrl: 'pathname'}}">{{navItems.text}}</a>
            <ng-template #isExternal>
              <a *ngIf="navItems.url; else noLink" href="{{navItems.url}}">{{navItems.text}}</a>
            </ng-template>
            <ng-template #noLink>
                <a (click)="toggleClass($event)">{{navItems.text}}</a>
              </ng-template>
            <ul *ngIf="navItems.megaMenu">
                <li *ngFor="let subNav of navItems.megaMenu.links" >
                  <a *ngIf="!subNav.isExternal; else isExternalSubNav" routerLink="{{subNav.url | parseUrl: 'pathname'}}">{{subNav.text}}</a>
                  <ng-template #isExternalSubNav>
                    <a href="{{subNav.url}}" target="_blank">{{subNav.text}}</a>
                  </ng-template>
                </li>
            </ul>
        </li>
        <li>
      </ul>
    </div>
    <div class="global-header__nav--button-container">
      <a class="button primary desktop" [routerLink]="buttonLink">{{buttonText}}</a>
      <a class="mobile" [routerLink]="buttonLink"></a>
    </div>
  </div>
  <div class="global-header__nav--mobile-container" *ngIf="!navStatus" data-drilldown-container>
    <ul>
        <ng-template #isDropdown>
        </ng-template>

        <li *ngFor="let navItems of navLinks"
            [ngClass]="navItems.megaMenu && navItems.megaMenu.links && navItems.megaMenu.links.length ? 'is-drilldown' : ''"
            data-drilldown-parent>
            <a *ngIf="!navItems.isExternal && navItems.url; else isExternal"
                routerLink="{{navItems.url | parseUrl: 'pathname'}}"
                (click)="toggleNav();">
              {{navItems.text}}
            </a>
            <div *ngIf="navItems.megaMenu && navItems.megaMenu.links && navItems.megaMenu.links.length"
                  class="icon-button" (click)="drill($event)">
            </div>
            <ng-template #isExternal>
              <a *ngIf="navItems.url; else noLink" href="{{navItems.url}}" (click)="toggleNav();">
                {{navItems.text}}
              </a>
            </ng-template>
            <ng-template #noLink>
              <a (click)="drill($event)">
                {{navItems.text}}
              </a>
            </ng-template>
            <ul *ngIf="navItems.megaMenu" class="drilldown">
                <li class="back-button">
                  <a (click)="drillBack($event)"><div class="icon-dropdown-arrow-icon"></div> Back</a>
                </li>
                <li *ngFor="let subNav of navItems.megaMenu.links">
                  <a *ngIf="!subNav.isExternal; else isExternal;" routerLink="{{subNav.url | parseUrl: 'pathname'}}" (click)="toggleNav();">{{subNav.text}}</a>
                  <ng-template #isExternal>
                    <a href="{{subNav.url}}" (click)="toggleNav();" target="_blank">{{subNav.text}}</a>
                  </ng-template>
                </li>
            </ul>
        </li>
        <li *ngFor="let topHatItem of topHatLinks">
          <a *ngIf="!topHatItem.isExternal; else isExternal" routerLink="{{topHatItem.url | parseUrl: 'pathname'}}" (click)="toggleNav();">{{topHatItem.text}}</a>
          <ng-template #isExternal>
            <a href="{{topHatItem.url}}" (click)="toggleNav();">{{topHatItem.text}}</a>
          </ng-template>
        </li>
        <li>
          <a [routerLink]="buttonLink" (click)="toggleNav();">{{buttonText}}</a> 
        </li>
        <li>
          <app-global-search (messageEvent)="receiveStatus($event)"></app-global-search> 
        </li>
      </ul>
    </div>
</header>
