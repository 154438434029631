<br-page [configuration]="configuration" [mapping]="mapping" (state)="setPageModel($event)">
  <ng-template let-page="page">
    <header>
      <nav class="navbar navbar-expand-sm navbar-dark sticky-top bg-dark" role="navigation">
        <div class="container">
          <div class="collapse navbar-collapse">
            <app-global-header *brComponent="'menu'; let component" [component]="component" [page]="page">
            </app-global-header>
          </div>
        </div>
      </nav>
    </header>
    <div class="grid-container search-results-page elastic-search">
      <div class="grid-x grid-margin-x search-results-page__content-container">
        <div class="cell">
            <bre-render-cms-component *ngIf="page.isPreview()" [path]="'search-config'"></bre-render-cms-component>
        </div>
        <div class="cell">
            <app-search-results-list *brComponent="'search-config'; let component" [component]="component" [page]="page"></app-search-results-list>
        </div>
      </div>
    </div>

    <footer class="bg-dark text-light py-3">
      <div class="container clearfix">
        <div class="overflow-hidden">
          <app-global-footer *brComponent="'footer'; let component" [component]="component" [page]="page">
          </app-global-footer>
        </div>
      </div>
    </footer>
    <cookie-info *brComponent="'cookie-info'; let component" [component]="component" [page]="page">
    </cookie-info>
  </ng-template>
</br-page>
<app-back-to-top></app-back-to-top>
