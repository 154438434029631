<div class="manage-content-container" *ngIf="page.isPreview()">
    <ng-container [brManageContentButton]="document"></ng-container>
</div>
<p *ngIf="!data">The Page Heading Component will render after a content document is selected in the authoring modal.
</p>
<div *ngIf="data" class="page-heading-block" [class.benefits-heading]="benefitsPageFlag === true">
    <div class="page-heading-block__wrapper">
        <div class="grid-container grid-x {{pageHeadingImageAlignment}}">
            <div class="image cell large-6 small-12 image">
                <img src='{{pageHeadingImage}}'>
            </div>
            <div class="cell large-6 small-12 content">
                <h1 [innerHTML]='pageHeadingHeading' class="ani-active"></h1>
                <p [innerHTML]="pageHeadingContent"></p>
                <div class="page-heading__links">
                    <div *ngFor="let link of pageHeadingLinks" class="page-heading__link">
                        <a *ngIf="link.isExternal && !link.newTab; else openNewTab;"
                            href="{{link.url | protocol}}" class="button secondary">{{link.text}}</a>
                        <ng-template #openNewTab>
                            <a *ngIf="link.isExternal && link.newTab; else internalNew;"
                                href="{{link.url | protocol}}" target="_blank"
                                class="button secondary">{{link.text}}</a>
                        </ng-template>
                        <ng-template #internalNew>
                            <a *ngIf="!link.isExternal && !link.assetLink && link.newTab; else internal;"
                                routerLink="{{link.url | parseUrl: 'pathname'}}" target="_blank"
                                class="button secondary">{{link.text}}</a>
                        </ng-template>
                        <ng-template #internal>
                            <a *ngIf="!link.isExternal && !link.assetLink && !link.newTab; else assetNew;"
                                routerLink="{{link.url | parseUrl: 'pathname'}}" class="button secondary">{{link.text}}</a>
                        </ng-template>
                        <ng-template #assetNew>
                            <a *ngIf="!link.isExternal && link.assetLink && link.newTab; else asset;"
                                href="{{link.url}}" target="_blank"
                                class="button secondary">{{link.text}}</a>
                        </ng-template>
                        <ng-template #asset>
                            <a href="{{link.url}}" class="button secondary">{{link.text}}</a>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
