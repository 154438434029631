import { Directive, Input, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: 'img[appImagePreload]'
})
export class ImagePreloadDirective {
  @Input() default: string;

  constructor(private el: ElementRef) { }

  // If there is an error loading an image, change the url to the supplied default string.
  @HostListener('error')
  updateUrl() {
    this.el.nativeElement.src = this.default;
  }
}
