<div class="manage-content-container" *ngIf="page.isPreview()">
  <ng-container [brManageContentButton]="document"></ng-container>
</div>
<p *ngIf="!data">The Featured Content Component will render after a content document is selected in the authoring modal.</p>
<div *ngIf="data" class="featured-content-section" [ngClass]="{'tag': featuredImageTitle}">
  <div class="featured-content-section__wrapper">
      <div class="grid-container grid-x">
        <div class="image cell large-4 small-12 ">
          <img src="{{featuredImage}}">
          <div *ngIf="featuredImageTitle" class="image-title">{{featuredImageTitle}}</div>
        </div>
        <div class="content cell large-8 small-12">
            <h2>{{featuredHeading}}</h2>
            <div *ngIf="featuredContentCol2; else singleSpan">
              <span [innerHTML]="featuredContentCol1"></span>
              <span [innerHTML]="featuredContentCol2"></span>
            </div>
            <ng-template #singleSpan>
              <div>
                <span  class="single" [innerHTML]="featuredContentCol1"></span>
              </div>
            </ng-template>
        </div>
    </div>
  </div>
</div>
