import { Component, OnInit, Input } from '@angular/core';
// import { BaseComponent, getNestedObject } from '../../upgrade/bloomreach';

// Upgrade
import { Component as BrComponent,Reference, Document, isMenu,  isLink, ImageSet, Page , TYPE_LINK_EXTERNAL, } from "@bloomreach/spa-sdk";


// Enums used to find the content for different sections in the hippo nav data.
enum cmsNavTitles {
  topFooterLinks = "Top Footer Links",
  bottomFooterLinks = "Bottom Footer Links",
  socialLinks = "Social Links",
  findAYardCTA = "Find a Yard CTA"
}

interface FooterLink {
  text: string;
  url: string;
  isExternal: boolean;
  megaMenu?: {
    links: {
      text: string;
      url: string;
      isExternal: boolean;
    }[];
  };
}

interface NavItem {
  text: string;
  url: string;
  isExternal: boolean;
  megaMenu?: {
    links: {
      text: string;
      url: string;
      isExternal: boolean;
    }[];
  };
}

interface MenuModels {
  main: Reference;
}

@Component({
  selector: "app-global-footer",
  templateUrl: "./global-footer.component.html",
  styleUrls: ["./global-footer.component.scss"]
})
export class GlobalFooterComponent implements OnInit {
  @Input() configuration: any;
  @Input() component!: BrComponent;
  @Input() page!: Page;

  imageHeroCTA: string;
  menuConfiguration: any;
  footerLinks: {
    heading: string,
    linkItems: FooterLink[];
  }[];

  bottomFooterLinks: FooterLink[];

  socialLinks: {
    text: string, // should be facebook, twitter, or linkedin
    url: string,
    isExternal: boolean
  }[];

  footerCTALink: string;
  footerCTAText: string;

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get data() {
    let data = this.document?.getData();
    this.imageHeroCTA = data.link?.text;
    return data;
  }

  get menu() {
    const menuRef = this.component.getModels<any>()?.footer;
    const menu = menuRef && this.page.getContent(menuRef);

    return isMenu(menu) ? menu : undefined;
  }

  ngOnInit() {
    this.getMenuConfiguration();
  }

  getMenuConfiguration(): void {
    const cmsMenuItems = this.menu.getItems();

    /**
     * Set all the content for the main navigation by reformatting the content from the cms
     * into the format the components are expecting
     */
    if (cmsMenuItems && cmsMenuItems.length) {
      cmsMenuItems.forEach((cmsMenuSection) => {
        switch (cmsMenuSection.getName()) {
          case cmsNavTitles.topFooterLinks:
            this.footerLinks = cmsMenuSection.getChildren().map((curColumn: any) => {
              return {
                heading: curColumn.getName(),
                linkItems: curColumn.getChildren().map((cmsMenuItem) => {
                  const newMenuItem: {
                  text: string;
                  url: string;
                  isExternal: boolean;
                  } = {
                    text: cmsMenuItem.getName(),
                    url: cmsMenuItem.getLink()
                      ? this._stripSiteFromLink(cmsMenuItem.getUrl())
                      : "",
                    isExternal:
                      isLink(cmsMenuItem.getLink()) &&
                      cmsMenuItem.getLink().type === TYPE_LINK_EXTERNAL
                        ? true
                        : false,
                  };

                  return newMenuItem;
                  })
              };
            });
            break;
          case cmsNavTitles.bottomFooterLinks:
            this.bottomFooterLinks = cmsMenuSection
            // This is the way to return all the children and iterate through each one to get the data for the Top hat links
              .getChildren()
              .map((cmsMenuItem) => {
                const newMenuItem: {
                  text: string;
                  url: string;
                  isExternal: boolean;
                } = {
                  text: cmsMenuItem.getName(),
                  url: cmsMenuItem.getLink()
                    ? this._stripSiteFromLink(cmsMenuItem.getUrl())
                    : "",
                  isExternal:
                    isLink(cmsMenuItem.getLink()) &&
                    cmsMenuItem.getLink().type === TYPE_LINK_EXTERNAL
                      ? true
                      : false,
                };

                return newMenuItem;
              });
            // Go through all the footer links and update the year to the current one
            this.bottomFooterLinks = this.bottomFooterLinks.map((link) => {
              // Check if the link is the one with copyright
              if(link.text.includes('©')) {
                link.text = link.text.replace('2020', new Date().getFullYear().toString())
              }
              return link;
            })
            break;
          case cmsNavTitles.socialLinks:
            this.socialLinks = cmsMenuSection
            // This is the way to return all the children and iterate through each one to get the data for the Top hat links
              .getChildren()
              .map((cmsMenuItem) => {
                const newMenuItem: {
                  text: string;
                  url: string;
                  isExternal: boolean;
                } = {
                  text: cmsMenuItem.getName(),
                  url: cmsMenuItem.getLink()
                    ? this._stripSiteFromLink(cmsMenuItem.getUrl())
                    : "",
                  isExternal:
                    isLink(cmsMenuItem.getLink()) &&
                    cmsMenuItem.getLink().type === TYPE_LINK_EXTERNAL
                      ? true
                      : false,
                };

                return newMenuItem;
              });
            break;
          case cmsNavTitles.findAYardCTA:
            const footerCTALinkItem = cmsMenuSection.getChildren()[0];
            this.footerCTALink = this._stripSiteFromLink(
              footerCTALinkItem.getUrl()
            );
            this.footerCTAText = footerCTALinkItem.getName();
            break;
        }
      });
    }
  }

  /**
   * If "site" is present in the url, strip from url and return that string.
   * @param navItem the string to be reformatted
   */
  private _stripSiteFromLink(navItem: string): string {
    if (navItem) {
      if (!navItem.includes('site/_cmsinternal') && navItem.includes('site/')) {
        navItem = navItem.replace('site/', '');
      }
    }

    return navItem;
  }

}
