/*
 * Copyright 2020 Bloomreach
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Pipe, PipeTransform } from '@angular/core';

declare global {
  interface URLSearchParams {
    entries(): IterableIterator<[string, string]>;
  }
}

@Pipe({ name: 'parseUrl' })
export class ParseUrlPipe implements PipeTransform {
  transform(url: string, property: keyof URL): string | (() => string) | Record<string, string> {
    const value = new URL(url, 'http://gms.com')[property];

    if (value instanceof URLSearchParams) {
      return Object.fromEntries([...value.entries()]);
    }

    return value;
  }
}
