import { Component, OnInit, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy, Input } from '@angular/core';
import { LocationsService } from '../../services/locations.service';
import { Observable, Subscription } from 'rxjs';
import { Location } from '../../global-classes/location';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Breakpoints } from '../../enums/breakpoints.enum';
import { BreakpointObserver } from '@angular/cdk/layout';
import {
  Component as BrComponent,
  Document,
  Page,
} from "@bloomreach/spa-sdk";
@Component({
  selector: 'app-find-a-yard-results',
  templateUrl: './find-a-yard-results.component.html',
  styleUrls: ['./find-a-yard-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FindAYardResultsComponent implements OnInit, OnDestroy {
  @Output() mapActive: EventEmitter<boolean> = new EventEmitter();
  @Input() component!: BrComponent;
  @Input() page!: Page;
  config: any;
  isMapActive = true;
  status: string;
  prevStatus: string;

  resultsList$: Observable<Location[]>;
  activeLocation$: Observable<Location>;
  searchLocation$: Observable<string>;

  isLargeScreen: boolean;
  layoutChangesLargeSub: Subscription;

  mapLabel: string;
  getDirectionsLinkText: string;
  visitWebsiteLinkText: string;
  hoursLabel: string;
  productServicesLabel: string;
  searchResultsTemplate: string;

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _locationsService: LocationsService
  ) {}

  ngOnInit() {
    let { document } = this.page.getComponent('config', 'container', 'configuration-values').getModels();

    let docContent = this.page.getContent<Document>(document);

    this.config = docContent?.getData();

    this._locationsService.currentStatus.subscribe(status => this.status = status);
    this._locationsService.previousStatus.subscribe(prevStatus => this.prevStatus = prevStatus);
    this.mapLabel = this.config.mapLabel;
    this.getDirectionsLinkText = this.config.getDirectionsLinkText;
    this.visitWebsiteLinkText = this.config.visitWebsiteLinkText;
    this.hoursLabel = this.config.hoursLabel;
    this.productServicesLabel = this.config.productServicesLabel;
    this.searchResultsTemplate = this.config.searchResultsTemplate;

    this.activeLocation$ = this._locationsService.activeLocation$;
    this.resultsList$ = this._locationsService.locations$;
    this.searchLocation$ = this._locationsService.searchLocation$;

    const layoutChangesLarge$ = this._breakpointObserver.observe([
      Breakpoints.large
    ]);

    this.layoutChangesLargeSub = layoutChangesLarge$.subscribe(result => {
      if (result.matches) {
        this.isLargeScreen = true;
      } else {
        this.isLargeScreen = false;
      }
    });
  }

  ngOnDestroy() {
    this.layoutChangesLargeSub.unsubscribe();
  }

  setActiveLocation(activeLocation: Location) {
    this._locationsService.setActiveLocation(activeLocation);
  }

  scrolltoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  clearActiveLocation() {
    this._locationsService.clearActiveLocation();
  }

  mapToggle(changeEvent: MatSlideToggleChange) {
    this.mapActive.emit(changeEvent.checked);
    this.isMapActive = changeEvent.checked;
  }

  generateSearchText(resultsNum: number, searchLocation: string): string {
    return this.searchResultsTemplate.replace('${resultsNum}', resultsNum.toString()).replace('${searchLocation}', searchLocation);
  }

}
