import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isPlatformBrowser } from "@angular/common";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ServiceUrls } from '../enums/service-urls.enum';
import { SearchResults } from '../global-classes/search-result';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'AIzaSyBBgGRVr0VizCa1xZCSg7gLrZgoHPI2lc'
  })
};

@Injectable()
export class GoogleLocationService {
  // tslint:disable-next-line:max-line-length
  readonly endpoint = 'https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyBOsgPdGumotLE6gPO-AEZVk5zQuVoqbR0';
  private isBrowser: boolean;

  constructor(private http: HttpClient, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  // Publich method to get the users current location
  public getLocation(): Observable<any> {
    // Get the location only if geolocation is available and the user is using the desktop verison
    // This can be changed to be device specific instead of using breakpoints
    if (this.isBrowser && "geolocation" in navigator && window.innerWidth >= 1024) {
      /* geolocation is available */
      return new Observable(obs => {
        // Get the current position from the Geolocation API
        navigator.geolocation.getCurrentPosition(success => {
          // If everything is ok return the location
          obs.next({
            location: {
              lat: success.coords.latitude,
              lng: success.coords.longitude
            }
          });
          // Complete the observable
          obs.complete();
        },
        // If the user denies geolocation or is not available
        error => {
          // Get the location from Google
          let locationFromGoogle = this.http.post(this.endpoint, location, httpOptions).subscribe((data: SearchResults) => {
            return data;
          })
          // Display the error
          // Removing this because the error blocks execution of JS
          // obs.error(error);
          // Return the Google location
          return locationFromGoogle;
        });
      })
    } else {
      /* geolocation IS NOT available */
      // For browsers that don't support geolocation, get the location from Google
      // The second parameter "location" was removed from the post request due to it being undefined
      return this.http.post(this.endpoint, httpOptions)
        .pipe(
          map((data: SearchResults) => {
            return data;
          })
        );
    }
  }
}
