import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
// tslint:disable-next-line:max-line-length
// import { BaseComponent, ImageUrlService, PageModelService, getNestedObject } from '../../upgrade/bloomreach';
// import { SingleContentComponent } from 'src/app/upgrade/SingleContentComponent';
import { ContentAlignment } from '../../enums/content-alignment.enum';

// Upgrade
import { Component as BrComponent, Document, ImageSet, Page } from '@bloomreach/spa-sdk';

@Component({
  selector: 'app-detailed-content-image',
  templateUrl: './detailed-content-image.component.html',
  styleUrls: ['./detailed-content-image.component.scss']
})
export class DetailedContentImageComponent implements OnInit {
  // @Input() configuration: any;
  @Input() component!: BrComponent;
  @Input() page!: Page;

  imageHeroCTA: string;
  detailedImage: string;
  detailedHeading: string;
  detailedContent: string;
  detailedOrientation: string;
  detailedListStatus: boolean;

  @ViewChild('innerItems') innerItems: ElementRef;

  // A mapping of options from hippo to ContentAlignment
 private readonly hippoContentAlignmentMap = {
    'Left': ContentAlignment.Left,
    'Center': ContentAlignment.Center,
    'Right': ContentAlignment.Right
  };

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get data() {
    let data = this.document?.getData();
    this.imageHeroCTA = data.link?.text;
    return data;
  }

  ngOnInit() {
    // super.ngOnInit();
    if (this.data) {
      if (this.data.image) {
        this.detailedImage =  this.page.getContent<ImageSet>(this.data.image).getOriginal().getUrl();
      }
      this.detailedHeading = this.data.contentHeadline;
      // tslint:disable-next-line:max-line-length
      this.detailedContent = this.data.detailedContent.value;

      if (this.component) {
        const params = this.component.getParameters();
        this.detailedOrientation = this.hippoContentAlignmentMap[params.alignment];
      }
    }

    setTimeout(() => {
      const $ = function (selector) {
        return document.querySelector(selector);
      };

      const links = this.innerItems.nativeElement.children;

        for ( let i = 0; i < links.length; i++) {
          const link = links[i];
          link.onclick = this.toggleClass;
        }
    });
  }

  toggleClass(e) {
    if (e.srcElement.classList.contains('show')) {
      e.srcElement.classList.remove('show');
    } else {
      e.srcElement.classList.add('show');
    }
  }
}
