/*
 * Copyright 2020-2021 Bloomreach
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Component,
  InjectionToken,
  Inject,
  OnInit,
  Optional,
} from "@angular/core";
import { NavigationEnd, Router, ActivatedRoute } from "@angular/router";
import { REQUEST, RESPONSE } from "@nguniversal/express-engine/tokens";
import cookie from "cookie";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { Request, Response } from "express";
import { BrPageComponent } from "@bloomreach/ng-sdk";
import { Page, TYPE_CONTAINER_ITEM_UNDEFINED } from "@bloomreach/spa-sdk";

import { FallbackComponent } from "../fallback-component/fallback.component";

import { BackToTopComponent } from "../global-components/back-to-top/back-to-top.component";
import { BreadcrumbComponent } from "../global-components/breadcrumb/breadcrumb.component";
import { BulletedContentSectionComponent } from "../global-components/bulleted-content-section/bulleted-content-section.component";
import { CookieInfoComponent } from "../global-components/cookie-info/cookie-info.component";
import { ConfigComponent } from "../find-a-yard-components/config/config.component";
import { ContentTeaserComponent } from "../global-components/content-teaser/content-teaser.component";
import { ContentColumnsComponent } from "../global-components/content-columns/content-columns.component";
import { ContentTilesComponent } from "../global-components/content-tiles/content-tiles.component";
import { ContentSectionComponentComponent } from "../global-components/content-section-component/content-section-component.component";
import { ContentSectionVideoComponent } from "../global-components/content-section-video/content-section-video.component";
import { ContentWellComponent } from "../global-components/content-well/content-well.component";
import { DetailedContentImageComponent } from "../global-components/detailed-content-image/detailed-content-image.component";
import { FeaturedContentComponent } from "../global-components/featured-content/featured-content.component";
import { GlobalHeaderComponent } from "../global-components/global-header/global-header.component";
import { GlobalHeroComponent } from "../global-components/global-hero/global-hero.component";
import { GlobalSearchComponent } from "../global-components/global-search/global-search.component";
import { GlobalFooterComponent } from "../global-components/global-footer/global-footer.component";
import { FlipbookPageComponent } from "../pages/flipbook-page/flipbook-page.component";
import { ImageCarouselComponent } from "../global-components/image-carousel/image-carousel.component";
import { ImageHeroComponent } from "../global-components/image-hero/image-hero.component";
import { ImagePullQuoteComponent } from "../global-components/image-pull-quote/image-pull-quote.component";
import { InternalSearchComponent } from "../global-components/internal-search/internal-search.component";
import { LogoGridComponent } from "../global-components/logo-grid/logo-grid.component";
import { LogoListComponent } from "../logo-page-components/logo-list-component/logo-list.component";
import { MainNavComponent } from "../global-components/main-nav/main-nav.component";
import { PageBreakComponent } from "../global-components/page-break/page-break.component";
import { PageHeadingComponent } from "../global-components/page-heading/page-heading.component";
import { PageNegativeSpacingComponent } from "../global-components/page-negative-spacing/page-negative-spacing.component";
import { PageSpacingComponent } from "../global-components/page-spacing/page-spacing.component";
import { ProductTilesComponent } from "../product-category-page-components/product-tiles/product-tiles.component";
import { SearchConfigComponent } from "../global-components/search-results-list/search-config/search-config.component";
import { SearchResultsListComponent } from "../global-components/search-results-list/search-results-list.component";
import { SeoComponent } from "../global-components/seo/seo.component";
import { ServicesTilesComponentsComponent } from "../services-page-components/services-tiles-components/services-tiles-components.component";
import { SubsidiaryHeadingComponent } from "../subsidiary-page-components/subsidiary-heading/subsidiary-heading.component";
import { SubsidiaryLocationsComponent } from "../subsidiary-page-components/subsidiary-locations/subsidiary-locations.component";
import { VendorListComponentComponent } from "../vendor-page-components/vendor-list-component/vendor-list-component.component";
import { VideoHeroComponent } from "../global-components/video-hero/video-hero.component";

import { environment } from "../../environments/environment";

export const ENDPOINT = new InjectionToken<string>("brXM API endpoint");

@Component({
  selector: "br-index",
  templateUrl: "./index.component.html",
})
export class IndexComponent implements OnInit {
  configuration: BrPageComponent["configuration"];

  mapping = {
    "Back To Top": BackToTopComponent,
    Breadcrumb: BreadcrumbComponent,
    "Bulleted Content Section": BulletedContentSectionComponent,
    "Cookie Info": CookieInfoComponent,
    Config: ConfigComponent,
    "Content Teaser": ContentTeaserComponent,
    "Content Columns": ContentColumnsComponent,
    "Content Tiles": ContentTilesComponent,
    "Content Section": ContentSectionComponentComponent,
    "Content with Video": ContentSectionVideoComponent,
    "Content Well": ContentWellComponent,
    "Detailed Content with Image": DetailedContentImageComponent,
    "Featured Content": FeaturedContentComponent,
    "Global Header": GlobalHeaderComponent,
    "Global Hero": GlobalHeroComponent,
    "Global Search": GlobalSearchComponent,
    "Global Footer": GlobalFooterComponent,
    "Hero Video": VideoHeroComponent,
    "Medium Video": VideoHeroComponent,
    "Flipbook Page": FlipbookPageComponent,
    "Image Carousel": ImageCarouselComponent,
    "Image Hero": ImageHeroComponent,
    "Image with Pull Quote": ImagePullQuoteComponent,
    "Internal Search": InternalSearchComponent,
    "Logo Grid": LogoGridComponent,
    "Logo List": LogoListComponent,
    Menu: GlobalHeaderComponent,
    "Main Nav": MainNavComponent,
    "Page Break": PageBreakComponent,
    "Page Heading": PageHeadingComponent,
    "Page Negative Spacing": PageNegativeSpacingComponent,
    "Page Spacing": PageSpacingComponent,
    "Product Tiles": ProductTilesComponent,
    "Search Config": SearchConfigComponent,
    "Search Results List": SearchResultsListComponent,
    "SEO Component": SeoComponent,
    "Services Tiles": ServicesTilesComponentsComponent,
    "Subsidiary Page Heading": SubsidiaryHeadingComponent,
    "Subsidiary Locations": SubsidiaryLocationsComponent,
    "Vendor List": VendorListComponentComponent,
    [TYPE_CONTAINER_ITEM_UNDEFINED]: FallbackComponent,
  };

  private navigationEnd: Observable<NavigationEnd>;

  constructor(
    router: Router,
    route: ActivatedRoute,
    @Inject(ENDPOINT) endpoint?: string,
    @Inject(REQUEST) @Optional() request?: Request,
    @Inject(RESPONSE) @Optional() response?: Response,
  ) {
    const PREVIEW_TOKEN_KEY = "token";
    const PREVIEW_SERVER_ID_KEY = "server-id";
    // Read a token and server id from the query params
    const queryToken = route.snapshot.queryParams[PREVIEW_TOKEN_KEY];
    const queryServerId = route.snapshot.queryParams[PREVIEW_SERVER_ID_KEY];

    // Save the values from the query string to have ability to restore them when switch back from legacy page to the SPA-SDK rendered page.
    if (response && request) {
      if (queryToken) response.cookie(PREVIEW_TOKEN_KEY, queryToken);
      if (queryServerId) response.cookie(PREVIEW_SERVER_ID_KEY, queryServerId);
    } else {
      if (queryToken)
        document.cookie = cookie.serialize(PREVIEW_TOKEN_KEY, queryToken);
      if (queryServerId)
        document.cookie = cookie.serialize(
          PREVIEW_SERVER_ID_KEY,
          queryServerId
        );
    }

    this.configuration = {
      endpoint,
      request,
      endpointQueryParameter: "endpoint",
      path: router.url,
      ...(environment.cms) && {origin: environment.cms},
    } as IndexComponent["configuration"];

    this.navigationEnd = router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;
  }

  ngOnInit(): void {
    this.navigationEnd.subscribe((event) => {
      this.configuration = { ...this.configuration, path: event.url };
    });
  }

  setVisitor(page?: Page): void {
    this.configuration.visitor = page?.getVisitor();
  }
}
