<div class="manage-content-container" *ngIf="page.isPreview()">
  <ng-container [brManageContentButton]="document"></ng-container>
</div>
<p *ngIf="!data">The Bulleted Content Section Component will render after a content document is selected in the authoring modal.</p>
<div *ngIf="data" class="bulleted-content-section">
    <div class="bulleted-content-section__wrapper">
        <div class="grid-container grid-x">
          <div class="cell large-5 small-12">
            <h2>{{bulletHeading}}</h2>
          </div>
          <div class="cell large-7 small-12">
            <div [innerHTML]=bulletPoints></div> 
          </div>
        </div>
    </div>
</div>
