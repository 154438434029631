import { Component, OnInit , Input } from "@angular/core";
import { ContentAlignment } from "../../enums/content-alignment.enum";

// Upgrade
import { Component as BrComponent, Document, ImageSet, Page } from '@bloomreach/spa-sdk';

@Component({
  selector: "app-page-heading",
  templateUrl: "./page-heading.component.html",
  styleUrls: ["./page-heading.component.scss"],
})
export class PageHeadingComponent implements OnInit {
  pageHeadingImage: string;
  pageHeadingTitle: string;
  pageHeadingHeading: string;
  pageHeadingContent: string;
  pageHeadingImageAlignment: string;
  // Primary link array
  pageHeadingLinks: [];

  // TODO remove this and find a permanent solution
  benefitsPageFlag: boolean = false;

  @Input() component!: BrComponent;
  @Input() page!: Page;

  // A mapping of options from hippo to ContentAlignment
  private readonly hippoContentAlignmentMap = {
    Left: ContentAlignment.Left,
    Center: ContentAlignment.Center,
    Right: ContentAlignment.Right,
  };

  // Populate the document variable, will return the model data from the Bloomreach API
  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get configuration() {
    const { cparam } = this.component.getModels();
    return cparam;
  }

  // Populate the data variable, will return the data from document
  get data() {
    let data = this.document?.getData();
    return data;
  }

  ngOnInit() {
    if (this.data) {
      if (this.configuration) {
        this.pageHeadingImageAlignment = this.hippoContentAlignmentMap[
          this.configuration.alignment
        ]; // bloomreach sends 'right' or 'left' for image alignment in component
      }

      if (this.data.image) {
        this.pageHeadingImage = this.page.getContent<ImageSet>(this.data.image).getOriginal().getUrl();
      }
      this.pageHeadingHeading = this.data.pageTitle.value;
      this.pageHeadingContent = this.data.content.value;

      this.pageHeadingLinks = this.data.link.map((link) => {
        let linkText = link.text;
        let linkDocument = this.page.getContent<any>(link.primaryDocument);
        let linkURL = link.url;
        if(link.primaryDocument) {
          if(typeof linkDocument.type !== 'undefined' && linkDocument.type === 'asset') {
            linkURL  = linkDocument.data.asset.links.site.href;
          } else {
            linkURL  = linkDocument.getUrl();
          }
        }

        let linkIsExternal = link.isExternal;
        let newTab = link.newTab;
        let assetLink;
        if (linkURL.includes("assets")) {
          assetLink = true;
        } else {
          assetLink = false;
        }

        return {
          text: linkText,
          url: linkURL,
          isExternal: linkIsExternal,
          newTab,
          assetLink
        }
      })
    }
  }
}
