import { Component, OnInit, Inject, PLATFORM_ID, Input } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from "@angular/common";
import { BasePageComponent } from '../base-page/base-page.component';
import { Title } from '@angular/platform-browser';
import { SeoService } from '../../services/seo.service';

// Upgrade
import { ENDPOINT } from '../../index/index.component';
import { Component as BrComponent, Document, Page } from '@bloomreach/spa-sdk';

@Component({
  selector: 'app-search-results-page',
  templateUrl: './search-results-page.component.html',
  styleUrls: ['./search-results-page.component.scss']
})
export class SearchResultsPageComponent extends BasePageComponent implements OnInit {
  // @Input() configuration: any;
  @Input() component!: BrComponent;
  @Input() page!: Page;

  isBrowser: boolean;
  configContent: any;

  constructor(
    route: ActivatedRoute,
    titleService: Title,
    seoService: SeoService,
    router: Router,
    @Inject(PLATFORM_ID) platformId: string,
    @Inject(ENDPOINT) endpoint: string,
  ) {
    super(titleService, route, router, seoService, endpoint);
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
