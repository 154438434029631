<div class="manage-content-container" *ngIf="page.isPreview()">
  <ng-container [brManageContentButton]="document"></ng-container>
</div>
<p *ngIf="!data">The Content Section Component will render after a content document is selected in the authoring modal.</p>
<div *ngIf="data" class="content-section-component">
    <div class="content-section-component__wrapper">
        <div class="grid-container grid-x">
          <div class="cell large-5 small-12">
            <h2 *ngIf="!contentHeadingImage">{{contentHeading}}</h2>
            <img class="heading-image" *ngIf="contentHeadingImage" src="{{contentHeadingImage}}" [ngClass]="{'ani-active': isVisible}" #imgStatic>
          </div>
          <div class="cell large-7 small-12">
            <h3 *ngIf="contentHeadingImage">{{contentHeading}}</h3>
            <div [innerHTML]=contentContent></div>
            <div *ngIf="contentCTA && contentCTALink">
              <a *ngIf="contentIsExternal && !newTab; else openNewTab;" href="{{contentCTALink | protocol}}" class="button secondary">{{contentCTA}}</a>
              <ng-template #openNewTab>
                    <a *ngIf="contentIsExternal && newTab; else internalNew;" href="{{contentCTALink | protocol}}" target="_blank" class="button secondary">{{contentCTA}}</a>
                </ng-template>
                <ng-template #internalNew>
                  <a *ngIf="!contentIsExternal && !assetLink && newTab; else internal;" routerLink="{{contentCTALink | parseUrl: 'pathname'}}" target="_blank" class="button secondary">{{contentCTA}}</a>
                </ng-template>
                <ng-template #internal>
                    <a *ngIf="!contentIsExternal && !assetLink && !newTab; else assetNew;" routerLink="{{contentCTALink | parseUrl: 'pathname'}}" class="button secondary">{{contentCTA}}</a>
                </ng-template>
                <ng-template #assetNew>
                  <a *ngIf="!contentIsExternal && assetLink && newTab; else asset;" href="{{contentCTALink }}" target="_blank" class="button secondary">{{contentCTA}}</a>
                </ng-template>
                <ng-template #asset>
                    <a href="{{contentCTALink }}" class="button secondary">{{contentCTA}}</a>
                </ng-template>
            </div>
          </div>
        </div>
    </div>
</div>
