// import { _MatTabHeaderMixinBase } from '@angular/material/tabs/typings/tab-header';

/**
 * Class that should for any location objecs returned from the server
 */
export class Location {
  company: string;
  details: string;
  displayInfo: string;
  city: string;
  state: string;
  addressLine1: string;
  postalCode: number;
  lat: number;
  long: number;
  telNumber: string;
  faxNumber: string;
  extWebUrl: string;
  monHours: string;
  tuesHours: string;
  wedHours: string;
  thursHours: string;
  friHours: string;
  satHours: string;
  sunHours: string;
  productServices: string[];
  newTab: boolean;
  logoPath: string;
  pagePath: string;
  distance?: number;
  index?: number;
  image?: string;


  private readonly _timeOffset = 12;

  constructor(
    company: string,
    details: string,
    displayInfo: string,
    city: string,
    state: string,
    addressLine1: string,
    postalCode: number,
    lat: number,
    long: number,
    telNumber: string,
    faxNumber: string,
    extWebUrl: string,
    monHours: string,
    tuesHours: string,
    wedHours: string,
    thursHours: string,
    friHours: string,
    satHours: string,
    sunHours: string,
    productServices: string[],
    newTab: boolean,
    logoPath: string,
    pagePath: string,
    image?: string


  ) {
    this.company = company;
    this.details = details;
    this.displayInfo = displayInfo;
    this.city = city;
    this.state = state;
    this.addressLine1 = addressLine1;
    this.postalCode = postalCode;
    this.lat = lat;
    this.long = long;
    this.telNumber = telNumber;
    this.faxNumber = faxNumber;
    this.extWebUrl = extWebUrl;
    this.monHours = monHours;
    this.tuesHours = tuesHours;
    this.wedHours = wedHours;
    this.thursHours = thursHours;
    this.friHours = friHours;
    this.satHours = satHours;
    this.sunHours = sunHours;
    this.productServices = productServices;
    this.newTab = newTab;
    this.logoPath = logoPath;
    this.pagePath = pagePath;
    this.image = image;
  }

  /**
   * Get the correct opening time based on the current day of the week.
   */
  getOpenTime(): Date {
    const curDate = new Date();
    const day = curDate.getDay();
    let timeParts: string[];

    switch (day) {
      case 0:
        timeParts = this.sunHours.split('-');
        if (timeParts && timeParts.length > 1) {
          const openingTime = timeParts[0].trim();
          const openingTimeParts = openingTime.split(':');
          curDate.setHours(parseInt(openingTimeParts[0], 10) + this._timeOffset, parseInt(openingTimeParts[1], 10));

          return curDate;
        }
        break;
      case 1:
        timeParts = this.monHours.split('-');
        if (timeParts && timeParts.length > 1) {
          const openingTime = timeParts[0].trim();
          const openingTimeParts = openingTime.split(':');
          curDate.setHours(parseInt(openingTimeParts[0], 10) + this._timeOffset, parseInt(openingTimeParts[1], 10));

          return curDate;
        }
        break;
      case 2:
        timeParts = this.tuesHours.split('-');
        if (timeParts && timeParts.length > 1) {
          const openingTime = timeParts[0].trim();
          const openingTimeParts = openingTime.split(':');
          curDate.setHours(parseInt(openingTimeParts[0], 10) + this._timeOffset, parseInt(openingTimeParts[1], 10));

          return curDate;
        }
        break;
      case 3:
        timeParts = this.wedHours.split('-');
        if (timeParts && timeParts.length > 1) {
          const openingTime = timeParts[0].trim();
          const openingTimeParts = openingTime.split(':');
          curDate.setHours(parseInt(openingTimeParts[0].trim(), 10) + this._timeOffset, parseInt(openingTimeParts[0].trim(), 10));

          return curDate;
        }
        break;
      case 4:
        timeParts = this.thursHours.split('-');
        if (timeParts && timeParts.length > 1) {
          const openingTime = timeParts[0].trim();
          const openingTimeParts = openingTime.split(':');
          curDate.setHours(parseInt(openingTimeParts[0], 10) + this._timeOffset, parseInt(openingTimeParts[1], 10));

          return curDate;
        }
        break;
      case 5:
        timeParts = this.friHours.split('-');
        if (timeParts && timeParts.length > 1) {
          const openingTime = timeParts[0].trim();
          const openingTimeParts = openingTime.split(':');
          curDate.setHours(parseInt(openingTimeParts[0], 10) + this._timeOffset, parseInt(openingTimeParts[1], 10));

          return curDate;
        }
        break;
      case 6:
        timeParts = this.satHours.split('-');
        if (timeParts && timeParts.length > 1) {
          const openingTime = timeParts[0].trim();
          const openingTimeParts = openingTime.split(':');
          curDate.setHours(parseInt(openingTimeParts[0], 10) + this._timeOffset, parseInt(openingTimeParts[1], 10));

          return curDate;
        }
        break;
      default:
        return null;
    }
  }

  /**
   * Get the correct closing time based on the current day of the week.
   */
  getCloseTime(): Date {
    const curDate = new Date();
    const day = curDate.getDay();
    let timeParts: string[];

    switch (day) {
      case 0:
        timeParts = this.sunHours.split('-');
        if (timeParts && timeParts.length > 1) {
          const closingTime = timeParts[1].trim();
          const closingTimeParts = closingTime.split(':');
          curDate.setHours(parseInt(closingTimeParts[0], 10) + this._timeOffset, parseInt(closingTimeParts[1], 10));

          return curDate;
        }
        break;
      case 1:
        timeParts = this.monHours.split('-');
        if (timeParts && timeParts.length > 1) {
          const closingTime = timeParts[1].trim();
          const closingTimeParts = closingTime.split(':');
          curDate.setHours(parseInt(closingTimeParts[0], 10) + this._timeOffset, parseInt(closingTimeParts[1], 10));

          return curDate;
        }
        break;
      case 2:
        timeParts = this.tuesHours.split('-');
        if (timeParts && timeParts.length > 1) {
          const closingTime = timeParts[1].trim();
          const closingTimeParts = closingTime.split(':');
          curDate.setHours(parseInt(closingTimeParts[0], 10) + this._timeOffset, parseInt(closingTimeParts[1], 10));

          return curDate;
        }
        break;
      case 3:
        timeParts = this.wedHours.split('-');
        if (timeParts && timeParts.length > 1) {
          const closingTime = timeParts[1].trim();
          const closingTimeParts = closingTime.split(':');
          curDate.setHours(parseInt(closingTimeParts[0].trim(), 10) + this._timeOffset, parseInt(closingTimeParts[1].trim(), 10));

          return curDate;
        }
        break;
      case 4:
        timeParts = this.thursHours.split('-');
        if (timeParts && timeParts.length > 1) {
          const closingTime = timeParts[1].trim();
          const closingTimeParts = closingTime.split(':');
          curDate.setHours(parseInt(closingTimeParts[0], 10) + this._timeOffset, parseInt(closingTimeParts[1], 10));

          return curDate;
        }
        break;
      case 5:
        timeParts = this.friHours.split('-');
        if (timeParts && timeParts.length > 1) {
          const closingTime = timeParts[1].trim();
          const closingTimeParts = closingTime.split(':');
          curDate.setHours(parseInt(closingTimeParts[0], 10) + this._timeOffset, parseInt(closingTimeParts[1], 10));

          return curDate;
        }
        break;
      case 6:
        timeParts = this.satHours.split('-');
        if (timeParts && timeParts.length > 1) {
          const closingTime = timeParts[1].trim();
          const closingTimeParts = closingTime.split(':');
          curDate.setHours(parseInt(closingTimeParts[0], 10) + this._timeOffset, parseInt(closingTimeParts[1], 10));

          return curDate;
        }
        break;
      default:
        return null;
    }
  }

  get timesListing(): {
    label: string,
    value: string
  }[] {
    const timesArray = [ this.monHours, this.tuesHours, this.wedHours, this.thursHours, this.friHours, this.satHours, this.sunHours];
    let outputArray: {
      label: string,
      value: string
    }[] = [];

    timesArray.forEach((curTime: string, i: number) => {
      switch (i) {
        case 0:
          outputArray = this._generateDateValues(curTime, null, 'Mon', outputArray);
          break;
        case 1:
          outputArray = this._generateDateValues(curTime, this.monHours, 'Tues', outputArray);
          break;
        case 2:
          outputArray = this._generateDateValues(curTime, this.tuesHours, 'Wed', outputArray);
          break;
        case 3:
          outputArray = this._generateDateValues(curTime, this.wedHours, 'Thurs', outputArray);
          break;
        case 4:
          outputArray = this._generateDateValues(curTime, this.thursHours, 'Fri', outputArray);
          break;
        case 5:
          outputArray = this._generateDateValues(curTime, this.friHours, 'Sat', outputArray);
          break;
        case 6:
          outputArray = this._generateDateValues(curTime, this.satHours, 'Sun', outputArray);
          break;
      }
    });

    return outputArray;
  }

  private _generateDateValues(
    curTime: string,
    prevTime: string,
    label: string,
    outputArray: {
      label: string,
      value: string
    }[] = []
  )
  :
  {
    label: string,
    value: string
  }[] {
    if (curTime !== prevTime) {
      if (curTime && curTime.trim()) {
        const timeParts = curTime.split('-');
        const newEntry = {
          label: label,
          // tslint:disable-next-line: max-line-length
          value: `${timeParts[0] && timeParts[0].trim() && timeParts[0].trim().charAt(0) === '0' ? timeParts[0].substr(1) : timeParts[0]}am - ${timeParts[1] && timeParts[1].trim() && timeParts[1].trim().charAt(0) === '0' ? timeParts[1].substr(1) : timeParts[1]}pm`
        };
        outputArray.push(newEntry);
      } else {
        const newEntry = {
          label: label,
          value: 'Closed'
        };
        outputArray.push(newEntry);
      }
    } else {
      outputArray[outputArray.length - 1].label = `${outputArray[outputArray.length - 1].label.split('-')[0]} - ${label}`;
    }
    return outputArray;
  }
}
