<div style="border: 1px solid green;" *ngIf="document" [ngClass]="{ 'has-edit-button': page.isPreview() }">
    <!-- <pre>
        Component data:
        Name:
        {{ component.getName() }}
        Comp:
        {{ component.getComponent() | json }}
        Models:
        {{ component.getModels() | json }}
        Info:
        {{ info | json }}
    </pre> -->
    <pre>{{ data | json }}</pre>
    <!-- <app-dump-grid [input]="data"></app-dump-grid> -->
    <ng-container [brManageContentButton]="document"></ng-container>
</div>
