<br-page [configuration]="configuration" [mapping]="mapping" class="d-flex flex-column vh-100">
    <ng-template let-page="page">
        <div [ngClass]="{'home-page': page.getTitle() == 'Home Page'}">
            <app-global-header *brComponent="'menu'; let component" [component]="component" [page]="page">
            </app-global-header>
        </div>
        <main class="main-content">
            <div class="grid-container home-page">
                <div class="grid-x grid-margin-x home-page__content-container">
                    <div class="cell">
                        <div *ngIf="page.getTitle() !== 'Home Page'">
                            <app-breadcrumb *brComponent="'breadcrumb'; let component" [component]="component"
                                [page]="page">
                            </app-breadcrumb>
                        </div>
                        <div *ngIf="page.getTitle() == 'Right to Know'">
                            <app-rtkd-form></app-rtkd-form>
                        </div>
                        <ng-container brComponent="main"></ng-container>
                    </div>
                </div>
            </div>
        </main>
        <footer class="bg-dark text-light py-3">
            <div class="container clearfix">
                <div class="overflow-hidden">
                    <app-global-footer *brComponent="'footer'; let component" [component]="component" [page]="page">
                    </app-global-footer>
                </div>
            </div>
        </footer>
        <cookie-info *brComponent="'cookie-info'; let component" [component]="component" [page]="page">
        </cookie-info>
    </ng-template>
</br-page>
