import { Component, OnInit, Input } from '@angular/core';
// import { BaseComponent, ImageUrlService, PageModelService, getNestedObject } from '../../upgrade/bloomreach';
// import { SingleContentComponent } from 'src/app/upgrade/SingleContentComponent';
import reframe from 'reframe.js';
import { ContentAlignment } from '../../enums/content-alignment.enum';

// Upgrade
import { Component as BrComponent, Document, ImageSet, Page } from '@bloomreach/spa-sdk';

@Component({
  selector: 'app-content-section-video',
  templateUrl: './content-section-video.component.html',
  styleUrls: ['./content-section-video.component.scss']
})

export class ContentSectionVideoComponent implements OnInit {
  // @Input() configuration: any;
  @Input() component!: BrComponent;
  @Input() page!: Page;

  contentHeading: string;
  contentContent: string;
  contentOrientation: string;

  iframe_html: any;
  YT: any;
  video: any;
  player: any;
  reframed: Boolean = false;

  // Expose to template
  ContentAlignment = ContentAlignment;

  // A mapping of options from hippo to ContentAlignment
  private readonly hippoContentAlignmentMap = {
    'Left': ContentAlignment.Left,
    'Center': ContentAlignment.Center,
    'Right': ContentAlignment.Right
  };

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get data() {
    let data = this.document?.getData();
    return data;
  }

   init() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  ngOnInit() {
      this.init();
      // super.ngOnInit();
      if (this.data) {
      this.contentHeading = this.data.contentHeadline;
        // tslint:disable-next-line:max-line-length
        this.contentContent = this.data.mainContent.value;
        // if (this.configuration) {
        //   const params = getNestedObject(this.configuration, ['_meta', 'paramsInfo']);
        //   this.contentOrientation = this.hippoContentAlignmentMap[params.alignment];
        // }
        let linkVar = this.data.videoLink;
        linkVar = linkVar.substring(linkVar.indexOf( '=' ) + 1);

        this.video = linkVar;

        let timeVar = '0';
        let timeCheck = 0;

        if (linkVar.includes('t=')) { // run if link includes time stamp in seconds
          timeVar = linkVar.substring(linkVar.indexOf( '=' ) + 1); // get time with s
          timeVar =  timeVar.replace('s', ''); // remove s from time
          timeCheck = parseInt(timeVar, 10);
        }

        if (linkVar.includes('&')) { // check if link has time stamp
          linkVar = linkVar.substring(0, linkVar.indexOf( '&' )); // final link without ampersand
        }

        window['onYouTubeIframeAPIReady'] = (e) => {
          this.YT = window['YT'];
          this.reframed = false;
          this.player = new window['YT'].Player('video_player', {
            videoId: linkVar,
            playerVars: {'modestbranding': 1, 'start': timeCheck },
            events: {
              'onStateChange': this.onPlayerStateChange.bind(this),
              'onError': this.onPlayerError.bind(this),
              'onReady': (e) => {
                if (!this.reframed) {
                this.reframed = true;
                reframe(e.target.a);
                }
              }
            }
          });
        };
      }
    }

    onPlayerStateChange(event) {
      switch (event.data) {
        case window['YT'].PlayerState.PLAYING:
          if (this.cleanTime() === 0) {
            console.log('started ' + this.cleanTime());
          } else {
            console.log('playing ' + this.cleanTime() );
          }
          break;
        case window['YT'].PlayerState.PAUSED:
          if (this.player.getDuration() - this.player.getCurrentTime() !== 0) {
            console.log('paused' + ' @ ' + this.cleanTime());
          }
          break;
        case window['YT'].PlayerState.ENDED:
          console.log('ended ');
          break;
      }
    }

    // utility
    cleanTime() {
      return Math.round(this.player.getCurrentTime());
    }
    onPlayerError(event) {
      switch (event.data) {
        case 2:
          // console.log('' + this.video);
          break;
        case 100:
          break;
        case 101 || 150:
          break;
      }
    }
  }
