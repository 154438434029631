import { Component, Input } from '@angular/core';
// import { DocumentData, DocumentModels } from '../../../app/bloomreach/page-model';
import { Component as BrComponent, Document, Page } from '@bloomreach/spa-sdk';

@Component({
  selector: 'app-fallback',
  templateUrl: './fallback.component.html',
  styleUrls: ['./fallback.component.scss']
})
export class FallbackComponent {
  @Input() component!: BrComponent;
  @Input() page!: Page;

  get document() {
    const { document } = this.component.getModels();

    return document && this.page.getContent<Document>(document);
  }

  get info() {
    const id = this.document?.getId();
    const t = this.component;
    return {
      t,
      id
    };
  }

  get data() {
    return this.document?.getData();
  }
}
