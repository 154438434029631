import { Component, OnInit, Inject, Input } from '@angular/core';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';

// Upgrade
import { Component as BrComponent, Document, ImageSet, Page } from '@bloomreach/spa-sdk';
@Component({
  selector: 'app-subsidiary-heading',
  templateUrl: './subsidiary-heading.component.html',
  styleUrls: ['./subsidiary-heading.component.scss']
})
export class SubsidiaryHeadingComponent implements OnInit{
  @Input() component!: BrComponent;
  @Input() page!: Page;

  imageHeroCTA: string;
  subsidiaryHeading: string;
  susidiaryHeadingWebLink: string;
  susidiaryHeadingWebText: string;
  susidiaryHeadingWebIsExternal: boolean;
  newTab: boolean;
  assetLink: boolean;
  subsidiaryHeadingLocationText: string;
  showSwitchLanguage: boolean = false;

  constructor(
    private pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private DOM: any
  ) {}

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get data() {
    let data = this.document?.getData();
    this.imageHeroCTA = data.link?.text;
    return data;
  }

  ngOnInit() {
    // Disabled due to it throwing error, maybe move this in the request interceptor and find a way to display the button from there
    // if(window.location.href.indexOf('/fr') === -1) {
    //   this.showSwitchLanguage = true;
    // }

    if (this.data) {
      this.subsidiaryHeading = this.data.pageTitle.value;
      this.susidiaryHeadingWebLink = this.data.link.url;
      if(this.data.link.primaryDocument) {
        this.susidiaryHeadingWebLink = this.page.getContent<Document>(this.data.link.primaryDocument).getUrl()!;
      }
      if (this.data.link) {
        this.susidiaryHeadingWebText = this.data.link.text;
        this.susidiaryHeadingWebIsExternal = this.data.link.isExternal;
        this.newTab = this.data.link.newTab;
        if (this.susidiaryHeadingWebLink.includes('assets')) {
          this.assetLink = true;
        } else {
          this.assetLink = false;
        }
      }
      this.subsidiaryHeadingLocationText = this.data.locationsLinkText;
    }
  }

  /**
   * Scroll to the first subsidiary-locations-section
   *
   * TODO This isn't working
   * perhaps it needs
   */
  scrollToLocations() {
    this.pageScrollService.scroll({
      document: this.DOM,
      scrollTarget: '.subsidiary-locations-section'
    });
  }
}
