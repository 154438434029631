import { Component, OnInit, Input, Inject, PLATFORM_ID } from "@angular/core";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";

import { CookieService } from "ngx-cookie-service";

// Upgrade
import {
  Component as BrComponent,
  Document,
  ImageSet,
  Page,
} from "@bloomreach/spa-sdk";

@Component({
  selector: "cookie-info",
  templateUrl: "./cookie-info.component.html",
  styleUrls: ["./cookie-info.component.scss"],
})
export class CookieInfoComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;

  imageHeroCTA: string;
  @Input() configuration: any;
  cookieConfiguration: any;

  cookieBannerContent: string;

  isRead: boolean = true;
  private isBrowser: boolean;

  constructor(
    private cookieService: CookieService,
    @Inject(DOCUMENT) private DOM: any,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get data() {
    return this.document?.getData();
  }

  ngOnInit() {
    this.getCookieConfiguration();

    // Fix to display the cookie info only if the flag is false
    // Added this in order to hide the cookie popup when the page loads, this would show up on every page navigation or load event
    if (this.isBrowser) {
      setTimeout(() => {
        if (this.isRead === false) {
          let cookieInfo = document.getElementsByClassName("cookie-info")[0];
          cookieInfo.classList.remove("hide");
        }
      });
    }

    // Get the cookie using the cookie service
    let cookieStatus = this.cookieService.get("cookieConsent");
    // If there is no cookie set the display flag for the cookie info
    if (cookieStatus !== "") {
      // Set the isRead flag to true, so it will hide the cookie info
      this.isRead = true;
    } else {
      // Otherwise set the flag to false, so it will show the cookie info
      this.isRead = false;
    }
  }

  isRendered(): boolean {
    if (this.isRead) {
      return true;
    } else {
      return false;
    }
  }

  getCookieConfiguration(): void {
    const cookieConfigurationRef =
      this.component.getModels<any>().document.$ref;
    this.cookieConfiguration = this.page.getContent(cookieConfigurationRef);
    this.cookieBannerContent =
      this.cookieConfiguration.model.data.mainContent.value;
  }

  closeCookiePopup(event) {
    // Set the cookie
    this.setCookieConsent(true);
    // Update the isRead flag
    this.isRead = true;
  }

  // Set the cookie based on the flag to expire in 90 days
  setCookieConsent(allow: boolean) {
    let expDate = new Date();
    expDate.setTime(expDate.getTime() + 3 * 30 * 24 * 60 * 60 * 1000);
    this.cookieService.set("cookieConsent", allow.toString(), {
      // expires: new Date(),
      expires: expDate,
      sameSite: "Strict",
    });
    this.reRunConsentGTMScript();
  }

  reRunConsentGTMScript() {
    // Remove the script that checks for cookie consent, and re-add it in order to be re-run
    const scripts: HTMLScriptElement[] =
      this.DOM.getElementsByTagName("script");
    let head = document.getElementsByTagName("head")[0];
    let scriptTag = document.createElement("script");
    for (let script of scripts) {
      if (script.id === "cookieConsentAnalytics") {
        head.removeChild(script);
        let newScriptTag = document.createElement("script");
        newScriptTag.innerText = scriptTag.innerText;
        head.appendChild(newScriptTag);
        scriptTag = newScriptTag;
      }
    }
  }

  // Remove all cookies when the user declines the use of them
  removeAllCookies(event) {
    this.cookieService.deleteAll();
    // Set the cookie
    this.setCookieConsent(false);
    // Update the isRead flag, to hide the banner for current session
    this.isRead = true;
  }
}
