<div class="global-footer">
  <div class="manage-content-container" *ngIf="page.isPreview()">
    <ng-container [brManageMenuButton]="menu"></ng-container>
  </div>
  <div class="grid-container">
    <nav class="grid-x grid-margin-x">
      <div class="large-12 small-12" *ngFor="let items of footerLinks">
        <h3>{{items.heading}}</h3>
        <ul class="global-footer cell">
          <li *ngFor="let link of items.linkItems">
            <a *ngIf="!link.isExternal && link.url; else isExternal" routerLink="{{link.url | parseUrl: 'pathname'}}">{{link.text}}</a>
            <ng-template #isExternal>
              <a *ngIf="link.url; else noLink" href="{{link.url}}">{{link.text}}</a>
            </ng-template>
            <ng-template #noLink>
              <a>{{link.text}}</a>
            </ng-template>
          </li>
        </ul>
      </div>
    </nav>
    <a routerLink="{{footerCTALink | parseUrl: 'pathname'}}" class="button primary mobile">{{footerCTAText}}</a>
    <ul class="social-icons">
      <li *ngFor="let socialLink of socialLinks">
        <a *ngIf="!socialLink.isExternal && socialLink.url; else isExternal" routerLink="{{socialLink.url | parseUrl: 'pathname'}}">
          <span class="{{socialLink.text}}"></span>
        </a>
        <ng-template #isExternal>
          <a *ngIf="socialLink.url; noLink" href="{{socialLink.url}}">
            <span class="{{socialLink.text}}"></span>
          </a>
        </ng-template>
        <ng-template #noLink>
          <a>
            <span class="{{socialLink.text}}"></span>
          </a>
        </ng-template>
      </li>
    </ul>
    <a routerLink="{{footerCTALink  | parseUrl: 'pathname'}}" class="button primary desktop">{{footerCTAText}}</a>
  </div>
</div>
<div class="bottom-footer">
  <div class="grid-container">
    <ul>
      <li *ngFor="let bottomItems of bottomFooterLinks">
        <a *ngIf="!bottomItems.isExternal && bottomItems.url; else isExternal" href="{{bottomItems.url}}">{{bottomItems.text}}</a>
        <ng-template #isExternal>
          <a *ngIf="bottomItems.url; else noLink" href="{{bottomItems.url}}">{{bottomItems.text}}</a>
        </ng-template>
        <ng-template #noLink>
          <span>{{bottomItems.text}}</span>
        </ng-template>
      </li>
    </ul>
  </div>
</div>
