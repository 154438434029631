<app-breadcrumb [configuration]="breadcrumbConfig"></app-breadcrumb>

<div class="generic-content-page">
  <div class="grid-container">
      <div class="grid-x grid-margin-x">
          <div class="cell large-12">
              <ng-container [brManageContentButton]="page"></ng-container>
          </div>
        </div>
  </div>
</div>
