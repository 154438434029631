/**
 * Scrolls to an html element with an optional offset.
 * @param element element to scroll to
 * @param offset optional offset value for scrolling.
 */
export function scrollToElement(element: HTMLElement, offset: number = 0, scrollContainer?: HTMLElement) {
  const scrollTop = element.offsetTop + offset;

  if (typeof window !== 'undefined' && !scrollContainer) {
    window.scroll({
      top: scrollTop,
      behavior: 'smooth'
    });
  } else if (typeof window !== 'undefined' && scrollContainer) {
    scrollContainer.scroll({
      top: scrollTop,
      behavior: 'smooth'
    });
  }
}

/**
 * Adds a new string to a specified position in an existing string.
 * @param string the string to be modified
 * @param position position to insert into the new string
 * @param stringToAdd the string to be inserted.
 */
export function spliceString(string, position, stringToAdd) {
  return [string.slice(0, position), stringToAdd, string.slice(position)].join('');
}

export function convertHippoMenuItem(cmsMenuSection: any): {
  text: string,
  url: string,
  isExternal: boolean
}[] {
  return cmsMenuSection.childMenuItems.map(
    cmsMenuItem => {
      const cmsMenuLink = getNestedObject(cmsMenuItem, ['_links', 'site']);

      const newMenuItem: {
        text: string,
        url: string,
        isExternal: boolean
      } = {
        text: cmsMenuItem.name,
        url: cmsMenuLink ? _stripSiteFromLink(cmsMenuLink.href) : '',
        isExternal: cmsMenuLink ? cmsMenuLink.type === 'external' : false
      };

      return newMenuItem;
    }
  );
}

/**
 * If "site" is present in the url, strip from url and return that string.
 * @param navItem the string to be reformatted
 */
function _stripSiteFromLink(navItem: string): string {
  if (navItem) {
    if (!navItem.includes('site/_cmsinternal') && navItem.includes('site/')) {
      navItem = navItem.replace('site/', '');
    }
  }

  return navItem;
}

/**
 * Parse an object and return a certain nested property from it
 * @param nestedObj the object that needs to be parsed in order to get the desired property
 * @param pathArr the keys of the nested objects within nestedObj, sent as an array
 * @returns the nested property of the object
 */
export function getNestedObject(
  nestedObj: object,
  pathArr: string[]
): any {
  return pathArr.reduce(
    (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : null),
    nestedObj
  );
}
