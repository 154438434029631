import { Component, OnInit, Input, ElementRef, Renderer2, ViewChild, HostListener } from '@angular/core';

import {
  Component as BrComponent,
  Reference,
  Document,
  Page,
  isMenu,
  isLink,
  TYPE_LINK_EXTERNAL,
} from "@bloomreach/spa-sdk";

// Enums used to find the content for different sections in the hippo nav data.
enum cmsNavTitles {
  mainNavArea = "Main Nav Area",
  topHatLinks = "Top Hat Links",
  logoLink = "Logo Link",
  findAYardCTA = "Find a Yard CTA",
}

interface NavItem {
  text: string;
  url: string;
  isExternal: boolean;
  megaMenu?: {
    links: {
      text: string;
      url: string;
      isExternal: boolean;
    }[];
  };
}

interface MenuModels {
  main: Reference;
}

@Component({
  selector: "app-global-header",
  templateUrl: "./global-header.component.html",
  styleUrls: ["./global-header.component.scss"],
})
export class GlobalHeaderComponent implements OnInit {
  @Input() configuration: any;
  @Input() component!: BrComponent;
  @Input() page!: Page;

  @ViewChild('headerElement') headerElement: ElementRef; // Reference to the element whose height you want to measure
  @ViewChild('scrollFixElement') scrollFixElement: ElementRef; // Reference to the element to which you want to set the height
  
  menuConfiguration: any;
  navImage: string;
  logoUrl: string;
  logoAltText: string;
  topHatLinks: NavItem[];
  navLinks: NavItem[];
  buttonText: string;
  buttonLink: string;
  navStatus = true;

  navStatusMessage: boolean;

  constructor(private renderer: Renderer2) { }

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get data() {
    let data = this.document?.getData();
    return data;
  }

  get menu() {
    const menuRef = this.component.getModels<MenuModels>()?.main;
    const menu = menuRef && this.page.getContent(menuRef);

    return isMenu(menu) ? menu : undefined;
  }

  ngOnInit() {
    this.getMenuConfiguration();
    this.navImage = "../assets/images/icon-menu-nav.png";
  }

  ngAfterViewInit() {
    this.setHeight();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setHeight();
  }

  setHeight() {
    const targetHeight = this.headerElement.nativeElement.offsetHeight; // Get the height of the target element
    this.renderer.setStyle(this.scrollFixElement.nativeElement, 'height', `${targetHeight}px`); // Set the height of the other element
  }

  getMenuConfiguration(): void {
    const cmsMenuItems = this.menu.getItems();

    /**
     * Set all the content for the main navigation by reformatting the content from the cms
     * into the format the components are expecting
     *
     * Previously the way to get the data for the menu was using direct property access. But no, no. Not anymore
     * Since the upgrade of br-sdk the properties cannot directly accessed, they need to be accessed using the provided
     * methods from bloomreach/spa-sdk MenuItemImpl or MenuItem interfaces (ie. getName(), getLink(), getUrl()..)
     */
    if (cmsMenuItems && cmsMenuItems.length) {
      cmsMenuItems.forEach((cmsMenuSection) => {
        switch (cmsMenuSection.getName()) {
          case cmsNavTitles.topHatLinks:
            this.topHatLinks = cmsMenuSection
              // This is the way to return all the children and iterate through each one to get the data for the Top hat links
              .getChildren()
              .map((cmsMenuItem) => {
                const newMenuItem: {
                  text: string;
                  url: string;
                  isExternal: boolean;
                } = {
                  text: cmsMenuItem.getName(),
                  url: cmsMenuItem.getLink()
                    ? this._stripSiteFromLink(cmsMenuItem.getUrl())
                    : "",
                  isExternal:
                    isLink(cmsMenuItem.getLink()) &&
                    cmsMenuItem.getLink().type === TYPE_LINK_EXTERNAL
                      ? true
                      : false,
                };

                return newMenuItem;
              });
            break;
          case cmsNavTitles.logoLink:
            const cmsLogoLink = cmsMenuSection.getChildren()[0];
            this.logoUrl = this._stripSiteFromLink(cmsLogoLink.getUrl());
            this.logoAltText = cmsLogoLink.getName();
            break;
          case cmsNavTitles.mainNavArea:
            this.navLinks = cmsMenuSection
              .getChildren()
              .map((curCMSNavItem) => {
                // Pull out the marketing block from the child nav items and save to it's own variable.
                // TODO Figure out what this part is, could be some component that was implemented but dropped later down the road due to unusage
                /* const marketingBlockCMS = curCMSNavItem
                  .getChildren()
                  .find(
                    (menuItem) =>
                      menuItem.getParameters() && menuItem.getParameters()
                  );
                console.log(marketingBlockCMS);
                let marketingBlockContentPath = "";
                let marketingBlockCMSContent: any;
                if (marketingBlockCMS) {
                  // marketingBlockContentPath = marketingBlockCMS.parameters.marketing.replace('/', '-');
                  // marketingBlockCMSContent = this.data.getNestedObject(this.configuration, ['models', marketingBlockContentPath]);
                  // const marketingBlockCMSIndex = curCMSNavItem.getChildren().indexOf(marketingBlockCMS);
                  // curCMSNavItem.getChildren()[marketingBlockCMSIndex].isMarketingBlock = true;
                }
                */

                const newNavItem: NavItem = {
                  text: curCMSNavItem.getName(),
                  // TODO change with isExternal pipe
                  isExternal:
                    isLink(curCMSNavItem.getLink()) &&
                    curCMSNavItem.getLink().type === TYPE_LINK_EXTERNAL,
                  url: this._stripSiteFromLink(curCMSNavItem.getUrl()),
                  megaMenu: {
                    // See above explanation from topHatLinks
                    links: curCMSNavItem.getChildren().map((cmsMenuItem) => {
                      const newMenuItem: {
                        text: string;
                        url: string;
                        isExternal: boolean;
                      } = {
                        text: cmsMenuItem.getName(),
                        url: cmsMenuItem.getLink()
                          ? this._stripSiteFromLink(cmsMenuItem.getUrl())
                          : "",
                        isExternal:
                          isLink(cmsMenuItem.getLink()) &&
                          cmsMenuItem.getLink().type === TYPE_LINK_EXTERNAL
                            ? true
                            : false,
                      };

                      return newMenuItem;
                    }),
                  },
                };
                return newNavItem;
              });
            break;
          case cmsNavTitles.findAYardCTA:
            const findAYardCTALinkItem = cmsMenuSection.getChildren()[0];
            this.buttonLink = this._stripSiteFromLink(
              findAYardCTALinkItem.getUrl()
            );
            this.buttonText = findAYardCTALinkItem.getName();
            break;
        }
      });
    }
  }

  /**
   * If "site" is present in the url, strip from url and return that string.
   * @param navItem the string to be reformatted
   *
   * TODO Remove in case this can be avoided while using the Channel editor
   */
  private _stripSiteFromLink(navItem: string): string {
    if (navItem) {
      if (!navItem.includes("site/_cmsinternal") && navItem.includes("site/")) {
        navItem = navItem.replace("site/", "");
      }
    }

    return navItem;
  }

  toggleNav() {
    // Get the body tag
    let body = document.getElementsByTagName("body")[0];

    // Add the no-scroll class based on the nav status
    if (this.navStatus) {
      body.classList.add("no-scroll");
    } else {
      body.classList.remove("no-scroll");
    }

    // Return the inverse of the nav status in order to hide it
    return (this.navStatus = !this.navStatus);
  }

  toggleClass(event) {
    if (event.srcElement.classList.contains("show")) {
      event.srcElement.classList.remove("show");
      event.srcElement.parentNode.classList.remove("active");
    } else {
      event.srcElement.classList.add("show");
      event.srcElement.parentNode.classList.add("active");
    }
  }

  drill(event) {
    let drillElement = event.srcElement;
    drillElement.classList.add("show");
    let parent = drillElement.parentNode;
    let foundParent = false,
      foundWrapper = false;

    while (drillElement.parentNode) {
      // If the drilldown container has been found set the flag and add the .active class
      if (parent.hasAttribute("data-drilldown-container")) {
        foundWrapper = true;
        parent.classList.add("active-drill");
      }
      // If the drilldown parent has been found set the flag and add the .active class
      if (parent.hasAttribute("data-drilldown-parent")) {
        foundParent = true;
        parent.classList.add("active");
      }
      // If both flags are true
      if (foundParent && foundWrapper) {
        // Break the while loop
        break;
      }

      // Go up the tree to the next parent
      parent = parent.parentNode;
    }
  }

  drillBack(event) {
    let drillElement = event.srcElement;
    drillElement.classList.remove("show");
    let parent = drillElement.parentNode;
    let foundParent = false,
      foundWrapper = false;

    while (drillElement.parentNode) {
      // If the drilldown container has been found set the flag and remove the .active class
      if (parent.hasAttribute("data-drilldown-container")) {
        foundWrapper = true;
        parent.classList.remove("active-drill");
      }
      // If the drilldown parent has been found set the flag and remove the .active class
      if (parent.hasAttribute("data-drilldown-parent")) {
        foundParent = true;
        parent.classList.remove("active");
      }
      // If both flags are true
      if (foundParent && foundWrapper) {
        // Break the while loop
        break;
      }

      // Go up the tree to the next parent
      parent = parent.parentNode;
    }
  }

  receiveStatus($event) {
    this.toggleNav();
  }
}
