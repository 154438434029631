<div class="manage-content-container" *ngIf="page.isPreview()">
  <ng-container [brManageContentButton]="document"></ng-container>
</div>

<p *ngIf="!data">The Subsidiary Locations Component will render after a content document is selected in the authoring modal.</p>
<div *ngIf="data" class="subsidiary-locations-section">
    <div class="subsidiary-locations-section__wrapper">
        <div class="grid-container grid-x">
          <div class="cell large-5 small-12">
            <h2>{{subsidiaryLocationsHeading}}</h2>
            <form class="subsidiary-locations-section__finder desktop" [formGroup]="locationsForm" (ngSubmit)="locationFormSubmit()">
              <h3>Find a GMS Location Near You</h3>
              <label>
                <span class="show-for-sr">{{subsidiaryLocationsFinderPlaceholder}}</span>
                <input type="text" placeholder="{{subsidiaryLocationsFinderPlaceholder}}" formControlName="searchQuery" (input)="locationFormChange()">
              </label>
              <button type="submit" class="button primary">Find a Location</button>
            </form>
          </div>
          <div class="cell large-7 small-12">
            <ul>
              <li *ngFor="let location of subsidiaryLocations">
                <img src="{{location.image}}">
                <div>
                  <h3 *ngIf="location.displayInfo === null; else displayInfoText">{{location.company}} - {{location.city}}</h3>
                  <ng-template #displayInfoText>
                    <h3>{{location.displayInfo}}</h3>
                  </ng-template>
                  <p class="details" *ngIf="location.details">{{location.details}}</p>
                  <p class="address1">{{location.addressLine1}}</p>
                  <p class="address2">{{location.city}}, {{location.state}} {{location.postalCode}}</p>
                  <span *ngIf="location.timesListing as timesListing">
                    <p class="hours" *ngFor="let listingItem of timesListing; index as i;">{{ listingItem.label }}: {{ listingItem.value }}</p>
                  </span>
                  <br/>
                  <p class="phone"><span *ngIf="location.telNumber" class="bold">P:</span>{{location.telNumber}}</p>
                  <p class="fax"><span *ngIf="location.faxNumber" class="bold">F:</span>{{location.faxNumber}}</p>
                  <a href="https://www.google.com/maps/dir/?api=1&destination={{ location.addressLine1 }}, {{ location.city }}, {{ location.state }}, {{ location.postalCode }}"><span class="icon-directions-icon"><span class="path1"></span><span class="path2"></span></span>{{subsidiaryLocationsGetDirectionsText}}</a>
                </div>
              </li>
            </ul>
          </div>
          <div class="cell large-12">
              <form class="subsidiary-locations-section__finder mobile" [formGroup]="locationsForm" (ngSubmit)="locationFormSubmit()">
                <h3>Find a GMS Location Near You</h3>
                <label>
                  <span class="show-for-sr">{{subsidiaryLocationsFinderPlaceholder}}</span>
                  <input type="text" placeholder="{{subsidiaryLocationsFinderPlaceholder}}" formControlName="searchQuery" (input)="locationFormChange()">
                </label>
                <button type="submit" class="button primary">Find a Location</button>
              </form>
          </div>
        </div>
    </div>
</div>
