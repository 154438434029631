import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';

import { FindAYardComponent } from './pages/find-a-yard/find-a-yard.component';
import { SearchResultsPageComponent } from './pages/search-results-page/search-results-page.component';

// Upgrade
import { IndexComponent } from './index/index.component';

/**
 * Because the cms appends site/_cmsinteral to all requests, we should put all our routes here.
 * The actual "routes" variable will then make all these routes children of "site/_cmsinteral"
 * so that requests coming from the cms preview mode will route correctly.  Prevents the need to duplicate
 * routes.
 */
const appRoutes: Routes = [
    { path: 'find-a-yard', component: FindAYardComponent },
    { path: 'search-results', component: SearchResultsPageComponent },
    { path: '**', component: IndexComponent },
    // Any other route is assumed to be generic content
];

const routerOptions: ExtraOptions = {
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
