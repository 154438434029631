import { Component, OnInit, Input } from "@angular/core";
// import { BaseComponent, ImageUrlService, PageModelService, getNestedObject, RequestContextService } from '../../upgrade/bloomreach';
// import { SingleContentComponent } from 'src/app/upgrade/SingleContentComponent';
import { SeoService } from "../../services/seo.service";

// Upgrade
import { Component as BrComponent, Document, Page } from "@bloomreach/spa-sdk";

/**
 * This should be included on every page.  Sets the appropriate content based on parameters set in the CMS for SEO meta tags.
 */
@Component({
  selector: "app-seo",
  templateUrl: "./seo.component.html",
  styleUrls: ["./seo.component.scss"],
})
export class SeoComponent implements OnInit {
  // @Input() configuration: any;
  @Input() component!: BrComponent;
  @Input() page!: Page;

  // seoInfo: {
  //   description: string,
  //   keywords: string,
  //   title: string
  // };
  isPreview: boolean;

  constructor(private seoService: SeoService) {}

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get seoInfo() {
    const seoInfo = this.component.getModels()["seo-information"];
    return seoInfo;
  }

  ngOnInit() {
    this.isPreview = this.page.isPreview();
    if (this.seoInfo) {
      if (this.seoInfo.description) {
        this.seoService.createMetaDescription(this.seoInfo.description);
      }

      if (this.seoInfo.keywords) {
        this.seoService.createMetaKeywords(this.seoInfo.keywords);
      }

      if (this.seoInfo.title && this.seoInfo.title !== '') {
        this.seoService.createTitleTag(this.seoInfo.title);
      } else {
        // Using this as a fallback
        this.seoService.createTitleTag(this.page.getTitle());
      }
    } else {
      // In case the seoInfo isn't set still use the fallback
      this.seoService.createTitleTag(this.page.getTitle());
    }
  }
}
