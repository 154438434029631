<div class="manage-content-container" *ngIf="page.isPreview()">
  <ng-container [brManageContentButton]="document"></ng-container>
</div>

<p *ngIf="!data">The Global Hero Component will render after a content document is selected in the authoring modal.</p>
<section *ngIf="data" class="global-hero-block {{globalHeroType}}" [ngClass]="{ 'has-banner': globalHeroBanner == 'true' }" [ngStyle]="{'background-image': 'url('+globalHeroImageDisplay+')'}">
  <div class="global-hero-block__wrapper {{globalHeroOrientation}} ie11-{{ieVar}}" *ngIf="globalHeroOrientation == 'left';"  [ngStyle]="{'width': globalHeroContentWidth, 'text-align': globalHeroOrientation}">
    <div class="tag" *ngIf="globalHeroHeader">{{globalHeroHeader}}</div>
    <h1 [innerHTML]='globalHeroHeading' *ngIf="globalHeroIsPageTitle == 'true'; else heading2"></h1>
    <ng-template #heading2>
          <h2 [innerHTML]='globalHeroHeading'></h2>
    </ng-template>

      <p [innerHTML]='globalHeroContent'></p>
      <div *ngIf="globalHeroCTA && globalHeroCTAUrl">
          <a *ngIf="globalHeroCTAIsExternal && !newTab; else openNewTab;" href="{{globalHeroCTAUrl | protocol}}" class="button primary">{{globalHeroCTA}}</a>
          <ng-template #openNewTab>
            <a *ngIf="globalHeroCTAIsExternal && newTab; else internalNew;" href="{{globalHeroCTAUrl | protocol}}" target="_blank" class="button primary">{{globalHeroCTA}}</a>
          </ng-template>
          <ng-template #internalNew>
            <a *ngIf="!globalHeroCTAIsExternal && !assetLink && newTab; else internal;" routerLink="{{globalHeroCTAUrl | parseUrl: 'pathname'}}" target="_blank" class="button primary">{{globalHeroCTA}}</a>
          </ng-template>
          <ng-template #internal>
            <a *ngIf="!globalHeroCTAIsExternal && !assetLink && !newTab; else assetNew;" routerLink="{{globalHeroCTAUrl | parseUrl: 'pathname'}}" class="button primary">{{globalHeroCTA}}</a>
          </ng-template>
          <ng-template #assetNew>
            <a *ngIf="!globalHeroCTAIsExternal && assetLink && newTab; else asset;" href="{{globalHeroCTAUrl}}" target="_blank" class="button primary">{{globalHeroCTA}}</a>
          </ng-template>
          <ng-template #asset>
            <a href="{{globalHeroCTAUrl}}" class="button primary">{{globalHeroCTA}}</a>
          </ng-template>
        </div>
  </div>

  <div class="global-hero-block__wrapper {{globalHeroOrientation}} ie11-{{ieVar}}" *ngIf="globalHeroOrientation == 'right';"  [ngStyle]="{'width': globalHeroContentWidth, 'text-align': globalHeroOrientation}">
    <div class="tag" *ngIf="globalHeroHeader">{{globalHeroHeader}}</div>
    <h1 [innerHTML]='globalHeroHeading' *ngIf="globalHeroIsPageTitle == 'true'; else heading2"></h1>
    <ng-template #heading2>
          <h2 [innerHTML]='globalHeroHeading'></h2>
    </ng-template>
    <p [innerHTML]='globalHeroContent'></p>
    <div *ngIf="globalHeroCTA && globalHeroCTAUrl">
      <a *ngIf="globalHeroCTAIsExternal && !newTab; else openNewTab;" href="{{globalHeroCTAUrl | protocol}}" class="button primary">{{globalHeroCTA}}</a>
      <ng-template #openNewTab>
        <a *ngIf="globalHeroCTAIsExternal && newTab; else internalNew;" href="{{globalHeroCTAUrl | protocol}}" target="_blank" class="button primary">{{globalHeroCTA}}</a>
      </ng-template>
      <ng-template #internalNew>
        <a *ngIf="!globalHeroCTAIsExternal && !assetLink && newTab; else internal;" routerLink="{{globalHeroCTAUrl | parseUrl: 'pathname'}}" target="_blank" class="button primary">{{globalHeroCTA}}</a>
      </ng-template>
      <ng-template #internal>
        <a *ngIf="!globalHeroCTAIsExternal && !assetLink && !newTab; else assetNew;" routerLink="{{globalHeroCTAUrl | parseUrl: 'pathname'}}" class="button primary">{{globalHeroCTA}}</a>
      </ng-template>
      <ng-template #assetNew>
        <a *ngIf="!globalHeroCTAIsExternal && assetLink && newTab; else asset;" href="{{globalHeroCTAUrl}}" target="_blank" class="button primary">{{globalHeroCTA}}</a>
      </ng-template>
      <ng-template #asset>
        <a href="{{globalHeroCTAUrl}}" class="button primary">{{globalHeroCTA}}</a>
      </ng-template>
    </div>
  </div>

  <div class="global-hero-block__wrapper {{globalHeroOrientation}} ie11-{{ieVar}}" *ngIf="globalHeroOrientation == 'center';" [ngStyle]="{'width': globalHeroContentWidth, 'text-align': globalHeroOrientation}">
      <div class="tag" *ngIf="globalHeroHeader">{{globalHeroHeader}}</div>
      <h1 [innerHTML]='globalHeroHeading' *ngIf="globalHeroIsPageTitle == 'true'; else heading2"></h1> 
      <ng-template #heading2>
            <h2 [innerHTML]='globalHeroHeading'></h2>
      </ng-template>
      <p [innerHTML]='globalHeroContent'></p>
      <div *ngIf="globalHeroCTA && globalHeroCTAUrl">
        <a *ngIf="globalHeroCTAIsExternal && !newTab; else openNewTab;" href="{{globalHeroCTAUrl | protocol}}" class="button primary">{{globalHeroCTA}}</a>
        <ng-template #openNewTab>
          <a *ngIf="globalHeroCTAIsExternal && newTab; else internalNew;" href="{{globalHeroCTAUrl | protocol}}" target="_blank" class="button primary">{{globalHeroCTA}}</a>
        </ng-template>
        <ng-template #internalNew>
          <a *ngIf="!globalHeroCTAIsExternal && !assetLink && newTab; else internal;" routerLink="{{globalHeroCTAUrl | parseUrl: 'pathname'}}" target="_blank" class="button primary">{{globalHeroCTA}}</a>
        </ng-template>
        <ng-template #internal>
          <a *ngIf="!globalHeroCTAIsExternal && !assetLink && !newTab; else assetNew;" routerLink="{{globalHeroCTAUrl | parseUrl: 'pathname'}}" class="button primary">{{globalHeroCTA}}</a>
        </ng-template>
        <ng-template #assetNew>
          <a *ngIf="!globalHeroCTAIsExternal && assetLink && newTab; else asset;" href="{{globalHeroCTAUrl}}" target="_blank" class="button primary">{{globalHeroCTA}}</a>
        </ng-template>
        <ng-template #asset>
          <a href="{{globalHeroCTAUrl}}" class="button primary">{{globalHeroCTA}}</a>
        </ng-template>
      </div>
  </div>
</section>
<div class="global-hero-banner" *ngIf="globalHeroBanner == 'true'" [ngStyle]="{'background-color': globalHeroBannerColor, 'color': globalHeroBannerTextColor}">
  <img src="{{globalHeroBannerImage}}" />
  <span [innerHTML]='globalHeroBannerText'></span>
  <span class="global-hero-banner__link" *ngIf="globalHeroBannerLinkText && globalHeroBannerLink">
    <a *ngIf="globalHeroBannerLinkIsExternal && !globalHeroBannerLinkNewTab; else openBannerNewTab;" href="{{globalHeroBannerLink | protocol}}">{{globalHeroBannerLinkText}}</a>
    <ng-template #openBannerNewTab>
      <a *ngIf="globalHeroBannerLinkIsExternal && globalHeroBannerLinkNewTab; else internalBannerNewTab;" href="{{globalHeroBannerLink | protocol}}" target="_blank">{{globalHeroBannerLinkText}}</a>
    </ng-template>
    <ng-template #internalBannerNewTab>
      <a *ngIf="!globalHeroBannerLinkIsExternal && !globalHeroBannerLinkAssetLink && globalHeroBannerLinkNewTab; else internalBanner;" routerLink="{{globalHeroBannerLink | parseUrl: 'pathname'}}" target="_blank">{{globalHeroBannerLinkText}}</a>
    </ng-template>
    <ng-template #internalBanner>
      <a *ngIf="!globalHeroBannerLinkIsExternal && !globalHeroBannerLinkAssetLink && !globalHeroBannerLinkNewTab; else assetBannerNew;" routerLink="{{globalHeroBannerLink | parseUrl: 'pathname'}}">{{globalHeroBannerLinkText}}</a>
    </ng-template>
    <ng-template #assetBannerNew>
      <a *ngIf="!globalHeroBannerLinkIsExternal && globalHeroBannerLinkAssetLink && globalHeroBannerLinkNewTab; else assetBanner;" href="{{globalHeroBannerLink}}" target="_blank">{{globalHeroBannerLinkText}}</a>
    </ng-template>
    <ng-template #assetBanner>
      <a href="{{globalHeroBannerLink}}">{{globalHeroBannerLinkText}}</a>
    </ng-template>
  </span>
</div>
