import { Component, OnInit, Input } from '@angular/core';
import { Component as BrComponent, Document, ImageSet, Page } from '@bloomreach/spa-sdk';

@Component({
  selector: 'app-services-tiles-components',
  templateUrl: './services-tiles-components.component.html',
  styleUrls: ['./services-tiles-components.component.scss']
})
export class ServicesTilesComponentsComponent implements OnInit{

  servicesList: {
    image: string,
    heading: string,
    content: any,
    buttonText: string,
    buttonLink: string,
    newTab: boolean,
    assetLink: boolean
  }[];

  @Input() component!: BrComponent;
  @Input() page!: Page;

  // Populate the document variable, will return the model data from the Bloomreach API
  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  // Populate the data variable, will return the data from document
  get data() {
    let data = this.document?.getData();
    return data;
  }

  ngOnInit() {
    if (this.data) {
      this.servicesList = this.data.servicesTiles.map(
        (curTile: {
          heading: string,
          image: string,
          content: any,
          btnText: string,
          btnLink: string,
          newTab: string
        }
      ) => {
        let btnLink = '';
        let btnLinkData = this.page.getContent<any>(curTile.btnLink);
        if(curTile.btnLink) {
          if(btnLinkData.type && btnLinkData.type === 'asset') {
            btnLink = btnLinkData.data.asset.links.site.href;
          } else {
            btnLink = this.page.getContent<Document>(curTile.btnLink).getUrl()!;
          }
        }
        return {
          heading: curTile.heading,
          image: curTile.image ? this.page.getContent<ImageSet>(curTile.image ).getOriginal().getUrl() : null,
          content: curTile.content.value,
          buttonText: curTile.btnText,
          buttonLink: btnLink,
          newTab: curTile.newTab,
          assetLink: btnLink.includes('assets') ? true : false
        };
      });
    }
  }
}
