<div class="manage-content-container" *ngIf="page.isPreview()">
    <ng-container [brManageContentButton]="document"></ng-container>
</div>
<p *ngIf="!data">The Content Teaser Component will render after a content document is selected in the authoring modal.</p>
<div *ngIf="data" class="content-columns-block">
    <div class="content-columns-block__wrapper">
        <div class="grid-container grid-x {{contentColumnsImageAlignment}}">
            <div class="cell large-4 small-12 image">
                <img src='{{contentColumnsImage}}' [ngClass]="{'ani-active': isVisible}">
            </div>
            <div class="cell large-4 small-12 content">
                <pre [innerHTML]="contentColumnsContent" [ngStyle]="{'font-size': fontSize + 'px'}"></pre>
            </div>
            <div class="cell large-4 small-12 content">
                <pre [innerHTML]="contnetColumnsContent2" [ngStyle]="{'font-size': fontSize + 'px'}"></pre>
            </div>
        </div>
    </div>
</div>
