import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, NgZone, Inject, OnDestroy, Input } from '@angular/core';
import { PageScrollService } from 'ngx-page-scroll-core';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { Subscription } from 'rxjs';
import { Component as BrComponent, Document, ImageSet, Page } from '@bloomreach/spa-sdk';
import { DOCUMENT } from '@angular/common';

interface Vendor {
  name: string;
  url: string;
  isExternal: boolean;
  imageSrc: string;
  categories: {
    key: string,
    value: string
  }[];
}

@Component({
  selector: 'app-vendor-list-component',
  templateUrl: './vendor-list-component.component.html',
  styleUrls: ['./vendor-list-component.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VendorListComponentComponent implements OnInit, OnDestroy {
  imageHeroCTA: string;
  vendorListLabel: string;
  alphabeticalLabel: string;
  categoryLabel: string;
  vendorSiteLabel: string;
  activeLetter: string;

  vendorList: Vendor[];

  vendorGroupAlphabet: {
    letter: string,
    vendors: Vendor[]
  }[];

  vendorGroupCategory: {
    category: {
      key: string,
      value: string
    },
    vendors: Vendor[];
  }[];

  readonly fullAlphabet = [
    '#' ,'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'
  ];

  vendorListAlphabet: string[] = [];
  vendorListCategories: {
    key: string,
    value: string
  }[] = [];
  vendorListToggle: boolean;
  vendorListSetting: boolean;
  @ViewChild('navStatic') navStatic: ElementRef;
  isSticky = false;
  activeElementId: string;

  scrollDispSub: Subscription;

  logoTemplate: boolean = false;
  activeLogoIndex: string;

  @Input() component!: BrComponent;
  @Input() page!: Page;

  constructor(
    private scrollDispatcher: ScrollDispatcher,
    private ngZone: NgZone,
    private pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private _document: any
  ) {}

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get data() {
    let data = this.document?.getData();
    this.imageHeroCTA = data.link?.text;
    return data;
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.navStatic && this.navStatic.nativeElement) {
        this.scrollDispSub = this.scrollDispatcher.ancestorScrolled(this.navStatic).subscribe(() => {
          const elementTop = this.navStatic.nativeElement.getBoundingClientRect().top;
          const elementHeight = this.navStatic.nativeElement.offsetHeight;
          const checkValue = elementHeight - elementTop;

          if (elementTop <= checkValue && !this.isSticky) {
            this.ngZone.run(() => {
              this.isSticky = true;
            });
          } else if (elementTop > checkValue && this.isSticky) {
            this.ngZone.run(() => {
              this.isSticky = false;
            });
          }
        });
      }
    });

    if (this.data) {
      this.vendorListLabel = this.data.searchByLabel;
      this.alphabeticalLabel = this.data.alphabeticalLabel;
      this.categoryLabel = this.data.categoryLabel;
      this.vendorSiteLabel = this.data.vendorSiteLabel;
      
      if(this.data.name === "logo-list-configuration") {
        this.logoTemplate = true;
      } else {
        this.logoTemplate = false;
      }
    }

    const cmsVendorContentRefs: { $ref: string }[] = this.component.getModels().query;
    const cmsVendorContent = cmsVendorContentRefs.map(curItem => this.page.getContent<any>(curItem.$ref));

    this.vendorList = cmsVendorContent.map(document => {
      const curContent = document.model.data;
      let url = curContent.vendorPageLink.url;
      if(curContent.vendorPageLink.primaryDocument) {
        url = this.page.getContent<Document>(curContent.vendorPageLink.primaryDocument).getUrl()!
      }
      return {
        name: curContent.displayName,
        url,
        isExternal: curContent.vendorPageLink.isExternal,
        imageSrc: curContent.vendorLogoImage ? this.page.getContent<ImageSet>(curContent.vendorLogoImage).getOriginal().getUrl() : '',
        categories: curContent.category.map(curCat => {
          return {
            key: curCat.key,
            value: curCat.value
          };
        })
      };
    });

    this.vendorList.sort((a, b) => {
      // Sorting regardless of Uppercase or Lowercase letters in the name
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    });

    this.vendorList.forEach(curVendor => {
      const curVendorFirstLetter = curVendor.name.charAt(0).toLowerCase();
      const curVendorCategories = curVendor.categories;

      if (!this.vendorListAlphabet.includes(curVendorFirstLetter)) {
        if(!isNaN(parseInt(curVendorFirstLetter))) {
          this.vendorListAlphabet.push('#');
        } else {
          this.vendorListAlphabet.push(curVendorFirstLetter);
        }
      }

      curVendorCategories.forEach(curCat => {
        if (!this.vendorListCategories.some(curListedCate => curListedCate.key === curCat.key)) {
          this.vendorListCategories.push(curCat);
        }
      });
    });

    this.vendorListCategories.sort((a, b) => {
      if (a.key < b.key) {
        return -1;
      } else if (a.key > b.key) {
        return 1;
      } else {
        return 0;
      }
    });

    this.vendorGroupAlphabet = this.vendorListAlphabet.map(curLetter => {
      return {
        letter: curLetter,
        vendors: this.vendorList.filter(curVendor => {
          let firstChar = curVendor.name.toLowerCase().charAt(0);
          if (!isNaN(parseInt(firstChar)) && curLetter === '#') {
            return true;
          } else if(firstChar === curLetter) {
            return true;
          } else {
            return false;
          }
        })
      };
    });

    this.vendorGroupCategory = this.vendorListCategories.map(curCategory => {
      return {
        category: curCategory,
        vendors: this.vendorList.filter(curVendor => curVendor.categories.some(curCat => curCat.key === curCategory.key))
      };
    });
  }

  ngOnDestroy() {
    if (this.scrollDispSub) {
      this.scrollDispSub.unsubscribe();
    }
  }

  toggleClass(event) {
    if (event.srcElement.classList.contains('show')) {
      event.srcElement.classList.remove('show');
    } else {
      event.srcElement.classList.add('show');
    }
  }

  scrollToElement(newFragment: string, newActiveLetter?: string) {
    const element = this._document.getElementById(newFragment);

    if (element && this.navStatic && this.navStatic.nativeElement) {
      this.pageScrollService.scroll({
        document: this._document,
        scrollTarget: element,
        scrollOffset: this.navStatic.nativeElement.offsetHeight
      });
    }

    if (newActiveLetter) {
      this.activeLetter = newActiveLetter;
    }
  }

  letterIncluded(letterToCheck: string): boolean {
    return this.vendorListAlphabet.includes(letterToCheck);
  }

  selectActiveLogo(newIndex) {
    this.activeLogoIndex = newIndex;
  }
}
