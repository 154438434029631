<div class="manage-content-container" *ngIf="page.isPreview()">
  <ng-container [brManageContentButton]="document"></ng-container>
</div>

<p *ngIf="!data">The Detailed Content with Image Component will render after a content document is selected in the authoring modal.</p>

<div *ngIf="data" class="detailed-content-image-section">
  <div class="detailed-content-image--section__wrapper">
    <div class="grid-container grid-x {{detailedOrientation}}">
        <div class="cell large-5 small-12 image">
          <ng-container *ngIf="detailedImage; else noImageSet">
            <img src="{{detailedImage}}">
          </ng-container>
          <ng-template #noImageSet>
            <h2 class="no-image">{{detailedHeading}}</h2>
          </ng-template>
        </div>
        <div class="cell large-7 small-12">
            <h2>{{detailedHeading}}</h2>
            <div [innerHTML]=detailedContent #innerItems></div>
        </div>
    </div>
  </div>
</div>
