import { Component, Inject, Optional } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SeoService } from '../../services/seo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePageComponent } from '../base-page/base-page.component';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { ENDPOINT } from '../../index/index.component';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent extends BasePageComponent {

  constructor(
    route: ActivatedRoute,
    titleService: Title,
    seoService: SeoService,
    router: Router,
    @Inject(ENDPOINT) endpoint: string,
    // @Inject(REQUEST) @Optional() request?: Request
  ) {
    super(titleService, route, router, seoService, endpoint);
  }

}
