<div class="grid-container">
	<nav class="breadcrumb grid-x grid-margin-x">
		<ul class="breadcrumb__nav cell">
			<li><a routerLink="/">Home</a></li>
			<ng-container *ngFor="let crumb of breadcrumbObject | keyvalue; last as isLast">
				<li *ngIf="!isLast; else currentPage;">
					<a [routerLink]="'/' + crumb.key">{{ crumb.value }}</a>
				</li>
				<ng-template #currentPage>
					<li>
						{{ crumb.value }}
					</li>
				</ng-template>
			</ng-container>
		</ul>
	</nav>
</div>
