import { Component, OnInit, OnDestroy, Input, HostBinding, Inject, Optional } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SeoService } from '../../services/seo.service';
import { siteRoot } from '../../app.constants';
import { Subscription } from 'rxjs';

// Upgrade to 14.7
import { BrPageComponent } from "@bloomreach/ng-sdk";
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { TYPE_CONTAINER_ITEM_UNDEFINED } from '@bloomreach/spa-sdk';
import { FallbackComponent } from '../../fallback-component/fallback.component';
import { ENDPOINT } from '../../index/index.component';
import { REQUEST } from "@nguniversal/express-engine/tokens";
import { Observable } from 'rxjs';
import { filter } from "rxjs/operators";

// import { FindAYardSearchComponent } from 'src/app/find-a-yard-components/find-a-yard-search/find-a-yard-search.component';

@Component({
  selector: 'app-base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss']
})
export class BasePageComponent implements OnInit {
  // BEGIN: Variables that will be needed for almost all pages to work with the hippo content api.
  contentUrl: string;
  // isPreview: boolean;
  pageTitle: string;
  breadcrumbConfig: any;
  routerSub: Subscription;

  public pageModel: any;
  // END

  // Upgrade Variables
  configuration!: BrPageComponent["configuration"];

  mapping = {
    [TYPE_CONTAINER_ITEM_UNDEFINED]: FallbackComponent,
  };

  private navigationEnd: Observable<NavigationEnd>;

  @Input() page!: Page;
  @Input() component!: BrComponent;

  constructor(
    protected titleService: Title,
    protected route: ActivatedRoute,
    protected router: Router,
    protected seoService: SeoService,
    @Inject(ENDPOINT) public endpoint?: string,
    // @Optional() @Inject(REQUEST) request?: Request
  ) {
    this.configuration = {
      endpoint,
      // request,
      endpointQueryParameter: 'endpoint',
      path: router.url,
    } as unknown as BasePageComponent['configuration'];

    this.navigationEnd = router.events.pipe(
        filter((event) => {
          return event instanceof NavigationEnd
        })
    ) as Observable<NavigationEnd>;
  }

  ngOnInit() {
    // this.checkPageModel(this.pageModel);

    /**
     * Subscribe to router events in case of a nav event to the same route.
     */
    this.routerSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // this.pageModel = this.route.snapshot.data.pageModel;
        // this.checkPageModel(this.pageModel);
      }
    });
  }

  setPageModel(page?: Page):void {
    this.pageModel = page;
  }

  ngOnDestroy() {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }

  /**
   * Takes in a pageModel object and set the fields for the title tag, breadcrumb, title, and canonical link.
   * These are the fields needed by all templates.
   * @param pageModel The pagemodel object retrieved from the CMS.
   *
   * Deactivated due to Upgrade
  //  */
  // checkPageModel(pageModel: any) {
  //   if (pageModel) {
  //     // this.isPreview = this.requestContextService.isPreviewRequest();

  //     this.contentUrl = getNestedObject(pageModel, ['_links', 'site', 'href']);
  //     // If this is not a preview request we need to strip away the first part of the string
  //     if (!this.isPreview) {
  //       // The string that appears in the url passed from the content api.  Need to remove everything that is before this string.
  //       let searchString = '/site';
  //       if (this.contentUrl.indexOf(searchString) === -1) {
  //         searchString = '.com';
  //       }
  //       const siteIndex = this.contentUrl.indexOf(searchString) + searchString.length;
  //       if (this.contentUrl && siteIndex > -1) {
  //         this.contentUrl = this.contentUrl.slice(siteIndex);
  //       }
  //     }

  //     const contentContainers = getNestedObject(pageModel, ['page', 'components']);

  //     // Get the content for the contact us form
  //     if (contentContainers && contentContainers.length) {
  //       this.breadcrumbConfig = contentContainers.find(curContainer => curContainer.name === 'breadcrumb');
  //     }

  //     this.pageTitle = pageModel.page._meta.pageTitle;
  //     this.titleService.setTitle(this.pageTitle);
  //     this.setCanonicalLink();
  //   }
  // }

  /**
   * Set the canonical URL for this page based on the siteroot constant and the contentUrl pulled from the CMS.
   * Making a separate method so it can be more easily overridden by inheriting components.
   */
  setCanonicalLink() {
    // this.seoService.createLinkForCanonicalURL(`${siteRoot}${this.contentUrl}`);
  }

}
