import { Component, OnInit, ViewChild, ElementRef, NgZone, OnDestroy, Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef, } from '@angular/core';
import { Subscription } from 'rxjs';
import { ScrollDispatcher } from '@angular/cdk/overlay';

// Upgrade
import { Component as BrComponent, Document, ImageSet, Page } from '@bloomreach/spa-sdk';

@Component({
  selector: 'app-content-section-component',
  templateUrl: './content-section-component.component.html',
  styleUrls: ['./content-section-component.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentSectionComponentComponent implements OnInit, OnDestroy {
  contentHeading: string;
  contentHeadingImage: string;
  contentContent: string;
  @ViewChild('imgStatic') imgStatic: ElementRef;
  scrollDispSub: Subscription;
  isVisible = false;
  contentCTA: string;
  contentCTALink: string;
  contentIsExternal: boolean;
  newTab: boolean;
  assetLink: boolean;

  @Input() component!: BrComponent;
  @Input() page!: Page;

  // tslint:disable-next-line:max-line-length
  constructor(
    // imageUrlService: ImageUrlService,
    // pageModelService: PageModelService,
    private scrollDispatcher: ScrollDispatcher,
    private ngZone: NgZone,
    private elemRef: ElementRef,
    private cd: ChangeDetectorRef
    ) {
      // super(imageUrlService, pageModelService );
  }

  // Populate the document variable, will return the model data from the Bloomreach API
  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  // Populate the data variable, will return the data from document
  get data() {
    let data = this.document?.getData();
    return data;
  }

  ngOnInit() {
    // super.ngOnInit();
    if (this.data) {
      this.contentHeading = this.data.contentHeadline;
      if (this.data.image) {
        this.contentHeadingImage = this.page.getContent<ImageSet>(this.data.image).getOriginal().getUrl();
      }
      // tslint:disable-next-line:max-line-length
      this.contentContent = this.data.mainContent.value; // can include an anchor button with secondary class

      if (this.data.link) {
        this.contentCTA = this.data.link.text;
        this.contentCTALink = this.data.link.url;
        let linkDocument = this.page.getContent<any>(this.data.link.primaryDocument);
        if(this.data.link.primaryDocument) {
          if(typeof linkDocument.type !== 'undefined' && linkDocument.type === 'asset') {
            this.contentCTALink = linkDocument.data.asset.links.site.href;
          } else {
            this.contentCTALink  = linkDocument.getUrl();
          }
        }
        this.contentIsExternal = this.data.link.isExternal;
        this.newTab = this.data.link.newTab;
        if (this.contentCTALink.includes("assets")) {
          this.assetLink = true;
        } else {
          this.assetLink = false;
        }
      }
    }


    setTimeout(() => {
      if (this.imgStatic && this.imgStatic.nativeElement) {
        this.scrollDispSub = this.scrollDispatcher.ancestorScrolled(this.imgStatic).subscribe(() => {
          const elementTop = this.imgStatic.nativeElement.getBoundingClientRect().top;
          const elementHeight = this.imgStatic.nativeElement.offsetHeight;
          const winHeight = window.innerHeight;
          const checkValue = (winHeight - elementHeight) - 100;

          if (elementTop < checkValue && !this.isVisible) {
            this.ngZone.run(() => {
              this.isVisible = true;
              setTimeout(() => {
                this.cd.markForCheck();
              });
            });
          }
        });
      }
    });
  }

  ngAfterViewInit() {
    // Query all the tables
    let tables = this.elemRef.nativeElement.querySelectorAll('table');

    // If there are present iterate through them
    if(tables.length > 0) {
      tables.forEach((table) => {
        // Remove the styling so there won't be any rules applied from the RTE
        table.removeAttribute('style');
        // Initialize the required arrays to store the data
        let ths = [];
        let mobileRows = [];
        let mobileTableHTML = [];

        // Get the rows iterate through the rows
        let rows = table.querySelectorAll('tr');
        rows.forEach((row, index) => {
          // Remove the style for rows as well
          row.removeAttribute('style');
          // Get the table data
          let tds = row.querySelectorAll('td');
          let mobileTds = [];
          // Iterate through them and remove the style for them as well
          tds.forEach(td => {
            td.removeAttribute('style');
            if(index === 0) {
              // Asume the first row in table has the labels, which will be the table headers
              ths.push(td.innerHTML);
            } else {
              // Else push them to the main array
              mobileTds.push(td.innerHTML);
            }
          });
          if(index !== 0) {
            mobileRows.push(mobileTds);
          }
        });

        // Create the mobile html for the table using the parsed data from above
        mobileTableHTML.push('<tbody>')
        for(let i = 0; i < mobileRows.length; i ++) {
          for(let j = 0; j < mobileRows[i].length; j ++) {
            mobileTableHTML.push(`<tr><td class="header">${ths[j]}</td></tr>`);
            mobileTableHTML.push(`<tr><td>${mobileRows[i][j]}</td></tr>`);
          }
        }
        mobileTableHTML.push('</tbody>')
        let html = mobileTableHTML.join('');

        // Insert the html next to our current table
        table.insertAdjacentHTML('afterend', `<table class="mobile-table">${html}</table>`);
      })
    }
  }

  ngOnDestroy() {
    if (this.scrollDispSub) {
      this.scrollDispSub.unsubscribe();
    }
  }

}
