import { Component, OnInit, Input } from '@angular/core';

// Upgrade
import { Component as BrComponent, Document, ImageSet, Page } from '@bloomreach/spa-sdk';

@Component({
  selector: 'app-page-spacing',
  templateUrl: './page-spacing.component.html',
  styleUrls: ['./page-spacing.component.scss']
})
export class PageSpacingComponent implements OnInit {

  @Input() component!: BrComponent;
  @Input() page!: Page;
  
  imageHeroCTA: string;

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get data() {
    let data = this.document?.getData();
    this.imageHeroCTA = data.link?.text;
    return data;
  }

  ngOnInit() {
  }

}
