import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ServiceUrls } from '../enums/service-urls.enum';

@Injectable({
  providedIn: 'root'
})
export class RtkdFormService {

  readonly rtdkFormServiceUrl = `${environment.apiManEnpoint}${ServiceUrls.RTKDForm}`;

  constructor(private http: HttpClient) { }

  submitForm(formData: any): Observable<any> {
    // Set the headers for the request
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    // Make the POST request with the JSON payload
    return this.http.post(this.rtdkFormServiceUrl, formData, { headers });
  }
}
