import { Component, OnInit, Input } from '@angular/core';
// import { ImageUrlService, PageModelService, BaseComponent } from '../../upgrade/bloomreach';
// import { SingleContentComponent } from 'src/app/upgrade/SingleContentComponent';

// Upgrade
import { Component as BrComponent, Document, ImageSet, Page } from '@bloomreach/spa-sdk';

@Component({
  selector: 'app-content-well',
  templateUrl: './content-well.component.html',
  styleUrls: ['./content-well.component.scss']
})
export class ContentWellComponent implements OnInit {
  // @Input() configuration: any;
  @Input() component!: BrComponent;
  @Input() page!: Page;

  contentWellContent: string;
  contentWellTitle: string;

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get data() {
    let data = this.document?.getData();
    return data;
  }

  ngOnInit() {
    // super.ngOnInit();
    if(this.data) {
      this.contentWellTitle = this.data.contentHeadline;
      this.contentWellContent = this.data.detailedContent.value;
    }
  }

}
