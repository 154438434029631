<div *ngIf="!isRead" class="cookie-info hide">
    <div class="cookie-info__wrap">
        <div [innerHTML]='cookieBannerContent' class="cookie-info__text">
        </div>
        <div class="actions">
            <div class="accept"><button class="button primary" (click)="closeCookiePopup($event)">Accept</button></div>
            <div class="reject"><a class="link" (click)="removeAllCookies($event)">Decline</a></div>
        </div>
    </div>
</div>
