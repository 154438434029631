import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
  OnDestroy,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { Subscription } from "rxjs";
import { ScrollDispatcher } from "@angular/cdk/overlay";
import { ContentAlignment } from "../../enums/content-alignment.enum";

import {
  Component as BrComponent,
  Document,
  ImageSet,
  Page,
} from "@bloomreach/spa-sdk";
import { trigger } from "@angular/animations";

@Component({
  selector: "app-content-teaser",
  templateUrl: "./content-teaser.component.html",
  styleUrls: ["./content-teaser.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentTeaserComponent implements OnInit, OnDestroy {
  contentTeaserImage: string;
  contentTeaserTitle: string;
  contentTeaserHeading: string;
  contentTeaserContent: string;
  contentTeaserLink: string;
  contentTeaserLinkText: string;
  contentTeaserImageAlignment: string;
  @ViewChild("uStatic") uStatic: ElementRef;
  scrollDispSub: Subscription;
  isVisible = false;
  contentTeaserIsExternal: boolean;
  newTab: boolean;
  assetLink: boolean;

  @Input() component!: BrComponent;
  @Input() page!: Page;

  // A mapping of options from hippo to ContentAlignment
  private readonly hippoContentAlignmentMap = {
    Left: ContentAlignment.Left,
    Center: ContentAlignment.Center,
    Right: ContentAlignment.Right,
  };

  // tslint:disable-next-line:max-line-length
  constructor(
    private scrollDispatcher: ScrollDispatcher,
    private ngZone: NgZone,
    private cd: ChangeDetectorRef
  ) {}

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get data() {
    let data = this.document?.getData();
    return data;
  }

  ngOnInit() {
    if (this.data) {
      if (this.component) {
        const params = this.component.getParameters();
        //   // bloomreach sends 'right' or 'left' for image alignment in component
        this.contentTeaserImageAlignment =
          this.hippoContentAlignmentMap[params.alignment];
      }
      if (this.data.image) {
        this.contentTeaserImage = this.page
          .getContent<ImageSet>(this.data.image)
          .getOriginal()
          .getUrl();
      } else if (this.data.largeImage) {
        this.contentTeaserImage = this.page
          .getContent<ImageSet>(this.data.largeImage)
          .getOriginal()
          .getUrl();
      } else if (this.data.smallImage) {
        this.contentTeaserImage = this.page
          .getContent<ImageSet>(this.data.smallImage)
          .getOriginal()
          .getUrl();
      }
      this.contentTeaserTitle = this.data.header;
      this.contentTeaserHeading = this.data.primaryText;
      this.contentTeaserContent = this.data.secondaryText.value;
      // We check for link existence in data
      if (this.data.link) {
        // Assing the url to its respective variable in order to be rendered
        this.contentTeaserLink = this.data.link.url;
        this.contentTeaserIsExternal = this.data.link.isExternal;
        this.newTab = this.data.link.newTab;

        // Checking for the primary document existence for link, which means its an internal link
        // And override the url from contentTeaserLink
        if (this.data.link.primaryDocument) {
          this.contentTeaserLink = this.page
            .getContent<Document>(this.data.link.primaryDocument)
            .getUrl()!;
        }

        if (this.contentTeaserLink.includes("assets")) {
          this.assetLink = true;
        } else {
          this.assetLink = false;
        }
        this.contentTeaserLinkText = this.data.link.text;
      }
    }

    setTimeout(() => {
      if (this.uStatic && this.uStatic.nativeElement) {
        this.scrollDispSub = this.scrollDispatcher
          .ancestorScrolled(this.uStatic)
          .subscribe(() => {
            const elementTop =
              this.uStatic.nativeElement.getBoundingClientRect().top;
            const elementHeight = this.uStatic.nativeElement.offsetHeight;
            const winHeight = window.innerHeight;
            const checkValue = winHeight - elementHeight - 100;

            if (elementTop < checkValue && !this.isVisible) {
              this.ngZone.run(() => {
                this.isVisible = true;
                setTimeout(() => {
                  this.cd.markForCheck();
                });
              });
            }
          });
      }
    });
  }

  ngOnDestroy() {
    if (this.scrollDispSub) {
      this.scrollDispSub.unsubscribe();
    }
  }
}
