import { Component, OnInit, Input, HostListener } from '@angular/core';

// Upgrade
import { Component as BrComponent, Document, ImageSet, Page } from '@bloomreach/spa-sdk';

@Component({
  selector: 'app-image-hero',
  templateUrl: './image-hero.component.html',
  styleUrls: ['./image-hero.component.scss']
})
export class ImageHeroComponent {

  // @Input() configuration: any;
  @Input() component!: BrComponent;
  @Input() page!: Page;

  imageHeroImage: string;
  imageHeroCTA: string;
  imageHeroCTAUrl: string;
  imageHeroCTAIsExternal: boolean;
  imageHeroNewTab: boolean;
  imageHeroAssetLink: boolean;

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get data() {
    let data = this.document?.getData();
    return data;
  }

  ngOnInit() {
    // If we have a configuration, this is a hippo added component.
    if (this.data.image) {
      this.imageHeroImage = this.page.getContent<ImageSet>(this.data.image).getOriginal().getUrl();
    }
    if (this.data.link) {
      this.imageHeroCTAIsExternal = this.data.link.isExternal;
      this.imageHeroCTA = this.data.link.text;
      this.imageHeroNewTab = this.data.link.newTab;
      this.imageHeroCTAUrl = this.data.link.url;
      if(this.data.link.primaryDocument) {
        this.imageHeroCTAUrl = this.page.getContent<Document>(this.data.link.primaryDocument).getUrl()!;
      }
      if (this.imageHeroCTAUrl.includes('assets')) {
        this.imageHeroAssetLink = true;
      } else {
        this.imageHeroAssetLink = false;
      }
    }
  }
}
