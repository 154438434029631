
export enum ServiceUrls {
  locations = '/companies',
  formSubmissions = '/form-submission',
  resourceApi = '/resourceapi',
  pagenotfound = '/pagenotfound',
  hippoRestApi = '/api-man',
  search = '/search',
  ESsearch = '/es/search',
  Geocode = '/geocode/search/addressInformation',
  RTKDForm = '/rtdk-form'
}
