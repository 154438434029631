<div class="manage-content-container" *ngIf="page.isPreview()">
    <ng-container [brManageContentButton]="document"></ng-container>
</div>

<div *ngIf="data" class="logo-grid-block">
    <div class="logo-grid-block__wrapper">
        <div class="grid-container grid-x {{logoGridOrientation}}">
            <div class="cell large-6 small-12">
                <h2>{{ logoGridTitle }}</h2>
                <h3 [innerHTML]='logoGridHeading' [ngClass]="{'ani-active': isVisible}" #uStatic></h3>
                <p [innerHTML]=logoGridText></p>

                <div *ngIf="logoGridCTAText && logoGridCTAUrl">
                    <a *ngIf="linkIsExternal && !newTab; else openNewTab;" href="{{ logoGridCTAUrl }}" class="button secondary">{{ logoGridCTAText }}</a>
                    <ng-template #openNewTab>
                        <a *ngIf="linkIsExternal && newTab; else internalNew;" href="{{ logoGridCTAUrl }}" target="_blank" class="button secondary">{{ logoGridCTAText }}</a>
                    </ng-template>
                    <ng-template #internalNew>
                    <a *ngIf="!linkIsExternal && !assetLink && newTab; else internal;" routerLink="{{ logoGridCTAUrl.getUrl()! | parseUrl: 'pathname' }}" target="_blank" class="button secondary">{{ logoGridCTAText }}</a>
                    </ng-template>
                    <ng-template #internal>
                        <a *ngIf="!linkIsExternal && !assetLink && !newTab; else assetNew;" routerLink="{{ logoGridCTAUrl.getUrl()! | parseUrl: 'pathname' }}" class="button secondary">{{ logoGridCTAText }}</a>
                    </ng-template>
                    <ng-template #assetNew>
                    <a *ngIf="!linkIsExternal && assetLink && newTab; else asset;" href="{{ logoGridCTAUrl }}" target="_blank" class="button secondary">{{ logoGridCTAText }}</a>
                    </ng-template>
                    <ng-template #asset>
                        <a href="{{ logoGridCTAUrl }}" class="button secondary">{{ logoGridCTAText }}</a>
                    </ng-template>
                </div>
            </div>
            <div class="cell large-6 small-12">
                <div class="logo-grid-block__logo-grid grid-x grid-margin-x ">
                    <ng-container  *ngIf="isLocated; else defaultView">
                        <div *ngFor="let logoGridImage of locatedLogoGridImages | slice:0:9; let i=index" class="logo-item large-4 small-6 cell">
                            <a [routerLink]="logoGridImage.pagePath">
                                <img src="{{ logoGridImage.logoPath | asset }}">
                            </a>
                        </div>
                    </ng-container>

                    <ng-template #defaultView>
                        <div *ngFor="let logoGridImage of logoGridImages" class="logo-item large-4 small-6 cell">
                            <a [routerLink]="logoGridImage.url | parseUrl: 'pathname'">
                                <img src="{{ logoGridImage.image }}">
                            </a>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
