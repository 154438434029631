<div class="find-a-yard-results" *ngIf="resultsList$ | async as resultsList">
  <ng-container *ngIf="resultsList.length && status == 'active'">
    <ng-container *ngIf="!(activeLocation$ | async); else activeLocation">
      <div class="find-a-yard-results__top-nav">
        <ng-container *ngIf="searchLocation$ | async as searchLocation">
          <p *ngIf="searchLocation">
            {{ generateSearchText(resultsList.length, searchLocation) }}
          </p>
        </ng-container>
        <span class="toggle on">
          <mat-slide-toggle [labelPosition]="'before'" [checked]="true" (change)="mapToggle($event)" [disableRipple]="true">{{ mapLabel }}</mat-slide-toggle>
        </span>
      </div>
      <ol *ngIf="isMapActive || !isLargeScreen; else detailView;">
        <li *ngFor="let result of resultsList">
          <div class="find-a-yard-results__item">
            <h2 class="find-a-yard-results__title">{{ result.displayInfo || result.company + ' - ' + result.city }}</h2>
            <p class="find-a-yard-results__info">
              <ng-container *ngIf="result.distance">
                {{ result.distance | number:'1.0-0'}}
                mile<ng-container *ngIf="result.distance > 1">s</ng-container>
                away |
              </ng-container>
              <ng-container *ngIf="result.getCloseTime() as closeTime; else closed">
                Open until {{ closeTime | date:'shortTime' }}
              </ng-container>
              <ng-template #closed>
                Closed
              </ng-template>
            </p>
          </div>
          <div class="find-a-yard-results__controls">
            <a class="location" href="https://www.google.com/maps/dir/?api=1&destination={{ result.addressLine1 }}, {{ result.city }}, {{ result.state }}, {{ result.postalCode }}" rel="nofollow" target="_blank"><span class="icon-location-icon"></span></a>
            <a class="info" (click)=" scrolltoTop(); setActiveLocation(result)"><span class="icon-info-icon"></span></a>
          </div>
        </li>
      </ol>
      <ng-template #detailView>
        <ul class="collapsed-list">
          <li *ngFor="let result of resultsList" class="find-a-yard-results__detail find-a-yard-results__detail--list-item grid-x grid-margin-x">
            <div class="find-a-yard-results__detail__top-row grid-x grid-margin-x large-6 cell">
              <h2 class="find-a-yard-results__detail-title cell"><span class="find-a-yard-results__detail-number" *ngIf="prevStatus == 'active'">{{ result.index + 1 }}.</span> {{ result.company }}</h2>
              <div class="find-a-yard-results__address-block cell small-7">
                <p class="find-a-yard-results__address" translate="no" typeof="schema:PostalAddress">
                  <span property="schema:streetAddress">{{ result.addressLine1 }}</span><br>
                  <span property="schema:addressLocality">{{ result.city }}</span>, <span property="schema:addressRegion">{{ result.state }}</span>&nbsp;<span property="schema:postalCode">{{ result.postalCode }}</span>
                </p>
                <dl class="find-a-yard-results__contact-list">
                  <div class="find-a-yard-results__contact-item" *ngIf="result.telNumber">
                    <dt><strong>P:</strong></dt>&nbsp;
                    <dd class="find-a-yard-results__phone-number">{{ result.telNumber }}</dd>
                  </div>
                  <div class="find-a-yard-results__contact-item" *ngIf="result.telNumber && result.faxNumber">
                    <dt><strong>F:</strong></dt>&nbsp;
                    <dd class="find-a-yard-results__phone-number">{{ result.faxNumber }}</dd>
                  </div>
                </dl>
              </div>
              <div class="find-a-yard-results__detail-controls cell small-5">
                <ul class="find-a-yard-results__detail-controls-list collapsed-list">
                  <li class="find-a-yard-results__control-item">
                    <a class="location find-a-yard-results__control-link" href="https://www.google.com/maps/dir/?api=1&destination={{ result.addressLine1 }}, {{ result.city }}, {{ result.state }}, {{ result.postalCode }}" rel="nofollow">
                      <span class="icon-location-icon"></span>{{ getDirectionsLinkText }}
                    </a>
                  </li>
                  <li class="find-a-yard-results__control-item">
                    <a *ngIf="!result.newTab; else openNewTab;"  class="find-a-yard-results__control-link" [href]="result.extWebUrl" rel="nofollow">
                      <span class="icon-web-icon"></span>
                      {{ visitWebsiteLinkText }}
                    </a>
                    <ng-template #openNewTab>
                      <a class="find-a-yard-results__control-link" [href]="result.extWebUrl" rel="nofollow" target="_blank">
                        <span class="icon-web-icon"></span>
                        {{ visitWebsiteLinkText }}
                      </a>
                    </ng-template>
                  </li>
                </ul>
              </div>
            </div>
            <div class="find-a-yard-results__middle-row large-3 cell">
              <h3 class="find-a-yard-results__detail-sub-title">{{ hoursLabel }}</h3>
              <dl class="find-a-yard-results__hours-list" *ngIf="result.timesListing as timesListing">
                <div class="find-a-yard-results__hours-item" *ngFor="let listingItem of timesListing; index as i;">
                  <dt class="find-a-yard-results__hours-label">{{ listingItem.label }}</dt>
                  <dd>{{ listingItem.value }}</dd>
                </div>
              </dl>
            </div>
            <div class="find-a-yard-results__bottom-row large-3 cell" *ngIf="result.productServices && result.productServices.length && result.productServices[0]">
              <h3 class="find-a-yard-results__detail-sub-title">{{ productServicesLabel }}</h3>
              <ul class="find-a-yard-results__services-list">
                <li class="find-a-yard-results__services-item" *ngFor="let productService of result.productServices">
                  {{ productService }}
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </ng-template>
    </ng-container>
    <ng-template #activeLocation>
      <section *ngIf="(activeLocation$ | async) as activeLocation">
        <div class="find-a-yard-results__detail">
          <div class="find-a-yard-results__detail-title-block">
            <h2 class="find-a-yard-results__detail-title"><span class="find-a-yard-results__detail-number" *ngIf="prevStatus == 'active'">{{ activeLocation.index + 1 }}.</span> {{ activeLocation.company }}</h2>
            <a class="find-a-yard-results__close" (click)="clearActiveLocation()"></a>
          </div>
          <div class="find-a-yard-results__detail__top-row grid-x grid-margin-x">
            <div class="find-a-yard-results__address-block cell large-7 grid-x grid-margin-x">
              <p class="find-a-yard-results__address cell small-6 large-12" translate="no" typeof="schema:PostalAddress">
                <span property="schema:streetAddress">{{ activeLocation.addressLine1 }}</span><br>
                <span property="schema:addressLocality">{{ activeLocation.city }}</span>, <span property="schema:addressRegion">{{ activeLocation.state }}</span>&nbsp;<span property="schema:postalCode">{{ activeLocation.postalCode }}</span>
              </p>
              <dl class="find-a-yard-results__contact-list cell small-6 large-12">
                <div class="find-a-yard-results__contact-item" *ngIf="activeLocation.telNumber">
                  <dt><strong>P:</strong></dt>&nbsp;
                  <dd class="find-a-yard-results__phone-number">{{ activeLocation.telNumber }}</dd>
                </div>
                <div class="find-a-yard-results__contact-item" *ngIf="activeLocation.telNumber && activeLocation.faxNumber">
                  <dt><strong>F:</strong></dt>&nbsp;
                  <dd class="find-a-yard-results__phone-number">{{ activeLocation.faxNumber }}</dd>
                </div>
              </dl>
            </div>
            <div class="find-a-yard-results__detail-controls cell large-5">
              <ul class="find-a-yard-results__detail-controls-list collapsed-list">
                <li class="find-a-yard-results__control-item">
                  <a class="location find-a-yard-results__control-link" href="https://www.google.com/maps/dir/?api=1&destination={{ activeLocation.addressLine1 }}, {{ activeLocation.city }}, {{ activeLocation.state }}, {{ activeLocation.postalCode }}">
                    <span class="icon-location-icon"></span>{{ getDirectionsLinkText }}
                  </a>
                </li>
                <li class="find-a-yard-results__control-item">
                  <a *ngIf="!activeLocation.newTab; else openNewTab;" class="info find-a-yard-results__control-link" [href]="activeLocation.extWebUrl" rel="nofollow">
                    <span class="icon-web-icon"></span> {{ visitWebsiteLinkText }}
                  </a>
                  
                  <ng-template #openNewTab>
                    <a class="find-a-yard-results__control-link" [href]="activeLocation.extWebUrl" rel="nofollow" target="_blank">
                      <span class="icon-web-icon"></span> {{ visitWebsiteLinkText }}
                    </a>
                  </ng-template>
                </li>
              </ul>
            </div>
          </div>
          <div class="find-a-yard-results__middle-row">
            <h3 class="find-a-yard-results__detail-sub-title">{{ hoursLabel }}</h3>
            <dl class="find-a-yard-results__hours-list" *ngIf="activeLocation.timesListing as timesListing">
              <div class="find-a-yard-results__hours-item" *ngFor="let listingItem of timesListing; index as i;">
                <dt class="find-a-yard-results__hours-label">{{ listingItem.label }}</dt>
                <dd>{{ listingItem.value }}</dd>
              </div>
            </dl>
          </div>
          <div class="find-a-yard-results__bottom-row" *ngIf="activeLocation.productServices && activeLocation.productServices.length && activeLocation.productServices[0]">
            <h3 class="find-a-yard-results__detail-sub-title">{{ productServicesLabel }}</h3>
            <ul class="find-a-yard-results__services-list">
              <li class="find-a-yard-results__services-item" *ngFor="let productService of activeLocation.productServices">
                {{ productService }}
              </li>
            </ul>
          </div>
        </div>
      </section>
    </ng-template>
  </ng-container>
</div>
