import { Injectable, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

/**
 * Service to set/destroy seo related meta and link tags.  Appends to/removes from HTML head.
 */
@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(@Inject(Meta) private meta: Meta, @Inject(Title) private title: Title) { }

  createLinkForCanonicalURL(canonicalUrl?: string) {
    // let link: HTMLLinkElement = this.doc.querySelector('link[rel="canonical"]');

    // if (!link) {
    //   link = this.doc.createElement('link');
    //   link.setAttribute('rel', 'canonical');
    //   this.doc.head.appendChild(link);
    // }

    // link.setAttribute('href', canonicalUrl ? canonicalUrl : this.doc.URL);
  }

  createMetaDescription(descriptionText: string) {
    if(!this.meta.getTag('name="description"')) {
      this.meta.addTag({
        name: "description",
        content: descriptionText
      });
    } else {
      this.meta.updateTag({
        content: descriptionText
      }, 'name="description"');
    }
  }

  createTitleTag(titleText: string) {
    this.title.setTitle(titleText);
  }

  createMetaKeywords(keywordsText: string) {
    if(!this.meta.getTag('name="keywords"')) {
      this.meta.addTag({
        name: "keywords",
        content: keywordsText
      });
    } else {
      this.meta.updateTag({
        content: keywordsText
      }, 'name="keywords"');
    }
  }

  /* Unused since the upgrade, left here commented in case they need to be used in the future
  createPrevLink(pageNo: number, baseUrl: string, queryParams?: Params) {
    let prev: HTMLLinkElement = this.doc.querySelector('link[rel="prev"]');

    if (!prev) {
      prev = this.doc.createElement('link');
      prev.setAttribute('rel', 'prev');
      this.doc.head.appendChild(prev);
    }

    prev.setAttribute('href', `${baseUrl}?pageNo=${pageNo}` +
      `${
        queryParams ?
          Object.keys(queryParams).filter(curKey => curKey !== 'pageNo').map(curKey => {
            return `&${curKey}=${queryParams[curKey]}`;
          }).join('')
          :
          ''
      }`
    );
  }

  createNextLink(pageNo: number, baseUrl: string, queryParams?: Params) {
    let next: HTMLLinkElement = this.doc.querySelector('link[rel="next"]');

    if (!next) {
      next = this.doc.createElement('link');
      next.setAttribute('rel', 'next');
      this.doc.head.appendChild(next);
    }

    next.setAttribute('href', `${baseUrl}?pageNo=${pageNo}` +
      `${
        queryParams ?
          Object.keys(queryParams).filter(curKey => curKey !== 'pageNo').map(curKey => {
            return `&${curKey}=${queryParams[curKey]}`;
          }).join('')
          :
          ''
      }`
    );
  }

  destroyPrevLink() {
    const prev: HTMLLinkElement = this.doc.querySelector('link[rel="prev"]');

    if (prev) {
      this.doc.head.removeChild(prev);
    }
  }

  destroyNextLink() {
    const next: HTMLLinkElement = this.doc.querySelector('link[rel="next"]');

    if (next) {
      this.doc.head.removeChild(next);
    }
  }
  */

  createMetaRobots() {
    if(!this.meta.getTag('name="robots"')) {
      this.meta.addTag({
        name: "robots",
        content: 'noindex,nofollow'
      });
    } else {
      this.meta.updateTag({
        content: 'noindex,nofollow'
      }, 'name="robots"');
    }
  }

  destroyMetaRobots() {
    if(this.meta.getTag('name="robots"')) {
      this.meta.removeTag('name="robots"');
    }
  }
}
