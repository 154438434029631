import { Component, OnInit, Input } from '@angular/core';
// import { BaseComponent, ImageUrlService, PageModelService } from '../../upgrade/bloomreach';
// import { SingleContentComponent } from 'src/app/upgrade/SingleContentComponent';

// Upgrade
import { Component as BrComponent, Document, ImageSet, Page } from '@bloomreach/spa-sdk';


@Component({
  selector: 'app-content-tiles',
  templateUrl: './content-tiles.component.html',
  styleUrls: ['./content-tiles.component.scss']
})
export class ContentTilesComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;

  tileHeading: string;
  imageHeroCTA: string;
  contentTiles: {
    image: string,
    heading: string,
    content: any
  }[];

  // constructor(imageUrlService: ImageUrlService, pageModelService: PageModelService) {
  //   super(imageUrlService, pageModelService );
  // }

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get data() {
    let data = this.document?.getData();
    this.imageHeroCTA = data.link?.text;
    return data;
  }

  ngOnInit() {
    // super.ngOnInit();
    if (this.data) {
      this.tileHeading = this.data.headline;
      this.contentTiles = this.data.contentTiles.map(
        (curTile: {
          heading: string,
          image: string,
          content: any
        }
        ) => {
          return {
            heading: curTile.heading,
            image: curTile.image ? this.page.getContent<ImageSet>(curTile.image).getOriginal().getUrl() : null,
            content: curTile.content.value
          };
        });
    }
  }

}
