import { Component, OnInit, Input } from "@angular/core";
// tslint:disable-next-line:max-line-length
// import { BaseComponent, ImageUrlService, PageModelService, getNestedObject } from '../../upgrade/bloomreach';
// import { SingleContentComponent } from 'src/app/upgrade/SingleContentComponent';
import { ContentAlignment } from "../../enums/content-alignment.enum";

// Upgrade
import {
  Component as BrComponent,
  Document,
  ImageSet,
  Page,
} from "@bloomreach/spa-sdk";

@Component({
  selector: "app-image-pull-quote",
  templateUrl: "./image-pull-quote.component.html",
  styleUrls: ["./image-pull-quote.component.scss"],
})
export class ImagePullQuoteComponent implements OnInit {
  // @Input() configuration: any;
  @Input() component!: BrComponent;
  @Input() page!: Page;

  quote: string;
  author: string;
  image: string;
  imageHeroCTA: string;
  orientation: string;

  // Expose to template
  ContentAlignment = ContentAlignment;

  // A mapping of options from hippo to ContentAlignment
  private readonly hippoContentAlignmentMap = {
    Left: ContentAlignment.Left,
    Center: ContentAlignment.Center,
    Right: ContentAlignment.Right,
  };

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get data() {
    let data = this.document?.getData();
    return data;
  }

  get configuration() {
    const { cparam } = this.component.getModels();
    return cparam;
  }

  ngOnInit() {
    // super.ngOnInit();
    if (this.data) {
      if (this.configuration) {
        this.orientation =
          this.hippoContentAlignmentMap[this.configuration.alignment];
      }
      this.quote = this.data.caption.value;
      this.author = this.data.tag;
      if (this.data.image) {
        this.image = this.page
          .getContent<ImageSet>(this.data.image)
          .getOriginal()
          .getUrl();
      }
    }
  }
}
