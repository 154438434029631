<dl *ngIf="isPreview">
  <div>
    <dt>Omit page from index: </dt><br>
    <dd>
      <ng-container *ngIf="internalSearch">{{ internalSearch.omitPageFromIndex }}</ng-container>
    </dd>
  </div>
  <div>
    <dt>Page priority: </dt><br>
    <dd>
      <ng-container *ngIf="internalSearch">{{ internalSearch.pagePriority }}</ng-container>
    </dd>
  </div>
  <div>
    <dt>Suggested Terms: </dt><br>
    <dd>
      <ng-container *ngIf="internalSearch">{{ internalSearch.suggestTerms }}</ng-container>
    </dd>
  </div>
</dl>
