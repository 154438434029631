<h1>Right to Know, Correct & Delete Form</h1>

<div *ngIf="!submissionSuccessful">
  <p>By using this form, you may elect to exercise your privacy rights as a California resident. You may also submit a request to exercise these rights by calling us at <a href="tel:1-855-275-5787">1-855-275-5787</a> or emailing us at <a href="mailto:privacyinquiries@gms.com">privacyinquiries&#64;gms.com</a>.</p>

  <p><b>Instructions:</b> Please complete the required fields below. Information collected on this form will be used and retained only to process your request.</p>
</div>

<div *ngIf="submissionSuccessful">
  <p>Form submission successful. Thank you!</p>
</div>

<form (ngSubmit)="submitForm()" [formGroup]="rtkdForm" class="mat-typography" #captchaProtectedForm="ngForm" *ngIf="!submissionSuccessful" id="rtkdForm">
  <label class="text-input" >
    <span class="show-for-sr">First Name</span>
    <input matInput type="text" formControlName="firstName" name="firstName" required placeholder="First Name *">
  </label>

  <label class="text-input" >
    <span class="show-for-sr">Middle Name</span>
    <input matInput type="text" formControlName="middleName" name="middleName" placeholder="Middle Name">
  </label>

  <label class="text-input" >
    <span class="show-for-sr">Last Name</span>
    <input matInput type="text" formControlName="lastName" name="lastName" required placeholder="Last Name *">
  </label>

  <mat-form-field class="mat-remove-spacing dropdown-list" [hideRequiredMarker]="true" >
    <mat-select formControlName="state" class="dropdown" name="state" required (selectionChange)="stateUpdate($event)">
      <mat-option value="CA">California</mat-option>
      <mat-option value="CO">Colorado</mat-option>
      <mat-option value="DE">Delaware</mat-option>
      <mat-option value="IA">Iowa</mat-option>
      <mat-option value="MD">Maryland</mat-option>
      <mat-option value="MT">Montana</mat-option>
      <mat-option value="NE">Nebraska</mat-option>
      <mat-option value="NH">New Hampshire</mat-option>
      <mat-option value="OR">Oregon</mat-option>
      <mat-option value="TX">Texas</mat-option>
      <mat-option value="UT">Utah</mat-option>
      <mat-option value="VA">Virginia</mat-option>
      <mat-option value="other">Other State</mat-option>
    </mat-select>
    <mat-label *ngIf="rtkdForm.get('state').value === ''">State of Residence *</mat-label>
  </mat-form-field>

  <div *ngIf="rtkdForm.get('state').value !== ''" class="state-info">
    <div class="certify-check" *ngIf="rtkdForm.get('state').value !== 'other'">
      <mat-checkbox class="checkbox-option" formControlName="herebyCertify" name="herebyCertify" value="cerify">I certify that I am a resident of the state that I have selected</mat-checkbox>
    </div>

    <div class="warning" *ngIf="rtkdForm.get('state').value === 'other'">
      Sorry, this request is not yet available in your jurisdiction.
    </div>
  </div>

  <label class="text-input" >
    <span class="show-for-sr">E-mail Address</span>
    <input matInput type="email" formControlName="email" name="email" required placeholder="E-mail Address *">
  </label>

  <label class="text-input" >
    <span class="show-for-sr">Phone Number</span>
    <input matInput type="tel" formControlName="phoneNumber" name="phoneNumber" [placeholder]="phoneNumberPlaceholder">
  </label>

  <label class="text-input" >
    <span class="show-for-sr">Physical Address</span>
    <input matInput type="text" formControlName="address" name="address" [placeholder]="addressPlaceholder">
  </label>

  <label class="text-input" >
    <span class="show-for-sr">Account Number<span class="optional">(optional)</span></span>
    <input matInput type="text" formControlName="accountNumber" name="accountNumber" placeholder="Account Number">
  </label>

  <!-- Other form fields -->

  <p>How would you like us to contact you in regards to this request? </p>

  <span class="show-for-sr">Contact Preference</span>
  <div class="checkbox-group" formArrayName="contactPreference">
    <mat-checkbox class="checkbox-option" formControlName="email" name="contactPreference" value="email">Email address</mat-checkbox>
    <mat-checkbox class="checkbox-option" formControlName="phone" name="contactPreference" value="phone">Phone number</mat-checkbox>
    <mat-checkbox class="checkbox-option" formControlName="letter" name="contactPreference" value="letter">By letter to your address listed above</mat-checkbox>
  </div>

  <p>
    If you provided an email address to us as part of submitting this request, you consent to receive electronic communications (email) from us regarding your request.
  </p>

  <p>I am a:</p>

  <mat-radio-group formControlName="userType" name="userType" required class="radio-group">
    <mat-radio-button value="customer">Customer</mat-radio-button>
    <mat-radio-button value="marketing">Marketing Recipient</mat-radio-button>
    <mat-radio-button value="employee">Current/Former Employee</mat-radio-button>
    <mat-radio-button value="applicant">Current/Former Applicant</mat-radio-button>
    <mat-radio-button value="agent">Authorized Agent submitting a request on behalf of another</mat-radio-button>
    <mat-radio-button value="other">Other</mat-radio-button>
  </mat-radio-group>
  <label class="text-input" *ngIf="rtkdForm.get('userType').value === 'other'">
    <input matInput class="text" formControlName="otherUserType" placeholder="Specify other user type">
  </label>

  <p>Please select the type of request you wish to make:</p>

  <div class="checkbox-group" formArrayName="requestTypes">
    <mat-checkbox class="checkbox-option" formControlName="access" name="requestTypes" value="access"
      (change)="toggleRequestSection('access', rtkdForm.get('requestTypes.access').value)">
      Request to access personal information
      <span *ngIf="rtkdForm.get('state').value === 'CA'" class="warning">
        - Sorry, this request is not yet available in your jurisdiction.
      </span>
    </mat-checkbox>

    <mat-checkbox class="checkbox-option" formControlName="knowCategories" name="requestTypes" value="knowCategories"
      (change)="toggleRequestSection('knowCategories', rtkdForm.get('requestTypes.knowCategories').value)">
      Request to know about categories or specific pieces of personal information
      <span *ngIf="rtkdForm.get('state').value !== 'CA'" class="warning">
        - Sorry, this request is not yet available in your jurisdiction.
      </span>
    </mat-checkbox>

    <mat-checkbox class="checkbox-option" formControlName="knowDisclosures" name="requestTypes" value="knowDisclosures"
      (change)="toggleRequestSection('knowDisclosures', rtkdForm.get('requestTypes.knowDisclosures').value)">
      Request to know about disclosures of personal information

      <span *ngIf="rtkdForm.get('state').value === 'MD' || rtkdForm.get('state').value === 'OR' && rtkdForm.get('requestTypes.knowDisclosures').value" class="warning">
        - Sorry, this request is not yet available in your jurisdiction.
      </span>
    </mat-checkbox>

    <mat-checkbox class="checkbox-option" formControlName="delete" name="requestTypes" value="delete"
      (change)="toggleRequestSection('delete', rtkdForm.get('requestTypes.delete').value)">
      Request to delete personal information
    </mat-checkbox>

    <mat-checkbox class="checkbox-option" formControlName="correct" name="requestTypes" value="correct"
      (change)="toggleRequestSection('correct', rtkdForm.get('requestTypes.correct').value)">
      Request to correct personal information
    </mat-checkbox>

    <mat-checkbox class="checkbox-option" formControlName="dataPortability" name="requestTypes" value="dataPortability"
      (change)="toggleRequestSection('dataPortability', rtkdForm.get('requestTypes.dataPortability').value)">
      Data portability request
    </mat-checkbox>
    <div *ngIf="rtkdForm.get('state').value === 'CA' && rtkdForm.get('requestTypes.dataPortability').value" class="warning">
      Sorry, this request is not yet available in your jurisdiction.
    </div>

    <mat-checkbox class="checkbox-option" formControlName="appeal" name="requestTypes" value="appeal"
      (change)="toggleRequestSection('appeal', rtkdForm.get('requestTypes.appeal').value)">
      Request to appeal a previous request decision
    </mat-checkbox>
    <div *ngIf="(rtkdForm.get('state').value === 'CA' || rtkdForm.get('state').value === 'UT') && rtkdForm.get('requestTypes.appeal').value" class="warning">
      Sorry, this request is not yet available in your jurisdiction.
    </div>
  </div>

  <div class="ma-t ma-b">
    <re-captcha [siteKey]="recaptchaSiteKey" [formControlName]="'captchaResponse'" name="captcha" required></re-captcha>
  </div>

  <div [hidden]="displayrecaptchaMessage" class="error ma-b">Please fill out the reCAPTCHA.</div>

  <!-- Next button -->
  <button *ngIf="!isDetailsValid" mat-raised-button color="primary" (click)="next($event)">Next</button>

  <!-- Additional text and inputs -->
  <div *ngIf="showRequestToAccess" [formGroup]="requestToAccessForm">
    <h2>Request to Access:</h2>
    <div class="checkbox-group">
      <mat-checkbox formControlName="categoriesCollected">The categories of personal information we have collected about you, the categories of sources from which we have collected personal information about you, our business or commercial purpose for collecting personal information about you, and the categories of third parties to whom we have disclosed your personal information for a business purpose.</mat-checkbox>
      <mat-checkbox formControlName="specificPieces" (change)="toggleSpecificPieces($event, 'access')">Specific pieces of personal information that we have collected about you relating to:</mat-checkbox>
      <textarea matInput formControlName="specificPiecesDescription" class="ma-t ma-b" placeholder="Please describe the information you are requesting in 180 words or less" [hidden]="!requestToAccessForm.get('specificPieces').value"></textarea>
    </div>

    <p>We will comply with your request to know your personal information unless the personal information is outside the scope of California’s privacy laws, is otherwise exempt from disclosure, or we cannot confirm your identity. Note that California law prohibits us from disclosing certain sensitive types of information including financial account numbers, an account password, or security questions and answers.</p>

    <p>(Note: For requests to know the categories or purposes (boxes 1 above), require at least two pieces of information to be submitted. For requests to know specific pieces of information, require at least three pieces of information to be submitted for confirmation.)</p>

    <p>If we are unable to verify your identity as part of your request, we may be prohibited from fulfilling your request.</p>
  </div>

  <div *ngIf="showRequestToKnowSection" [formGroup]="requestToKnowForm">
    <h2>Please let us know what information you are requesting:</h2>
    <div class="checkbox-group">
      <mat-checkbox formControlName="categoriesCollected">The categories of personal information we have collected about you.</mat-checkbox>
      <mat-checkbox formControlName="categoriesSources">The categories of sources from which we have collected personal information about you.</mat-checkbox>
      <mat-checkbox formControlName="purposeCollecting">The business or commercial purpose for collecting personal information about you.</mat-checkbox>
      <mat-checkbox formControlName="disclosedThirdParties">The categories of third parties to whom the personal information was disclosed for a business purpose.</mat-checkbox>
      <mat-checkbox formControlName="specificPieces" (change)="toggleSpecificPieces($event, 'know')">Specific pieces of personal information that we have collected about you relating to:</mat-checkbox>
      <textarea matInput formControlName="specificPiecesDescription" placeholder="Please describe the information you are requesting in 180 words or less" [hidden]="!requestToKnowForm.get('specificPieces').value"></textarea>
    </div>

    <p>We will comply with your request to know your personal information unless the personal information is outside the scope of California’s privacy laws, is otherwise exempt from disclosure, or we cannot confirm your identity. Note that California law prohibits us from disclosing certain sensitive types of information including financial account numbers, an account password, or security questions and answers.</p>

    <h2>Please confirm your identity</h2>
    <p>We will contact you by email to collect the necessary information.</p>

    <p>(Note: For requests to know the categories or purposes (boxes 1-4 above), require at least two pieces of information to be submitted. For requests to know specific pieces of information, require at least three pieces of information to be submitted for confirmation.)</p>

    <h2>Delivery of Specific Pieces of Information</h2>
    <div class="checkbox-group">
      <mat-checkbox formControlName="deliveryPaper" (change)="toggleDeliveryCheckbox('deliveryPaper')">Paper</mat-checkbox>
      <mat-checkbox formControlName="deliveryElectronic" (change)="toggleDeliveryCheckbox('deliveryElectronic')">Electronic</mat-checkbox>
    </div>
  </div>

  <div *ngIf="showRequestToKnowDisclosuresSection">
    <p>At our discretion, we will provide you with a list of specific third parties to which we have disclosed either your personal information, or any personal information.</p>
    <p>We will comply with your request unless the personal information is outside the scope of applicable data protection laws, is otherwise exempt from disclosure, or we cannot confirm your identity.</p>

    <p>Please confirm your identity.</p>
    <p>• If we require additional information to verify your identity, and you do not have a web-access account with us, we will contact you by email or phone to collect the necessary information.</p>
    <p>If we are unable to verify your identity as part of your request, we may be prohibited from fulfilling your request.</p>
  </div>

  <!-- Additional text and inputs for Request to Delete -->
  <div *ngIf="showRequestToDeleteSection" [formGroup]="requestToDeleteForm">
    <h2>Request to Delete:</h2>
    <p>When you submit a request to delete, we will delete personal information that we have collected about you consistent with your request, unless:</p>
    <ol>
      <li>We must or are permitted to keep the information by law or regulation.</li>
      <li>Your request to delete personal information is outside the scope of California’s privacy laws.</li>
      <li>We cannot confirm your identity.</li>
    </ol>
    <p>We will maintain a record of your request to delete.</p>

    <h2>Please let us know what personal information you want us to delete:</h2>

    <div class="checkbox-group">
      <mat-checkbox formControlName="deleteAll">All personal information that we have collected about you, if the information is in scope.</mat-checkbox>
      <mat-checkbox formControlName="deleteFinancial">All my financial information</mat-checkbox>
      <mat-checkbox formControlName="deleteBrowsing">All my web browsing information</mat-checkbox>
      <mat-checkbox formControlName="deleteCommercial">All my commercial information</mat-checkbox>
      <mat-checkbox formControlName="deleteOther" (change)="toggleDeleteOther($event)">Other</mat-checkbox>
      <textarea matInput formControlName="deleteOtherDescription" placeholder="Please provide additional details" [hidden]="!requestToDeleteForm.get('deleteOther').value"></textarea>
    </div>
  </div>

  <div *ngIf="showRequestToDataPortabilitySection">
    <p>When you submit a data portability request, we will transfer personal information related to you consistent with your request, unless: (i) we prohibited from disclosing the information by law or regulation; (ii) your data portability request is outside the scope of applicable data protection laws; or (iii) we cannot confirm your identity.</p>

    <p>Please let us know what personal information you wish to transfer:</p>

    <div class="checkbox-group">
      <mat-checkbox formControlName="dataPortabilityAll">All personal information that we have collected from or about you, if the information is in scope</mat-checkbox>
      <mat-checkbox formControlName="dataPortabilityFinancial">All my financial information</mat-checkbox>
      <mat-checkbox formControlName="dataPortabilityBrowsing">All my web browsing information</mat-checkbox>
      <mat-checkbox formControlName="dataPortabilityCommercial">All my commercial information</mat-checkbox>
      <mat-checkbox formControlName="dataPortabilityOther" (change)="toggleDataPortabilityOther($event)">Other</mat-checkbox>
      <textarea matInput formControlName="dataPortabilityOtherDescription" placeholder="Please provide additional details" [hidden]="!requestToDataPortabilityForm.get('dataPortabilityOther').value"></textarea>
    </div>

    <p>Please note that only personal information you provided to us in electronic form, and not information created by the Company or any service provider acting on the Company’s behalf, may be the subject of your request. </p>
  </div>

  <div *ngIf="showRequestToCorrectSection">
    Please describe the specific information you would like to correct to in 180 words or less:

    <textarea matInput formControlName="correctionDescription" placeholder=""></textarea>

    <p>If you wish to provide documentation with this request showing why this information is inaccurate, or how your proposed correction would correct the inaccurate personal information, please upload that documentation here. Providing documentation now may expedite your request because we may request documentation upon reviewing your request.  Without documentation, we may not have the necessary evidence that the personal information at issue is inaccurate. Alternatively, please describe why you believe this information is inaccurate, or how your proposed correction would correct the inaccurate personal information, in 180 words or less:</p>

    <p>When you submit a request to correct, we will correct inaccurate personal information consistent with your request, unless: (i) the information is an opinion or otherwise not objectively incorrect, or if we are unable to determine the accuracy of the information; (ii) we must or are permitted to keep the information in its existing form by law or regulation; (iii) your request to correct personal information is outside the scope of applicable data protection laws; or (iv) we cannot confirm your identity. We will maintain a record of your request to correct.</p>
  </div>

  <div *ngIf="showRequestToAppealSection" [formGroup]="requestToAppealForm">
    <h2>Request to Appeal:</h2>

    <p>If you disagree with a decision we have made with respect to your personal information or in response to a previous privacy request you have submitted, you can request an appeal of that decision, which provides us with an opportunity to reevaluate the decision you are appealing.</p>
    <p>If you wish, you may also informally discuss any relevant issues with our privacy team, who may be able to resolve the issue so that you do not need to initiate the formal appeals process below. Our privacy team will consider your perspective, and we will do our best to address your concerns in accordance with our policies and applicable data protection laws. You may contact our privacy team at <strong>privacyinquiries&#64;gms.com</strong>.</p>
    <p>To formally appeal a decision, please provide the following information about the previous privacy request you submitted:</p>

    <mat-form-field class="dropdown-list">
      <mat-select formControlName="appealRequestType" class="dropdown" required>
        <mat-option value="access">Request to Access</mat-option>
        <mat-option value="knowCategories">Request to Know Categories</mat-option>
        <mat-option value="knowDisclosures">Request to Know Disclosures</mat-option>
        <mat-option value="delete">Request to Delete</mat-option>
        <mat-option value="correct">Request to Correct</mat-option>
        <mat-option value="dataPortability">Data Portability Request</mat-option>
      </mat-select>
      <mat-label *ngIf="requestToAppealForm.get('appealRequestType').value === ''">Previous type Request</mat-label>
    </mat-form-field>

    <mat-form-field class="datepicker-wrap">
      <input matInput [matDatepicker]="submittedDatePicker" formControlName="submittedDate" required>
      <mat-datepicker-toggle matSuffix [for]="submittedDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #submittedDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="datepicker-wrap">
      <input matInput [matDatepicker]="responseDatePicker" formControlName="responseDate" required>
      <mat-datepicker-toggle matSuffix [for]="responseDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #responseDatePicker></mat-datepicker>
    </mat-form-field>

    <p>If you wish to provide documentation with this appeal showing why you disagree with our previous decision, any additional information that may help us properly investigate the appeal, or other information to validate the claims you are making, please upload that documentation here. Providing documentation now may expedite your request because we may request documentation upon reviewing your request.  Without documentation, we may not have the necessary evidence that the personal information at issue is inaccurate. Alternatively, please describe why you disagree with our previous decision, or any other information that may help us investigate the appeal or validate the claims you are making in 300 words or less:</p>
    <textarea matInput formControlName="appealDescription" placeholder=""></textarea>

    <p>Please describe the specific outcome or remedy you are seeking in 300 words or less:</p>
    <textarea matInput formControlName="appealOutcome" placeholder=""></textarea>

    <p>We will reasonably investigate the appeal based on the information and documentation you have provided. Following our investigation, we will reach a determination of your appeal, which may include modifying or upholding the original decision. We will notify you of our determination in writing within sixty (60) days of receipt of your appeal, and we will include the explanation for our determination of your appeal. If we deny your appeal, you may file an appeal with the relevant regulator. We will maintain a record of your appeal, including our final determination.</p>
  </div>

  <div *ngIf="showRequestToDeleteSection || showRequestToKnowSection || showRequestToAccess">
    <h2>Please confirm your identity</h2>
    <p>We will contact you by email to collect the necessary information.</p>

    <h2>Submit a request on behalf of someone else</h2>
    <p>If you are requesting personal information on behalf of someone else, please upload your authorization letter here. We may require the person to verify their identity with us directly and to verify that you have been authorized to submit a request on their behalf. If we verify that you are authorized to know the requested personal information, we will contact you to collect additional information.</p>

    <label class="upload-button">
      <input type="file" (change)="handleFileUpload($event)">
      Upload Authorization
      <span *ngIf="authorizationFileName"> : <strong>{{ authorizationFileName }}</strong></span>
    </label>

    <h2>Declaration under penalty of perjury</h2>
    <p>By submitting this form, you agree, on penalty of perjury or other criminal offense under applicable law, that either you are the individual about whom this request for personal information relates, or that you are authorized by that individual to make this request on their behalf.</p>
  </div>

  <div *ngIf="showSubmitButton">
    <button mat-raised-button color="primary" type="submit" [disabled]="rtkdForm.invalid || requestToDeleteForm.invalid || requestToKnowForm.invalid">Submit</button>
  </div>
</form>
