<div class="manage-content-container" *ngIf="page.isPreview()">
    <ng-container [brManageContentButton]="document"></ng-container>
</div>
<p *ngIf="!data">The Image Hero Component will render after a image is selected in the authoring modal.</p>
<section *ngIf="data" class="image-hero-block" [ngStyle]="{'background-image': 'url('+imageHeroImage+')'}">
    <div class="image-hero-link" *ngIf="imageHeroCTAUrl">
        <a *ngIf="imageHeroCTAIsExternal && !imageHeroNewTab; else openNewTab;"
            href="{{imageHeroCTAUrl | protocol}}"></a>
        <ng-template #openNewTab>
            <a *ngIf="imageHeroCTAIsExternal && imageHeroNewTab; else internalNew;"
                href="{{imageHeroCTAUrl | protocol}}" target="_blank"></a>
        </ng-template>
        <ng-template #internalNew>
            <a *ngIf="!imageHeroCTAIsExternal && !imageHeroAssetLink && imageHeroNewTab; else internal;"
                routerLink="{{imageHeroCTAUrl}}" target="_blank"></a>
        </ng-template>
        <ng-template #internal>
            <a *ngIf="!imageHeroCTAIsExternal && !imageHeroAssetLink && !imageHeroNewTab; else assetNew;"
                routerLink="{{imageHeroCTAUrl}}"></a>
        </ng-template>
        <ng-template #assetNew>
            <a *ngIf="!imageHeroCTAIsExternal && imageHeroAssetLink && imageHeroNewTab; else asset;" href="{{imageHeroCTAUrl}}"
                target="_blank"></a>
        </ng-template>
        <ng-template #asset>
            <a href="{{imageHeroCTAUrl}}" class="button primary"></a>
        </ng-template>
    </div>
</section>
