import { Component, HostListener, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormControl } from "@angular/forms";

import { SearchService } from "../../services/search.service";

// Upgrade
import { Component as BrComponent, Document, ImageSet, Page } from '@bloomreach/spa-sdk';


@Component({
  selector: "app-global-search",
  templateUrl: "./global-search.component.html",
  styleUrls: ["./global-search.component.scss"]
})
export class GlobalSearchComponent implements OnInit {
  // @Input() configuration: any;
  @Input() component!: BrComponent;
  @Input() page!: Page;

  imageHeroCTA: string;
  query: string;
  globalSearchForm = new FormGroup({
    searchQuery: new FormControl("")
  });
  displayCompletionResults: boolean = false;
  completionResults: any = [];
  completionResultsFocus: number = -1;
  blurClass: boolean = false;
  private clickedInside: boolean = false;

  navStatusMessage: boolean;

  // Set property to always display the form
  @Input() displayJustForm: boolean = false;
  @Output() messageEvent = new EventEmitter<boolean>();

  @HostListener('click')
  clickInside() {
    this.clickedInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if(!this.clickedInside) {
      this.blurClass = false;
    }
    this.clickedInside = false;
  }

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get data() {
    let data = this.document?.getData();
    this.imageHeroCTA = data.link?.text;
    return data;
  }

  constructor(
    private router: Router,
    private searchService: SearchService,
    private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const searchQuery = params.query;

      if (searchQuery) {
        // Set the value from the input to be searchQuery
        this.globalSearchForm.setValue({
          searchQuery: searchQuery
        });
      }
    });
  }

  toggleClass(e) {
    this.blurClass = true;
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      document.getElementById("global-search-input").focus();
    },0);
  }

  // Clear the search value based on the id provided in the template
  clearSearchValue(whichForm) {
    // Clear the value
    this.globalSearchForm.setValue({
      searchQuery: ''
    });
    // Focus desired input
    document.getElementById(whichForm).focus();
    this.clickout();
    document.getElementById('global-search-input').blur();
  }

  onChange(event) {
    // Get the value from the input
    let inputValue = event.currentTarget.value;
    // If the value length is bigger than 3
    if (inputValue.length >= 3) {
      // Get the autocomplete results using the search service
      this.searchService
        .getElasticCompletionResults(inputValue)
        .subscribe(data => {
          this.completionResults = data;
          this.completionResultsFocus = -1;

          // Scroll down if the element is withing the main menu on mobile
          // Allows the autocomplete results to display
          var mobileContainer = document.querySelector(".global-header__nav--mobile-container");
          // Check if current element is within the mobile container
          if(mobileContainer !== null && mobileContainer.contains(event.target)) {
            // If it is, get the search bottom and scroll it into view
            // That way all the results will show
            var searchBottom = mobileContainer.querySelector(".search-bottom");
            setTimeout(() => {
              searchBottom.scrollIntoView()
            });
          }
        });
    } else {
      // If not, clear the completion results
      this.completionResults = [];
    }
  }

  closeMenu() {
    // Clear the autocomplete results
    this.completionResults = [];
  }

  hideCompletionResults() {
    this.displayCompletionResults = false;
  }

  showCompletionResults() {
    this.displayCompletionResults = true;
  }

  clickedResult(result) {
    // If the result has been clicked, navigated to the desired page with the updated query
    this.navigate(result);

    // Clear the completion results
    this.completionResults = [];

    // Set the global search form value
    this.globalSearchForm.setValue({
      searchQuery: result
    });

    this.blurClass = false;
  }

  changeFocusToSelf(index) {
    // Set the focus to the received index
    this.completionResultsFocus = index;
  }

  selectResult(event) {
    switch (event.key) {
      case "ArrowUp":
        // Select the previous result from the array
        if (this.completionResultsFocus > 0) {
          this.completionResultsFocus--;
        }

        return false;

      case "ArrowDown":
        // Select next result from the array
        if (this.completionResultsFocus < this.completionResults.length - 1) {
          this.completionResultsFocus++;
        }

        return false;

      case "Escape":
        // Close the menu
        this.completionResults = [];
        return false;

      case "Enter":
        // Get the value from the form in case of user input
        let query = this.globalSearchForm.value.searchQuery;
        if(this.completionResults.length !== 0) {
          // If there are results and focus is on one of them
          let index = this.completionResultsFocus >= 0 ? this.completionResultsFocus : 0;
          // Get the query from the selected result
          query = this.completionResults[index];
        }

        this.navigate(query);

        // Set the value for the search form with the new query
        this.globalSearchForm.setValue({
          searchQuery: query
        });

        // Clear the autocomplete results
        this.completionResults = [];
        return false;

      default:
        return event;
    }
  }

  onSubmit(newSearchQuery: string) {
    // If the query isn't empty
    if (newSearchQuery !== "") {
      // Navigate to the desired page with the new query
      this.navigate(newSearchQuery)
    }
  }

  // Single method for navigation, no need to repeat code
  navigate(query) {
    // Navigate to the route using the query receieved as a param
    this.router.navigate(["/search-results/"], {
      queryParams: { query: query }
    });
    // Send the message event to the global header component to close the mobile navigation
    this.messageEvent.emit();
  }
}
