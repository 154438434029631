<div class="manage-content-container" *ngIf="page.isPreview()">
    <ng-container [brManageContentButton]="document"></ng-container>
</div>

<p *ngIf="!data">The Product Tiles Component will render after a content document is selected in the authoring modal.</p>
<div *ngIf="data"  class="product-tiles">
    <div class="product-tiles__wrapper">
        <div class="grid-container grid-x">
            <div class="cell large-4 small-6 product-tiles--tile" *ngFor="let productTile of productTiles">
                <a *ngIf="!productTile.assetLink && !productTile.newTab; else asset;" routerLink="{{productTile.link | parseUrl: 'pathname'}}">
                    <div class="product-tiles--tile__wrapper">
                        <div *ngIf="portraitMode; then portraitImage else landscapeImage"></div>

                        <div class="content">
                            <p class="heading">{{productTile.title}}</p>
                        </div>
                    </div>
                </a>
                <ng-template #asset>
                    <a *ngIf="productTile.assetLink && !productTile.newTab; else assetNewTab;" href="{{productTile.link}}">
                        <div class="product-tiles--tile__wrapper">
                            <div *ngIf="portraitMode; then portraitImage else landscapeImage"></div>

                            <div class="content">
                                <p class="heading">{{productTile.title}}</p>
                            </div>
                        </div>
                    </a>
                </ng-template>
                <ng-template #assetNewTab>
                    <a #anchorElement href="{{ productTile.link !== '' ? (productTile.link) : '#' }}"
                        target="_blank"
                        (click)="voidLink($event, anchorElement)">
                        <div class="product-tiles--tile__wrapper">
                            <div *ngIf="portraitMode; then portraitImage else landscapeImage"></div>

                            <div class="content">
                                <p class="heading">{{productTile.title}}</p>
                                <p class="desc" *ngIf="productTile.desc">{{productTile.desc}}</p>
                                <a *ngIf="productTile.link !== ''" class="bio-link">Bio <i class="icon-share"></i></a>
                            </div>
                        </div>
                    </a>
                </ng-template>
                <ng-template #newTab>
                    <a #anchorElement href="{{ productTile.link !== '' ? (productTile.link) : '#' }}"
                        target="_blank"
                        (click)="voidLink($event, anchorElement)">
                        <div class="product-tiles--tile__wrapper">
                            <div *ngIf="portraitMode; then portraitImage else landscapeImage"></div>
                            <div class="content">
                                <p class="heading">{{productTile.title}}</p>
                                <p class="desc" *ngIf="productTile.desc">{{productTile.desc}}</p>
                                <a *ngIf="productTile.link !== ''" class="bio-link">Bio <i class="icon-share"></i></a>
                            </div>
                        </div>
                    </a>
                </ng-template>

                <ng-template #landscapeImage>
                    <div class="image">
                        <img src="{{productTile.image}}">
                    </div>
                </ng-template>

                <ng-template #portraitImage>
                    <div class="image portrait" [ngStyle]="{'background-image': 'url('+ productTile.image +')'}">
                    </div>
                </ng-template>
            <div>
        </div>
    </div>
</div>
