<ng-template [ngIf]="displayJustForm" [ngIfElse]="globalForm">
  <form [formGroup]="globalSearchForm" (ngSubmit)="onSubmit(searchQuery.value)" autocomplete="off">
    <span class="searchbox-icon"><span class='icon-search-icon'></span></span>
    <input #searchQuery
      formControlName="searchQuery"
      (keydown)="selectResult($event)"
      (input)="onChange($event)"
      (focus)="onChange($event)"
      (focusout)="hideCompletionResults()"
      (focusin)="showCompletionResults()"
      type="text"
      placeholder="Search..."
      name="search"
      class="searchbox-input"
      id="search-input"
      required />

    <div *ngIf="searchQuery.value !== ''" class="search-close-button" (click)="clearSearchValue('search-input')">
      <span class="icon-close-icon"></span>
    </div>

    <div [ngClass]="displayCompletionResults ? '' : 'hide'" class="search-autocomplete">
      <div *ngFor="let resultItem of completionResults; let i = index"
          [attr.data-index]="i"
          class="search-autocomplete__result"
          [ngClass]="{'focused': i === completionResultsFocus}"
          (mousedown)="clickedResult(resultItem)"
          (onmouseover)="changeFocusToSelf(i)">
          {{ resultItem }}
      </div>
    </div>
  </form>
</ng-template>

<ng-template #globalForm>
  <div class='global-search'>
    <div class="search-input-animated" [ngClass]="blurClass ? 'show' : ''">
      <form [formGroup]="globalSearchForm" (ngSubmit)="onSubmit(searchQuery.value)" autocomplete="off">
        <span class="searchbox-icon"><span class='icon-search-icon'></span></span>
        <input #searchQuery
          formControlName="searchQuery"
          (keydown)="selectResult($event)"
          (input)="onChange($event)"
          (focusout)="hideCompletionResults()"
          (focusin)="showCompletionResults()"
          type="text"
          placeholder="Search..."
          name="search"
          class="searchbox-input"
          id="global-search-input" required />

        <div *ngIf="searchQuery.value !== ''" class="search-close-button" (click)="clearSearchValue('global-search-input')">
          <span class="icon-close-icon"></span>
        </div>

        <div [ngClass]="displayCompletionResults ? '' : 'hide'" class="search-autocomplete">
          <div *ngFor="let resultItem of completionResults; let i = index" 
            [attr.data-index]="i"
            class="search-autocomplete__result"
            [ngClass]="{'focused': i === completionResultsFocus}"
            (mousedown)="clickedResult(resultItem)"
            (onmouseover)="changeFocusToSelf(i)">
              {{ resultItem }}
          </div>
          <div class="search-bottom"></div>
        </div>
      </form>
    </div>
    <div class="icon-button desktop" (click)="toggleClass($event)">
      <span class='icon-search-icon'></span>
    </div>
  </div>
</ng-template>
