<!--
<noscript>
  <div class="no-javascript">
    Please enable javascript for full site functionality.
  </div>
</noscript>
<bre-render-cms-component [ngClass]="{'homepage': isHomePage}" [path]="'menu'" [renderComponent]="GlobalHeaderComponent"></bre-render-cms-component>
<ng-container *ngIf="!isLoading; else loading">
  <main class="main-content">
    <div *ngIf="overlayActive" class="menu-overlay"></div>
    <app-loading-container *ngIf="isLoadingPageComponent"></app-loading-container>
  </main>
</ng-container>
<bre-render-cms-component [path]="'footer'" [renderComponent]="GlobalFooterComponent"></bre-render-cms-component>
<ng-template #loading>
  <app-loading-container></app-loading-container>
</ng-template>
<bre-render-cms-component [path]="'cookie-info'" [renderComponent]="CookieInfoComponent"></bre-render-cms-component>
-->
<noscript>
  <div class="no-javascript">
    Please enable javascript for full site functionality.
  </div>
</noscript>
<router-outlet></router-outlet>
