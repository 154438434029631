<div class="manage-content-container" *ngIf="page.isPreview()">
  <ng-container [brManageContentButton]="document"></ng-container>
</div>

<p *ngIf="!data">The Services Tiles Component will render after a content document is selected in the authoring modal.</p>
<div *ngIf="data" class="services-tiles">
  <div class="services-tiles__wrapper">
      <div class="grid-container grid-x">
          <div class="cell large-6 small-12 services-tiles--tile" *ngFor="let servicesItem of servicesList">
            <div class="services-tiles--tile__wrapper">
              <img src="{{servicesItem.image}}">
              <h3>{{servicesItem.heading}}</h3>
              <p [innerHTML]=servicesItem.content></p>
              <a *ngIf="!servicesItem.assetLink && !servicesItem.newTab; else asset;" href="{{servicesItem.buttonLink}}" class="button secondary">{{servicesItem.buttonText}}</a>
              <ng-template #asset>
                  <a *ngIf="servicesItem.assetLink && !servicesItem.newTab; else assetNewTab;"  href="{{servicesItem.buttonLink}}" class="button secondary">{{servicesItem.buttonText}}</a>
              </ng-template>
              <ng-template #assetNewTab>
                  <a *ngIf="servicesItem.assetLink && servicesItem.newTab; else newTab;" href="{{servicesItem.buttonLin}}" target="_blank" class="button secondary">{{servicesItem.buttonText}}</a>
              </ng-template>
              <ng-template #newTab>
                  <a routerLink="{{servicesItem.buttonLink | parseUrl: 'pathname'}}" class="button secondary" target="_blank">{{servicesItem.buttonText}}</a>
              </ng-template>
            </div>
          </div>
      </div>
  </div>
</div>
