import { Component, OnInit, Input } from "@angular/core";
// import { BaseComponent, ImageUrlService, PageModelService } from '../../upgrade/bloomreach';
// import { SingleContentComponent } from 'src/app/upgrade/SingleContentComponent';
import { FormGroup, FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "../../global-classes/location";

// Upgrade
import {
  Component as BrComponent,
  Document,
  ImageSet,
  Page,
} from "@bloomreach/spa-sdk";

@Component({
  selector: "app-subsidiary-locations",
  templateUrl: "./subsidiary-locations.component.html",
  styleUrls: ["./subsidiary-locations.component.scss"],
})
export class SubsidiaryLocationsComponent implements OnInit {
  locationsForm = new FormGroup({
    searchQuery: new FormControl(""),
  });
  imageHeroCTA: string;
  subsidiaryLocationsHeading: string;
  subsidiaryLocationsFinderHeading: string;
  subsidiaryLocationsFinderPlaceholder: string;
  subsidiaryLocationsHeadingFinderButtonText: string;
  subsidiaryLocationsGetDirectionsText: string;
  subsidiaryLocations: Location[];

  // @Input() configuration: any;
  @Input() component!: BrComponent;
  @Input() page!: Page;

  get document() {
    const { document } = this.component.getModels();
    return document && this.page.getContent<Document>(document);
  }

  get data() {
    let data = this.document?.getData();
    return data;
  }

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    // super.ngOnInit();
    if (this.data) {
      this.subsidiaryLocationsHeading = this.data.heading;
      this.subsidiaryLocationsFinderHeading = this.data.fayWidgetLabel;
      this.subsidiaryLocationsFinderPlaceholder = this.data.fayPlaceholder;
      this.subsidiaryLocationsHeadingFinderButtonText = this.data.fayBtnTxt;
      this.subsidiaryLocationsGetDirectionsText = this.data.getDirectionsTxt;

      // Remove in case there is an empty location, it will not display it but display the other ones
      this.subsidiaryLocations = this.data.locations
        .filter((location) => {
          if (location.locationInfo.$ref) {
            return true;
          } else {
            return false;
          }
        })
        .map(
          (curLocation: {
            image: string;
            locationInfo: {
              $ref: string;
            };
          }) => {
            const locationInfoObj: any = this.page.getContent<Document>(
              curLocation.locationInfo.$ref
            ).getData();
            return new Location(
              locationInfoObj.companyName,
              locationInfoObj.companyDetails,
              locationInfoObj.displayInfo,
              locationInfoObj.city,
              locationInfoObj.state,
              locationInfoObj.streetAddress,
              locationInfoObj.zip,
              null,
              null,
              locationInfoObj.phoneNumber,
              locationInfoObj.faxNumber,
              null,
              locationInfoObj.monHours,
              locationInfoObj.tuesHours,
              locationInfoObj.wedHours,
              locationInfoObj.thursHours,
              locationInfoObj.friHours,
              locationInfoObj.satHours,
              locationInfoObj.sunHours,
              null,
              false,
              null,
              locationInfoObj.pagePath,
              curLocation.image
                ? this.page
                    .getContent<ImageSet>(curLocation.image)
                    .getOriginal()
                    .getUrl()
                : null
            );
          }
        );
    }
  }

  /**
   * Handler that navigates to the find a yard page with the searchQuery attached.
   */
  locationFormSubmit() {
    this.router.navigate(["find-a-yard"], {
      queryParams: { searchQuery: this.locationsForm.get("searchQuery").value },
    });
  }

  /**
   * Patches form value whenever it is changed so that the mobile and desktop versions of the form stay in sync.
   */
  locationFormChange() {
    this.locationsForm.patchValue(this.locationsForm.value);
  }
}
