<div class="manage-content-container" *ngIf="page.isPreview()">
  <ng-container [brManageContentButton]="document"></ng-container>
</div>

<p *ngIf="!data">The Vendor List Component will render after a content document is selected in the authoring modal.</p>
<div *ngIf="data" class="vendor-list-component">
  <div class="vendor-list-component__wrapper">
    <div class="grid-container grid-x">
      <div class="vendor-list-component__search-by cell small-12 large-8" [ngClass]="{'no-categories': !vendorListCategories || !vendorListCategories.length}">
        <label>{{ vendorListLabel }}:</label>
        <ng-container *ngIf="vendorListCategories && vendorListCategories.length">
          <a (click)="this.vendorListToggle = !this.vendorListToggle; this.vendorListSetting = !this.vendorListSetting"
            [ngClass]="vendorListToggle ? '' : 'selected'">{{ alphabeticalLabel }}</a>
          <a (click)="this.vendorListToggle = !this.vendorListToggle; this.vendorListSetting = !this.vendorListSetting"
            [ngClass]="vendorListToggle ? 'selected' : ''">{{ categoryLabel }}</a>
        </ng-container>
      </div>
      <div #navStatic class="vendor-list-component__selector cell small-12 large-12"
        [ngClass]="{'category': vendorListSetting, 'alpha': !vendorListSetting, 'is-stuck': isSticky}">
        <ul class="alphabet">
          <li *ngFor="let alphabetLetter of fullAlphabet">
            <a (click)="scrollToElement(alphabetLetter, alphabetLetter)" [ngClass]="{'disabled': !letterIncluded(alphabetLetter), 'active': alphabetLetter === activeLetter}">
              {{alphabetLetter}}
            </a>
          </li>
        </ul>
        <div class="categories" placeholder="Select a Category">
          <mat-form-field placeholder="Select a Category">
            <mat-label></mat-label>
            <mat-select placeholder="Select a Category" (valueChange)="scrollToElement($event)">
              <mat-option *ngFor="let category of vendorListCategories" value="{{ category.key }}">{{ category.value }}</mat-option>
            </mat-select>
          </mat-form-field>
          <ul>
            <li *ngFor="let category of vendorListCategories"><a (click)="scrollToElement(category.key)">{{ category.value }}</a></li>
          </ul>
        </div>
      </div>
      <div #navSticky class="vendor-list-component__selector vendor-list-component__selector--sticky cell small-12 large-12"
      [ngClass]="{'category': vendorListSetting, 'alpha': !vendorListSetting, 'is-stuck': isSticky}">
        <ul class="alphabet">
          <li *ngFor="let alphabetLetter of fullAlphabet">
            <a (click)="scrollToElement(alphabetLetter, alphabetLetter)" [ngClass]="{'disabled': !letterIncluded(alphabetLetter), 'active': alphabetLetter === activeLetter}">
              {{alphabetLetter}}
            </a>
          </li>
        </ul>
        <div class="categories" placeholder="Select a Category">
          <mat-form-field placeholder="Select a Category">
            <mat-label></mat-label>
            <mat-select placeholder="Select a Category" (valueChange)="scrollToElement($event)">
              <mat-option *ngFor="let category of vendorListCategories" value="{{ category.key }}">{{ category.value }}</mat-option>
            </mat-select>
          </mat-form-field>
          <ul>
            <li *ngFor="let category of vendorListCategories"><a (click)="scrollToElement(category.key)">{{ category.value }}</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="data" class="vendor-list-component">
  <div class="vendor-list-component__listing">
    <div class="grid-container grid-x" [ngClass]="vendorListSetting ? 'category' : 'alpha'">
      <div class="list-wrapper list-alphabet">
        <div class="cell large-12" *ngFor="let vendorGroup of vendorGroupAlphabet; let i = index">
          <a name="{{vendorGroup.letter}}" class="vendor-list-component__listing--toggle show" (click)="toggleClass($event)"
            id="{{vendorGroup.letter}}" [ngClass]="{'is-stuck': isSticky}">
            <span>{{vendorGroup.letter}}</span>
            <span></span>
          </a>
          <ul>
            <ng-container *ngFor="let vendorGroupList of vendorGroup.vendors">
              <li class="list-item">
                <ng-container *ngIf="!logoTemplate; else renderLogos">
                  <a *ngIf="vendorGroupList.isExternal; else internal" href="{{vendorGroupList.url}}" rel="nofollow" target="_blank">
                    <div>
                      <img src="{{vendorGroupList.imageSrc}}">
                      <p>{{vendorGroupList.name}}</p>
                      <span class="vendor__cta-text">{{ vendorSiteLabel }}</span>
                    </div>
                  </a>
                  <ng-template #internal>
                    <a routerLink="{{vendorGroupList.url | parseUrl: 'pathname'}}">
                      <div>
                        <img src="{{vendorGroupList.imageSrc}}">
                        <p>{{vendorGroupList.name}}</p>
                        <span class="vendor__cta-text">{{ vendorSiteLabel }}</span>
                      </div>
                    </a>
                  </ng-template>
                </ng-container>
                <ng-template #renderLogos>
                  <a (click)="selectActiveLogo(vendorGroupList.name)">
                    <div>
                      <img src="{{vendorGroupList.imageSrc}}">
                      <p>{{vendorGroupList.name}}</p>
                      <span class="vendor__cta-text">{{ vendorSiteLabel }}</span>
                    </div>
                  </a>
                </ng-template>
              </li>
              <li class="logo-items" [ngClass]="{'is-showing': vendorGroupList.name === activeLogoIndex }" *ngIf="logoTemplate">
                <ng-container>
                  <div>
                    <div class="logo-item">
                      <a>
                        <div class="logo-item__img-container"><img src="https://via.placeholder.com/150" /></div>
                        <p>Format</p><span>png</span>
                        <p>Resolution</p><span>150x150</span>
                      </a>
                    </div>
                    <div class="logo-item">
                      <a>
                        <div class="logo-item__img-container"><img src="https://via.placeholder.com/250" /></div>
                        <p>Format</p><span>svg</span>
                        <p>Resolution</p><span>250x250</span>
                      </a>
                    </div>
                    <div class="logo-item">
                      <a>
                      <div class="logo-item__img-container"><img src="https://via.placeholder.com/350" /></div>
                        <p>Format</p><span>jpg</span>
                        <p>Resolution</p><span>350x350</span>
                      </a>
                    </div>
                    <div class="logo-item">
                      <a>
                      <div class="logo-item__img-container"><img src="https://via.placeholder.com/450x300" /></div>
                        <p>Format</p><span>jpg</span>
                        <p>Resolution</p><span>450x300</span>
                      </a>
                    </div>
                    <div class="logo-item">
                      <a>
                      <div class="logo-item__img-container"><img src="https://via.placeholder.com/450x300" /></div>
                        <p>Format</p><span>jpg</span>
                        <p>Resolution</p><span>450x300</span>
                      </a>
                    </div>
                    <div class="logo-item">
                      <a>
                      <div class="logo-item__img-container"><img src="https://via.placeholder.com/450x300" /></div>
                        <p>Format</p><span>jpg</span>
                        <p>Resolution</p><span>450x300</span>
                      </a>
                    </div>
                    <div class="logo-item">
                      <a>
                      <div class="logo-item__img-container"><img src="https://via.placeholder.com/450x300" /></div>
                        <p>Format</p><span>jpg</span>
                        <p>Resolution</p><span>450x300</span>
                      </a>
                    </div>
                    <div class="logo-item">
                      <a>
                      <div class="logo-item__img-container"><img src="https://via.placeholder.com/450x300" /></div>
                        <p>Format</p><span>jpg</span>
                        <p>Resolution</p><span>450x300</span>
                      </a>
                    </div>
                    <div class="logo-item">
                      <a>
                      <div class="logo-item__img-container"><img src="https://via.placeholder.com/450x300" /></div>
                        <p>Format</p><span>jpg</span>
                        <p>Resolution</p><span>450x300</span>
                      </a>
                    </div>
                  </div>
                </ng-container>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
      <div class="list-wrapper list-category">
        <div class="cell large-12" *ngFor="let vendorGroupCat of vendorGroupCategory">
          <a name="{{vendorGroupCat.category.key}}" class="vendor-list-component__listing--toggle show" id="{{vendorGroupCat.category.key}}"
            (click)="toggleClass($event)" [ngClass]="{'is-stuck': isSticky}">
            <span>{{vendorGroupCat.category.value}}</span>
            <span> &mdash; </span>
          </a>
          <ul>
            <li *ngFor="let vendorGroupCatList of vendorGroupCat.vendors">
              <a *ngIf="vendorGroupCatList.isExternal; else internal" href="{{vendorGroupCatList.url}}" rel="nofollow" target="_blank">
                <div>
                  <img src="{{vendorGroupCatList.imageSrc}}">
                  <p>{{vendorGroupCatList.name}}</p>
                  <span class="vendor__cta-text">{{ vendorSiteLabel }}</span>
                </div>
              </a>
              <ng-template #internal>
                <a routerLink="{{vendorGroupCatList.url | parseUrl: 'pathname'}}">
                  <div>
                    <img src="{{vendorGroupCatList.imageSrc}}">
                    <p>{{vendorGroupCatList.name}}</p>
                    <span class="vendor__cta-text">{{ vendorSiteLabel }}</span>
                  </div>
                </a>
              </ng-template>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<app-back-to-top></app-back-to-top>
