<br-page [configuration]="configuration" [mapping]="mapping" (state)="setPageModel($event)">
  <ng-template let-page="page">
    <header>
      <nav class="navbar navbar-expand-sm navbar-dark sticky-top bg-dark" role="navigation">
        <div class="container">
          <div class="collapse navbar-collapse">
            <app-global-header *brComponent="'menu'; let component" [component]="component" [page]="page">
            </app-global-header>
          </div>
        </div>
      </nav>
    </header>
    <!-- <pre>{{ page | json }}</pre> -->
    <div class="find-a-yard-page">
      <div class="grid-container">
        <div class="grid-x grid-margin-x">
          <!-- <bre-render-cms-component *ngIf="isPreview" [path]="'config'"></bre-render-cms-component> -->
          <div class="find-a-yard-page__search-container cell large-12 small-12">
            <app-find-a-yard-search [configComp]="page?.getComponent('config')" [page]="page">
            </app-find-a-yard-search>
            <p *ngIf="!(locations$ | async).length && (searchLocation$ | async) && !(loading$ | async) && isBrowser"
              class="find-a-yard-page__no-results">
              '{{ searchLocation$ | async }}'
            </p>
          </div>
          <ng-container *ngIf="status == 'default'">
            <div *ngIf="mapActive && isBrowser"
              class="find-a-yard-page__map-container cell large-12 small-12 large-order-2 default">
              <app-find-a-yard-map></app-find-a-yard-map>
            </div>
          </ng-container>
          <ng-container *ngIf="status == 'active'">
            <div *ngIf="mapActive && isBrowser"
              class="find-a-yard-page__map-container cell large-7 small-12 large-order-2">
              <app-find-a-yard-map></app-find-a-yard-map>
            </div>
            <div *ngIf="isBrowser" class="find-a-yard-page__results-container cell large-5 small-12 large-order-1"
              [ngClass]="{'large-5': mapActive, 'large-12': !mapActive}">
              <app-find-a-yard-results (mapActive)="toggleMapActive($event)" [component]="page?.getComponent('config')" [page]="page">
              </app-find-a-yard-results>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <footer class="bg-dark text-light py-3">
      <div class="container clearfix">
        <!-- <pre>{{page|json}}</pre> -->
        <div class="overflow-hidden">
          <app-global-footer *brComponent="'footer'; let component" [component]="component" [page]="page">
          </app-global-footer>
        </div>
      </div>
    </footer>
    <cookie-info *brComponent="'cookie-info'; let component" [component]="component" [page]="page">
    </cookie-info>
  </ng-template>
</br-page>
<app-back-to-top></app-back-to-top>
