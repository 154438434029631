<app-breadcrumb [configuration]="breadcrumbConfig"></app-breadcrumb>
<div class="vendor-page">
    <div class="grid-container">
      <div class="grid-x grid-margin-x">
        <div class="cell large-12">
          <bre-render-cms-component [path]="'main'"></bre-render-cms-component>
        </div>
      </div>
    </div>
</div>
  