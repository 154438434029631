/// <reference path="../../../node_modules/@types/googlemaps/index.d.ts"/>
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, pipe, Observable } from 'rxjs';
import { Location } from '../global-classes/location';
import { googleMapsApiKey } from '../app.constants';
import { googleGeocodingApiKey } from '../app.constants';
import { MapsAPILoader } from '@agm/core';
import { environment } from '../../environments/environment';
import { ServiceUrls } from '../enums/service-urls.enum';
import { map, skipWhile, take, delay } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { FullStateNamePipe } from '../directives-and-pipes/fullNameState.pipe';
import { ShortStateNamePipe } from '../directives-and-pipes/shortNameState.pipe';

@Injectable()
export class LocationsService {
  private _isBrowser: boolean;
  private _activeLocationSubj: BehaviorSubject<Location> = new BehaviorSubject(null);
  activeLocation$ = this._activeLocationSubj.asObservable();
  private _locationsSubj: BehaviorSubject<Location[]> = new BehaviorSubject([]);
  locations$ = this._locationsSubj.asObservable();
  private _loadingSubj: BehaviorSubject<boolean> = new BehaviorSubject(true);
  loading$ = this._loadingSubj.asObservable();
  private _searchLocationSubj: BehaviorSubject<string> = new BehaviorSubject('');
  searchLocation$ = this._searchLocationSubj.asObservable();
  private _allLocationsSubj: BehaviorSubject<Location[]> = new BehaviorSubject([]);
  allLocations$ = this._allLocationsSubj.asObservable();
  private _googleLocationsSubj: BehaviorSubject<Location[]> = new BehaviorSubject([]);
  googleLocations$ = this._googleLocationsSubj.asObservable();

  private stateStatus = new BehaviorSubject<string>('default');
  currentStatus = this.stateStatus.asObservable();

  private prevStatus = new BehaviorSubject<string>('default');
  previousStatus = this.prevStatus.asObservable();

  private _alternativeLocations: BehaviorSubject<String[]> = new BehaviorSubject([]);
  alternativeLocations$ = this._alternativeLocations.asObservable();

  private stateShortName;


  // tslint:disable-next-line:max-line-length
  readonly locationsUrl = `${environment.apiManEnpoint}${ServiceUrls.locations}`;

  readonly geocodeServiceUrl = `${environment.apiManEnpoint}${ServiceUrls.Geocode}`;

  // Default value.  Can be overridden by caller.
  private radius = 75; // default 75
  private googleRadius = 500; // default 500
  // Coordinates from google api.
  private _googleLat: number;
  private _googleLong: number;
  private googleArray: any[];
  constructor(private http: HttpClient,
    private mapsAPILoader: MapsAPILoader,
    private FullStateNamePipe: FullStateNamePipe,
    private ShortStateNamePipe: ShortStateNamePipe,
    @Inject(PLATFORM_ID) platformId: string) {
    this._isBrowser = isPlatformBrowser(platformId);

    this.http.get<any>(this.locationsUrl)
    .pipe(
      map<any, {
        items: Location[]
      }>(response => {
        return {
          items: response.items ?
            response.items.map(curResult => {
              return new Location(
                curResult.yard.companyName,
                curResult.yard.companyDetails,
                curResult.yard.displayInfo,
                curResult.yard.city,
                curResult.yard.state,
                curResult.yard.streetAddress,
                curResult.yard.zip,
                curResult.yard.lat,
                curResult.yard.long,
                curResult.yard.phoneNumber,
                curResult.yard.faxNumber,
                curResult.yard.websiteUrl,
                curResult.yard.monHours,
                curResult.yard.tuesHours,
                curResult.yard.wedHours,
                curResult.yard.thursHours,
                curResult.yard.friHours,
                curResult.yard.satHours,
                curResult.yard.sunHours,
                curResult.yard.productServices,
                curResult.newTab,
                curResult.company ? curResult.company.logoPath : null,
                curResult.company ? curResult.company.pagePath : null,
                curResult.yard.image
              );
            })
            :
            []
        };
      })
    )
    .subscribe(results => {
      this._allLocationsSubj.next(results.items);
    });
  }

  changeStatus(status: string) {
    this.stateStatus.next(status);
  }
  setPrevStatus(status: string) {
    this.prevStatus.next(status);
  }

  /**
   * Takes in a state short name and sets it in the class variable
   * @param stateName short name of the state
   */
  setStateShortName(stateName: string = null) {
    if(stateName !== '') {
      this.stateShortName = stateName;
      this._searchLocationSubj.next(this.FullStateNamePipe.transform(stateName));
      this._setLocations();
    }
  }

  getAlternativeLocations(query: string) {
    this.http.get<any>(
      `${this.geocodeServiceUrl}?address=${query.replace(' ', '+')}`
      // `https://nominatim.openstreetmap.org/search?q=${query.replace(' ', '+')}&format=jsonv2&polygon=1&addressdetails=1&extratags=1`
    ).subscribe(results => {
      // Modify San Francisco before sorting the array
      results.forEach(result => {
        if(result.place_id === 47011604 || result.place_id === 46987404) {
          result.display_name = "San Francisco, California, United States of America";
        }
      });
      // Sort the array and place the cities first
      results.sort((a, b) => {
        if(a.type === "city") {
          return -1;
        } else {
          return 1;
        }
      });
      this._alternativeLocations.next(results);
    });
  }

  /**
   * Takes in params for locations endpoint and updates the locations$ with the returned data mapped to
   * Location objects.  If necessary also makes a call to the google geocoding api to convert the given address
   * to coordinates.
   * @param searchLocation the center of the search radius.  Plain string to be converted to coordinates by google api.
   * @param geoLat Optional lat param that can be passed from browser location api.  Converted to address by google api.
   * @param geoLong Optional long param that can be passed from browser location api.  Converted to address by google api.
   */
  // tslint:disable-next-line:max-line-length
  getLocations(searchLocation: string = this._searchLocationSubj.value, geoLat?: number, geoLong?: number) {
    this._loadingSubj.next(true);
    this.stateShortName = null;
    /**
     * If there are geoLat and geoLong variables, priortize those and execute reverse geocode lookup with google api to
     * get formatted address.  Else check if there is a new searchLocation string.  If there else, use google api to get
     * coords and then set locations.  Else, just run setLocations.
     */
    if (geoLat && geoLong) {
      this._setLocations(geoLat, geoLong);
      this.changeStatus('active');
    } else if (searchLocation && searchLocation !== this._searchLocationSubj.value) {
      this.changeStatus('active');
      this.setPrevStatus('active');
      this._searchLocationSubj.next(searchLocation);
      this.http.get<any>(
        `${this.geocodeServiceUrl}?address=${searchLocation.replace(' ', '+')}`
        // `https://nominatim.openstreetmap.org/search?q=${searchLocation.replace(' ', '+')}&format=jsonv2&polygon=1&addressdetails=1&extratags=1`
        // OLD SERVICE `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(searchLocation)}` +
        // `&key=${googleGeocodingApiKey}`
      ).subscribe(result => {
        /**
         *  For ease of development
         *  In case of needing to rever to the old system
         *
         *  this.http.get<any>(
              `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(searchLocation)}` +
              `&key=${googleGeocodingApiKey}`
              ).subscribe(result => {
                if (result && result.results && result.results.length) {
                  const curLocation = result.results[0];
                  if (result.results[0].address_components[0].types.includes("administrative_area_level_1")) {
                    this.stateShortName = result.results[0].address_components[0].short_name;
                  }
                  const lat = curLocation.geometry.location.lat;
                  const long = curLocation.geometry.location.lng;
                  this._googleLat = lat;
                  this._googleLong = long;
                  if (this._isBrowser) {
                    this._setLocations(lat, long);
                  }
                } else {
         *
         */

        if (result && result.length) {
          const curLocation = result[0];
          // If the query returns a state, set the state short name and display all locations from that state
          if( curLocation.place_rank == 8 || curLocation.type == 'state' ||
              typeof curLocation.extratags.place !== 'undefined' && curLocation.extratags.place == 'state') {
            this.stateShortName = this.ShortStateNamePipe.transform(curLocation.address.state);
          }
          const lat = curLocation.lat;
          const long = curLocation.lon;
          this._googleLat = lat;
          this._googleLong = long;
          if (this._isBrowser) {
            this._setLocations(lat, long);
          }
        } else {
          // If there are no results from google, empty the results array.
          this._googleLat = null;
          this._googleLong = null;
          this._locationsSubj.next([]);
          this._loadingSubj.next(false);
        }
      });
    } else if (searchLocation) {
      if (this._isBrowser) {
        this._setLocations();
        this.changeStatus('active');
      }
    } else {
      if (this._isBrowser && this.prevStatus.value === 'default') {
        this._setLocations(39.809734, -98.555620); // set to center of US, Kansas
        this.changeStatus('default');
      }
    }
  }

  /**
   * Given lat and long values attempts to get location results from hapi api.  Then updates the locationsSubj with those new results
   * after they've been converted to Location objects.
   * @param lat latitude that is the center of the search radius.
   * @param long longitude that is the center of the search radius.
   */
  private _setLocations(lat = this._googleLat, long = this._googleLong) {
    if (lat === 39.809734) { // Temporary condition: if default show, use all locations within a 1700 radius
      this.mapsAPILoader.load().then(() => {
        this.allLocations$
        .pipe(
          skipWhile(allLocations => !allLocations || !allLocations.length),
          take(1)
        )
        .subscribe(allLocations => {
          this._locationsSubj.next(allLocations.map(curLoc => {
            if (this.stateShortName) {
              // Add any specific logic for stateShortName
            } else {
              const curLocLatLng = new google.maps.LatLng(curLoc.lat, curLoc.long);
              const centerLocLatLng = new google.maps.LatLng(lat, long);
              const distanceBetween = google.maps.geometry.spherical.computeDistanceBetween(curLocLatLng, centerLocLatLng);
              const locCopy = Object.assign(Object.create(curLoc), curLoc);
              locCopy.distance = this._metersToMiles(distanceBetween);
              return locCopy;
            }
          })
          .filter(curLoc => {
            return curLoc.distance <= 4000; // Filter locations within 4000 distance units
          })
          .sort((a, b) => a.distance - b.distance)
          .map((curLoc, i) => {
            const locCopy = Object.assign(Object.create(curLoc), curLoc);
            locCopy.index = i;
            return locCopy;
          }));
          this._loadingSubj.next(false);
        });
      });
    } else if(this.stateShortName != null) {
      this.mapsAPILoader.load().then(() => {
        this.allLocations$
        .pipe(
          skipWhile(allLocations => !allLocations || !allLocations.length),
          take(1)
        )
        .subscribe(allLocations => {
          this._locationsSubj.next(allLocations.map(curLoc => {
            const curLocLatLng = new google.maps.LatLng(curLoc.lat, curLoc.long);
            const centerLocLatLng = new google.maps.LatLng(lat, long);
            const distanceBetween = google.maps.geometry.spherical.computeDistanceBetween(curLocLatLng, centerLocLatLng);
            const locCopy = Object.assign(Object.create(curLoc), curLoc);
            locCopy.distance = this._metersToMiles(distanceBetween);
            return locCopy;
          })
          .filter(curLoc => {
            if (curLoc.state.trim() == this.stateShortName) {
              return curLoc;
            }
          })
          .sort((a, b) => {
            if (a.distance < b.distance) {
              return -1;
            } else if (a.distance > b.distance) {
              return 1;
            } else {
              return 0;
            }
          })
          .map((curLoc, i) => {
            const locCopy = Object.assign(Object.create(curLoc), curLoc);
            locCopy.index = i;
            return locCopy;
          }));
          this._loadingSubj.next(false);
        });
      });
    } else {
      this.mapsAPILoader.load().then(() => {
        this.allLocations$
        .pipe(
          skipWhile(allLocations => !allLocations || !allLocations.length),
          take(1)
        )
        .subscribe(allLocations => {
          this._locationsSubj.next(allLocations.map(curLoc => {
            const curLocLatLng = new google.maps.LatLng(curLoc.lat, curLoc.long);
            const centerLocLatLng = new google.maps.LatLng(lat, long);
            const distanceBetween = google.maps.geometry.spherical.computeDistanceBetween(curLocLatLng, centerLocLatLng);
            const locCopy = Object.assign(Object.create(curLoc), curLoc);
            locCopy.distance = this._metersToMiles(distanceBetween);
            return locCopy;
          })
          .filter(curLoc => {
            return curLoc.distance <= this.radius; // Filter locations within the specified radius
          })
          .sort((a, b) => a.distance - b.distance)
          .map((curLoc, i) => {
            const locCopy = Object.assign(Object.create(curLoc), curLoc);
            locCopy.index = i;
            return locCopy;
          }));
          this._loadingSubj.next(false);
        });
      });
    }
  }

  public _setGoogleLocation(lat, long) {  // set locations for logo grid
      this.mapsAPILoader.load().then(() => {
        this.allLocations$
        .pipe(
          skipWhile(allLocations => !allLocations || !allLocations.length),
          take(1)
        )
        .subscribe(allLocations => {
          this._locationsSubj.next(allLocations.map(curLoc => {
            const curLocLatLng = new google.maps.LatLng(curLoc.lat, curLoc.long);
            const centerLocLatLng = new google.maps.LatLng(lat, long);
            const distanceBetween = google.maps.geometry.spherical.computeDistanceBetween(curLocLatLng, centerLocLatLng);
            const locCopy = Object.assign(Object.create(curLoc), curLoc);
            locCopy.distance = this._metersToMiles(distanceBetween);
            return locCopy;
          })
          .filter(curLoc => {
            return curLoc.distance <= this.googleRadius;
          })
          .sort((a, b) => a.distance - b.distance)
          .map((curLoc, i) => {
            const locCopy = Object.assign(Object.create(curLoc), curLoc);
            locCopy.index = i;
            return locCopy;
          }));
        });
      });
  }

  getLocationsByCompany(newCompany: string) {
    this._loadingSubj.next(true);
    this._searchLocationSubj.next(newCompany);
    this.allLocations$
    .pipe(
      skipWhile(allLocations => !allLocations || !allLocations.length),
      take(1)
    )
    .subscribe(allLocations => {
      this._locationsSubj.next(
        allLocations
        .filter(curLoc => curLoc.company.includes(newCompany))
        .map((curLoc, i) => {
          const locCopy = Object.assign(Object.create(curLoc), curLoc);
          locCopy.index = i;
          return locCopy;
        })
      );
      this._loadingSubj.next(false);
    });
  }

  setActiveLocation(activeLocation: Location) {
    this.setPrevStatus(this.stateStatus.value);
    this._activeLocationSubj.next(activeLocation);
    this.changeStatus('active');
  }

  clearActiveLocation() {
    this._activeLocationSubj.next(null);
    if (this.prevStatus.value === 'default') {
      this.changeStatus('default');
    }
  }

  private _metersToMiles(valueInMeters: number): number {
    return valueInMeters * 0.000621371;
  }
}
